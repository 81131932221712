import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {MatchmakingComponent} from './matchmaking.component';
import {UcAuthentication} from '../core/auth';
import {HomeComponent} from './home/home.component';

const routes: Routes = [
  {
    path: 'matchmaking',
    component: MatchmakingComponent,
    children: [
      {path: '', redirectTo: 'home', pathMatch: 'full'},
      {path: 'home', component: HomeComponent, canActivate: [UcAuthentication]},
    ],
    data: {
      preload: true
    }, canActivate: [UcAuthentication]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MatchmakingRouting {
}

