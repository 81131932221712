<nz-layout>
  <nz-header class="uc-header">
    <div class="uc-logo" [ngStyle]="{backgroundImage: 'url(' + logo + ')'}" style="cursor: pointer;background-size: 100% 100%;" routerLink="/system/index"></div>
    <ul nz-menu [nzTheme]="'dark'" [nzMode]="'horizontal'" class="uc-header-nav">
      <ng-container>
        <li nz-menu-item routerLinkActive="ant-menu-item-selected" nzSelected>
          <a (click)="storageMobile()">联系客服</a>
        </li>
        <li nz-menu-item routerLinkActive="ant-menu-item-selected">
          <span>更新时间：2024/09/14</span>
        </li>
      </ng-container>
    </ul>
    <div class="uc-header-info" *ngIf="isAuthenticated | async">
      <nz-avatar [nzText]="letter | async" [nzIcon]="icon | async" nz-popover [nzPopoverTitle]="title" [nzPopoverContent]="content" nzPopoverPlacement="bottomRight"></nz-avatar>
      <ng-template #title>
        <div class="uc-header-info-title">
          <nz-avatar [nzText]="letter | async" [nzIcon]="icon | async"></nz-avatar>
          {{username | async}}
        </div>
      </ng-template>
      <ng-template #content>
        <div class="uc-header-info-content">
          <div nz-row nzGutter="8" nzType="flex" nzJustify="space-between">
            <div nz-col nzSpan="12">
              <a routerLink="/system/usercenter/my_account"><i nz-icon nzType="user" nzTheme="outline"></i> 用户信息</a>
            </div>
            <div nz-col nzSpan="12">
              <a routerLink="/system/usercenter/my_account"><i nz-icon nzType="safety" nzTheme="outline"></i> 账户安全</a>
            </div>
          </div>
          <div nz-row nzGutter="8" nzType="flex" nzJustify="space-between" hidden>
            <div nz-col nzSpan="12"><a><i nz-icon nzType="mail" nzTheme="outline"></i> 设置邮箱</a></div>
            <div nz-col nzSpan="12"><a><i nz-icon nzType="mobile" nzTheme="outline"></i> 设置手机</a></div>
          </div>
          <div nz-row nzGutter="8" nzType="flex" nzJustify="space-between">
            <!--<div nz-col nzSpan="12" *ngIf="openid === null">-->
              <!--<a href="{{baseUrl}}/usercenter/account/wechat"><i nz-icon nzType="wechat" nzTheme="outline"></i> 微信绑定</a>-->
            <!--</div>-->
            <!--<div nz-col nzSpan="12" *ngIf="openid !== null">-->
              <!--<a (click)="unbindWechat()"><i nz-icon nzType="wechat" nzTheme="outline"></i> 微信解绑</a>-->
            <!--</div>-->
            <div nz-col nzSpan="12"><a (click)="logout()"><i nz-icon nzType="poweroff" nzTheme="outline"></i> 退出登录</a></div>
          </div>
        </div>
      </ng-template>
    </div>
<!--    <div class="sim-btn" *ngIf="ofiSysAdmin">-->
<!--      <span class="sim-btn-radio">跳转：<a class="sim-btn-click" routerLink="/matchmaking/home">撮合平台</a></span>-->
<!--    </div>-->
  </nz-header>
  <nz-layout class="uc-body-layout">
    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzCollapsedWidth]="collapsedWidth" [nzWidth]="width"
              class="uc-sider" *ngIf="isAuthenticated | async">
      <ul nz-menu [nzTheme]="'dark'" [nzMode]="'inline'" [nzInlineCollapsed]="isCollapsed">
        <ng-container *ngFor="let item of menuItems">
          <li nz-submenu *ngIf="item.inSider && item.children && item.children.length > 0">
            <span title>
              <i nz-icon [nzType]="item.icon" nzTheme="outline"></i>
              <span class="nav-text">{{item.title}}</span>
            </span>


            <ul>
              <li nz-menu-item routerLinkActive="ant-menu-item-selected" *ngFor="let child of item.children">
                <a [routerLink]="child.url" (click)="routClick(child)">{{child.title}}</a>
              </li>
            </ul>
          </li>
          <li nz-menu-item routerLinkActive="ant-menu-item-selected" *ngIf="item.inSider && (!item.children || item.children.length === 0)">
            <a [routerLink]="item.url" (click)="routClick(item)">
              <span><i nz-icon [nzType]="item.icon" nzTheme="outline"></i><span class="nav-text">{{item.title}}</span></span>
            </a>
          </li>
        </ng-container>
      </ul>
    </nz-sider>
    <nz-layout class="uc-content-layout">
      <div id="uc-content-router">
        <nz-tabset [nzType]="'card'" [(nzSelectedIndex)]="routerIndex">
          <nz-tab *ngFor="let tab of routerTabs; index as index;" [nzTitle]="titleTemplate">
            <ng-template #titleTemplate>
              <span [routerLink]="tab.url" class="ant-tabs-tab-span">
                <div>{{ tab.title }}</div>
              </span>
              <span class="ant-tabs-tab-i" (click)="closeTab(tab, index)" *ngIf="routerTabs.length > 1">
                <i nz-icon nzType="close" class="ant-tabs-close-x"></i>
              </span>
            </ng-template>
          </nz-tab>
        </nz-tabset>
      </div>
      <app-breadcrumb class="uc-content-top"></app-breadcrumb>
      <nz-content class="uc-content">
        <router-outlet></router-outlet>
      </nz-content>
      <nz-footer class="uc-footer">{{title}} ©{{year}}</nz-footer>
    </nz-layout>
  </nz-layout>
  <nz-back-top></nz-back-top>
</nz-layout>
