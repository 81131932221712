<nz-spin [nzSpinning]="isSubmitting">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">购买用户</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <nz-form-text>{{currentUser?.realname}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">账户权益</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text>{{selectUser?.accountEquity || 0 | number}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="6" [nzXs]="24">可用资金</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text style="color: red; font-weight: bold;">{{selectUser?.availableEquity || 0 | number}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" [nzRequired]="true" nzFor="mobile">手机号</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入手机号" >
        <input nz-input formControlName="mobile" id="mobile" placeholder="手机号" type="text" class="uc-input"  style="width: 200px;">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="companyId">片区</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请选择片区">
        <nz-select style="width: 100%;" formControlName="companyId" nzMode="default" nzPlaceHolder="选择片区"
                   (ngModelChange)="companyChange($event)">
          <ng-container *ngFor="let c of companyList">
            <nz-option [nzValue]="c.orgId" [nzLabel]="c.name"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="sellerCompanyId">销售商</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请选择销售商">
        <nz-select style="width: 100%;" formControlName="sellerCompanyId" nzMode="default" nzPlaceHolder="选择销售商"
                   (ngModelChange)="sellerCompanyChange($event)">
          <ng-container *ngFor="let c of sellerCompanyData">
            <nz-option [nzValue]="c.id" [nzLabel]="c.name"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="storageId">油库</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请选择油库">
        <nz-select style="width: 126px; margin-right: 10px" [(ngModel)]="cityCode" [ngModelOptions]="{standalone: true}" (ngModelChange)="cityChange($event)" nzPlaceHolder="选择城市">
          <nz-option *ngFor="let c of cityData" [nzValue]="c.cityCode" [nzLabel]="c.city"></nz-option>
        </nz-select>
        <nz-select style="width: 200px;" formControlName="storageId" nzPlaceHolder="选择油库" (ngModelChange)="storageChange($event)" [nzDisabled]="!cityCode">
          <nz-option *ngFor="let s of storageData" [nzValue]="s.id" [nzLabel]="s.storageName" ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="oilTypeCode">油品</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请选择油品">
        <nz-select style="width: 100%;" formControlName="oilTypeCode" nzMode="default" nzPlaceHolder="选择油品"
                   (ngModelChange)="oilTypeChange($event)">
          <ng-container *ngFor="let o of oilTypeList">
            <nz-option [nzValue]="o.key" [nzLabel]="o.label"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="volume">委托数量</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入委托数量">
        <nz-input-number formControlName="volume" [nzMin]="0" [nzStep]="10" style="width: 150px" (ngModelChange)="volumeChange($event)"></nz-input-number>
        <div style="color: red;">
          <ng-container *ngIf="validateForm.get('volume').value % 10 !== 0">
            <ng-container>
              *委托数量只能为10或10的整数倍数量
            </ng-container>
          </ng-container>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="price">委托价格</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入委托价格">
        <nz-input-number formControlName="price" [nzMin]="0"  [nzStep]="10" style="width: 150px"></nz-input-number><span> (元/吨)</span>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item >
      <nz-form-label [nzSm]="6" [nzXs]="24">操作方向</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <nz-form-text>委托买入</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="min">最小成交</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入最小成交">
        <nz-input-number formControlName="min" [nzMin]="0"  [nzStep]="10" style="width: 150px"></nz-input-number>
        <div style="color: red;">
          <ng-container *ngIf="validateForm.get('min').value % 10 !== 0">
            <ng-container>
              *最小成交数量只能为10或10的整数倍数量
            </ng-container>
          </ng-container>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="max">最大成交</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入最大成交">
        <nz-input-number formControlName="max" [nzMin]="0"  [nzStep]="10" style="width: 150px"></nz-input-number>
        <div style="color: red;">
          <ng-container *ngIf="validateForm.get('max').value % 10 !== 0">
            <ng-container>
              *最大成交量只能为10或10的整数倍数量
            </ng-container>
          </ng-container>
          <ng-container *ngIf="validateForm.get('max').value % 10 === 0 && validateForm.get('max').value < validateForm.get('min').value">
            <ng-container>
              *最大成交量 小于 最小成交量
            </ng-container>
          </ng-container>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row style="margin-bottom:8px;">
      <nz-form-control [nzSm]="{ span:14, offset:6 }" [nzXs]="{ span:14, offset:1 }">
        <button nz-button class="uc-btn uc-btn-main-create" nzType="primary" type="submit"
                [disabled]="!validateForm.valid || validateForm.get('volume').value % 10 !== 0 ||
              validateForm.get('min').value % 10 !== 0 || validateForm.get('max').value % 10 !== 0 ||
              validateForm.get('max').value < validateForm.get('min').value">确定
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
