import {ModalButtonOptions} from 'ng-zorro-antd';

/**
 * 回调指示的类型
 */
export enum FrontCallbackResultType {
  success,
  refresh,
  cancel,
  error
}

/**
 * 回调处理函数
 */
export type FrontCallBackHandler = (res: FrontCallbackResultType) => void;

/**
 * 模态框配置对象的接口
 */
export interface FrontModalOptModel {
  titleText: string;
  width: number;
  content: any;
  iconOpt: ModalIconOpt;
  footer: ModalButtonOptions[];
  onClose?: FrontCallBackHandler;
  maskClosable?: boolean;
}

/**
 * 审批状态：审核中 checking | 已拒绝 denied | 已接受 approved | 已出借 lent | 已分配 assigned | 已过期：expired
 */
export enum ApprovalStatus {
  checking,
  denied,
  approved,
  lent,
  assigned,
  expired,
}
/**
 * nz-icon 组件的属性接口，用于模态框标题渲染
 */
export interface ModalIconOpt {
  nzType: string;
  nzTheme: 'fill' | 'outline' | 'twotone';
  spin?: boolean;
  twoToneColor?: string;
  iconfont?: string;
}

export interface Entity {
  id?: string;
  createTime?: any; // 创建时间
  updateTime?: any; // 更新时间
  createUsername?: string; // 创建用户名
  createRealname?: string; // 创建用户名称
  updateUsername?: string; // 更新用户名
}

// 委托订单
export interface OrderModel extends Entity {
  orderCode?: string; // 委托编号 WT
  oilTypeCode?: string; // 油品编号
  oilType?: string; // 油品名称 0# 92# 95#
  companyName?: string; // 片区 - 中坤 - 福建
  companyId?: string; // 片区 - 中坤 - 福建
  sellerCompanyId?: string; // 上游
  sellerCompanyName?: string; // 上游
  storageName?: string; // 油库
  storageId?: string; // 油库
  orderDate?: any; // 委托日期 20221028 moment().format('YYYYMMDD')
  orderTime?: any; // 委托时间 new Date()
  timestamp?: number; // 委托时间戳
  operation?: number; // 操作类型 1-买入  2-卖出
  direction?: string; // 操作方向 BUY  SELL
  status?: number; // 委托状态  1-委托中（已报）  10-部成 20-已成 200-废单 201-部撤 203-已撤
  approveStatus?: number; // 1-待审核  10-已确认 200-已取消 201-作废 203-已撤单
  flag?: boolean; // ture: 审核委托  false: 不需要审核
  volume?: number; // 委托量
  remainingVolume?: number; // 委托剩余量  初始=volume
  price?: number; // 委托单价
  dealVolume?: number; // 成交量
  min?: number; // 最小成交数量 0 10 15
  max?: number; // 最大成交数量

  traderUsername?: string; // 委托用户
  traderId?: string; // 委托用户
  traderName?: string; // 委托用户
  mobile?: string; // 手机号

  orgTraderUsername?: string; // 委托用户
  orgTraderId?: string; // 委托用户
  orgTraderName?: string; // 委托用户
  orgTraderUserMobile?: string; // 委托用户手机号
  orgOrderId?: string; // 委托单id
  orgOrderCode?: string; // 委托编号
  approveUsername?: string; // 确认人用户名
  approveRealname?: string; // 确认人名称
  approveTime?: any; // 确认时间
  positionId?: string; // 持仓id
  fee?: number; // 交易费（手续费）
  feeRate?: number; // 手续费比例
  feeUnit?: number; // 手续费/吨
  feeType?: number; // 手续费类型 1-按吨  2-按比例
  bondRate?: number; // 保证金比例
  reserveBondRate?: number; // 预留保证金比例
  usedBond?: number; // 已用保证金
  availableBond?: number; // 可用保证金
  refund?: number; // 退还保证金

  checked?: boolean;

  tradeType?: number; // 交易类型 1-全款  2-托盘
  isRate?: boolean; // 是否计息  tradeType2-托盘
  rateType?: number; // 计息方式 1: 每天计息  2： 一周固定利息  tradeType2-托盘
  rate?: number; // 利率 tradeType2-托盘
  yearDays?: number; // 年利率天数 tradeType2-托盘
}
// 成交（审核）订单
export interface DealOrderModel extends Entity {
  dealCode?: string; // 成交编号 CJS(卖) / CJB(买)
  oilTypeCode?: string; // 油品编号
  oilType?: string; // 油品名称
  companyName?: string; // 片区 - 中坤 - 福建
  companyId?: string; // 片区 - 中坤 - 福建
  sellerCompanyId?: string; // 上游
  sellerCompanyName?: string; // 上游
  storageName?: string; // 油库
  storageId?: string; // 油库
  dealDate?: any; // 成交日期 YYYYMMDD
  dealTime?: any; // 成交时间
  timestamp?: number; // 成交时间戳
  operation?: number; // 操作类型 1-买入  2-卖出
  direction?: string; // 操作方向 BUY  SELL
  status?: number; // 状态  10-部成 20-成交  200-废单
  volume?: number; // 成交量
  price?: number; // 成交单价

  traderUsername?: string; // 成交用户
  traderId?: string; // 成交用户
  traderName?: string; // 成交用户
  mobile?: string; // 成交用户手机号
  flag?: boolean; // ture: 主动  false: 被动
  fee?: number; // 交易费（手续费）
  feeRate?: number; // 手续费比例
  feeUnit?: number; // 手续费/吨
  feeType?: number; // 手续费类型 1-按吨  2-按比例
  bond?: number; // 保证金
  bondRate?: number; // 保证金比例 tradeType
  closeProfit?: number; // 平仓盈亏
  storage?: number; // 0-正常 1-库存转持仓（买入）  2-持仓转库存（卖出）  3-建仓加仓（买入） 4-出仓减仓（卖出）
  usedBond?: number; // 已用保证金
  availableBond?: number; // 可用保证金
  refund?: number; // 退还保证金
  positionId?: string;

  tradeType?: number; // 交易类型 1-全款  2-托盘
  isRate?: boolean; // 是否计息  tradeType2-托盘
  rateType?: number; // 计息方式 1: 每天计息  2： 一周固定利息  tradeType2-托盘
  rate?: number; // 利率 tradeType2-托盘
  yearDays?: number; // 年利率天数 tradeType2-托盘
  accountsId?: string; // 账款id
}

// 持仓
// 建仓、加减仓功能
export interface PositionModel extends Entity {
  userId?: string; // 用户
  username?: string; // 用户
  name?: string; // 用户

  oilTypeCode?: string; // 油品编号
  oilType?: string; // 油品名称
  companyName?: string; // 片区 - 中坤 - 福建
  companyId?: string; // 片区 - 中坤 - 福建
  sellerCompanyId?: string; // 上游
  sellerCompanyName?: string; // 上游
  storageName?: string; // 油库
  storageId?: string; // 油库
  volume?: number; // 持仓量
  available?: number; // 可用持仓
  enabled?: boolean;
}

export interface CompanyPositionModel extends Entity {
  oilTypeCode?: string; // 油品编号
  oilType?: string; // 油品名称
  companyName?: string; // 片区 - 中坤 - 福建
  companyId?: string; // 片区 - 中坤 - 福建
  volume?: number; // 持仓量
}

export interface MovePositionLogModel extends Entity {
  traderId?: string;
  traderName?: string;
  traderUsername?: string;
  oilTypeCode?: string; // 油品编号
  oilType?: string; // 油品名称
  companyName?: string; // 片区 - 中坤 - 福建
  companyId?: string; // 片区 - 中坤 - 福建
  sellerId?: string; // 上游
  sellerName?: string; // 上游
  volume?: number; // 持仓量
  price?: number; // 价格
  operation?: number; // 操作类型 1-买入  2-卖出
  date?: any; // 操作时间
}

// 交易用户表
export interface TraderModel extends Entity {
  userId?: string; // 用户
  username?: string; // 用户
  name?: string; // 用户
  mobile?: string; // 手机号
  bondRate?: number; // 保证金比例
  reserveBondRate?: number; // 预留保证金比例
  feeRate?: number; // 手续费比例
  feeUnit?: number; // 手续费/吨
  feeType?: number; // 手续费类型 1-按吨  2-按比例
  enabled?: boolean;

  accountEquity?: number; // 账户权益（展示）（可用资金+委托锁定保证金+持仓占用保证金）
  availableEquity?: number; // 可用资金（展示）(剩余资金+floatProfit)
  availableFunds?: number; // 剩余资金（可用-floatProfit)（出入金+closeProfit-持仓占用保证金-委托锁定保证金）
  positionBond?: number; // 持仓占用保证金
  principal?: number; // 出入金/本金
  closeProfit?: number; // 累计平仓盈亏
  lockedFunds?: number; // 委托锁定资金
}

// 上游销售商
export interface SellerCompanyModel extends Entity {
  id?: string;
  name?: string;
  enaled?: boolean;
}

// 油库
export interface StorageModel extends Entity {
  storageName?: string;
  companyId?: string;
  companyName?: string;
  city?: string;
  cityCode?: string;
  enabled?: boolean;
}

export interface CityModel extends Entity {
  city?: string;
  cityCode?: string;
  enabled?: boolean;
  // MA-福州  MB-莆田 MC-泉州  MD-厦门 ME-漳州 MF-龙岩  MG-三明  MH-南平  MJ-宁德 MK-福建其他
  // YE-佛山 YS-东莞
}

// 片区
export interface CompanyModel extends Entity {
  name: string;
  orgId: string;
  enaled?: boolean;
}


// 出入金表
export interface DiscrepancyGold extends Entity {
  traderUsername?: string; // 成交用户
  traderId?: string; // 成交用户
  traderName?: string; // 成交用户
  mobile?: string; // 成交用户手机号
  amount?: number; // 出入金额
  type?: number; // 1-入金 2-出金
}
