import {Injectable} from '@angular/core';
import * as OSS from 'ali-oss';
declare var require: any;

@Injectable()
export class OssService {
  client;
  headers;

  constructor() {
    this.init();
  }


  init() {
    this.client = new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
      region: 'oss-cn-shenzhen',
      // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
      accessKeyId: 'LTAI5tRox5gpWE5Yj5Sv1noE',
      accessKeySecret: ' Dt3M1OvCrFtsHzkwV6PYT71oiiRfIL',
      bucket: 'xiaxing-oil-share'
    });

    this.headers = {
      // 指定Object的存储类型。
      // 'x-oss-storage-class': 'Standard',
      // 指定Object的访问权限。
      // 'x-oss-object-acl': 'private',
      // 设置Object的标签，可同时设置多个标签。
      // 'x-oss-tagging': 'Tag1=1&Tag2=2',
      // 指定PutObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
      'x-oss-forbid-overwrite': 'true',
    };
  }

  // 查看存储空间列表
  listBuckets() {
    try {
      // 列举当前账号所有地域下的存储空间。
      const result = this.client.listBuckets();
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  }

  // 查看文件列表
  list() {
    // 不带任何参数，默认最多返回100个文件。
    const result = this.client.list();
    console.log(result);
  }

  async putOSS(file, callBack) {
    try {
      const result = await this.client.put(file.name, file
        // 自定义headers
        //
      );

      callBack(result);
    } catch (e) {
      console.log(e);
    }
  }

  async deletOSS(fileName, callBack?) {
    try {
      const result = await this.client.delete(fileName
        // 自定义headers
        //
      );

      callBack(result);
    } catch (e) {
      console.log(e);
    }
  }

}
