import {Component, OnInit} from '@angular/core';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {MessageModel, QueryModelPagination, ViewModelPagination} from '../../system/system.model';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import { OrderModel, FrontModalOptModel, FrontCallbackResultType } from '../../system/oilFinance/finance.model';
import {DataTabListBaseComponent} from '../../system/oilFinance/oilFinanceListBase.component';
import {MatchmakingService} from '../matchmaking.service';
import {XlsxService} from '../../core/common/xlsx.service';
import {UcAuthService} from '../../core/auth';
import {CryptoJSService} from '../../system/common/cryptoJS';
import {differenceInCalendarDays} from 'date-fns';
@Component({
  selector: 'app-finance-deal-order-list',
  templateUrl: './dealOrderList.html',
  styles: [`
    .head-example {
      padding-right: 20px;
    }

    .uc-list-selects-select-date {
      margin-top: 15px;
    }

    .uc-list-selects-select-date div {
      float: left;
      margin-right: 15px;
    }
  `]
})
export class DealOrderListComponent extends DataTabListBaseComponent implements OnInit {
  orderStatus = {
    10: '部成',
    20: '已成',
    200: '废单'
  };

  orderStatusFilter = [
    {text: '部成', value: 10},
    {text: '已成', value: 20},
    {text: '废单', value: 200}
  ];

  operation = {
    1: '买入',
    2: '卖出'
  };


  tradeType = {
    1: '全款',
    2: '托盘'
  };

  dateType: boolean;
  startTime;
  startDate;
  endTime;
  endDate;

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date) > 0;
  }


  constructor(
    private matchmakingService: MatchmakingService,
    private xlsxService: XlsxService,
    authService: UcAuthService,
    crypto2: CryptoJSService,
    messageService: NzMessageService,
    private modalservice: NzModalService
  ) {
    super(authService, crypto2, messageService, modalservice);

    this.tabs = [
      {
        title: '成交订单', type: 1
      }
    ];

    this.approvalCount = 0;
  }

  ngOnInit() {
    this.init();
    this.getTargetText = `成交订单`;
    this.searchKey = 'traderName';
    this.sortKey = 'dealTime';
    this.sortValue = false;

    this.dateType = true;
    const date = moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00';
    const endDate = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';
    this.startTime = +new Date(date.replace(/-/g, '/'));
    this.endTime = +new Date(endDate.replace(/-/g, '/'));
    this.startDate = new Date(this.startTime);
    this.endDate = new Date(this.endTime);
    this.defaultSearch();
  }

  getDataListService(body: QueryModelPagination): Observable<ViewModelPagination<OrderModel>> {
    body.filters = [
      ...body.filters,
      {key: 'timestamp', type: 'Ge', value: this.startTime},
      {key: 'timestamp', type: 'Le', value: this.endTime}
    ];
    return this.matchmakingService.getDealList(body);
  }

  getModalOpt(type: string, modalOpt: FrontModalOptModel): FrontModalOptModel {
    switch (type) {
    }
    return modalOpt;
  }

  defaultSearch() {
    this.currentTab = 0;
    this.tabFiltersChange();
    this.searchData(true);
  }

  tabFiltersChange() {
    this.tabFitlers = [];
    this.currentTabType = this.tabs[this.currentTab]['type'];
    if (this.tabs[this.currentTab]['type'] === 1) {
      if (this.sysAdmin) {
      } else {
        this.tabFitlers = [
          {key: 'traderId', type: 'Eq', value: this.currentUser && this.currentUser.id}
        ];
      }
    }
  }

  getApprovalCount() {
  }

  rateToValue(r): number {
    let rate = 0;
    if (r) {
      rate = r * 100;
    }
    return rate;
  }

  // 文件导出
  exportAsFileAll() {
    const body: QueryModelPagination = {
    };

    if (!this.sysAdmin) {
      body.filters = [
        ...body.filters,
        {key: 'buyUserId', type: 'Eq', value: this.currentUser && this.currentUser.id}
      ];
    }

    this.matchmakingService.getDealListAllByBody(body).subscribe(
      res => {
        if (res.status === 200) {
          const datalists = res.data || [];
          this.exportAsFileData(datalists);
        }
      }
    );
  }

  exportAsFileCheck() {
    const jsonData = [];
    // 获取选中项和需更新项
    this.dataList.forEach((data): void => {
      if (data.checked) {
        jsonData.push(data);
      }
    });


    if (jsonData.length > 0) {
      this.exportAsFileData(jsonData);
    } else {
      this.messageService.warning('请选择要导出的成交单');
    }
  }

  exportAsFileData(jsonData) {
    let fileData = [];
    jsonData.forEach((item, index) => {
      fileData = [
        {
          '成交编号': item.code,
          '交易用户': item.traderName,
          '油品': item.oilType,
          '委托数量': item.volume,
          '委托价格': item.price,
          '手续费': item.fee,
          '操作类型': this.operation[item.operation],
          '操作方向': item.direction,
          '交易类型': this.tradeType[item.tradeType],
          '状态': this.orderStatus[item.status],
          '成交时间': moment(item.dealTime).format('YYYY-MM-DD HH:mm:ss')
        },
        ...fileData
      ];
    });

    if (fileData.length > 0) {
      this.xlsxService.exportList(fileData, '成交订单');
    } else {
      this.messageService.warning('导出内容为空');
    }
  }

  /**
   * 时间类型
   */
  dateTypeChange(e) {
    if (e) {
      const startDate = moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00';
      const endDate = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';

      this.startTime = +new Date(startDate.replace(/-/g, '/'));
      this.endTime = +new Date(endDate.replace(/-/g, '/'));
      this.startDate = new Date(this.startTime);
      this.endDate = new Date(this.endTime);

      this.refresh();
    } else {
      const date = moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00';
      const endDate = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';
      this.startTime = +new Date(date.replace(/-/g, '/')) - 360 * 24 * 60 * 60 * 1000;
      this.endTime = +new Date(endDate.replace(/-/g, '/')) - 24 * 60 * 60 * 1000;
      this.startDate = new Date(this.startTime);
      this.endDate = new Date(this.endTime);

      this.refresh();
    }
  }

  onStartOk(e) {
    if (e) {
      const date = moment(new Date(e)).format('YYYY-MM-DD') + ' 00:00:00';
      this.startTime = +new Date(date.replace(/-/g, '/'));
      this.startDate = new Date(this.startTime);
      this.refresh();
    }
  }

  onEndOk(e) {
    if (e) {
      const date = moment(new Date(e)).format('YYYY-MM-DD') + ' 23:59:59';
      this.endTime = +new Date(date.replace(/-/g, '/'));
      this.endDate = new Date(this.endTime);
      this.refresh();
    }
  }
}
