import {Injectable} from '@angular/core';
import {UcStorageType} from '../cache';
import {UcCacheService} from '../cache';
import {UcAuthConfig} from './authConfig';
import {Observable, ReplaySubject} from 'rxjs';
import {isBlank, getCode} from '../common';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Location} from '@angular/common';
import {CryptoJSService} from '../../system/common/cryptoJS';

export interface UcUser {
  username: string;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  credentialsNonExpired?: boolean;
  enabled?: boolean;
  email?: string;
  id?: string;
  initial?: number;
  mobile?: string;
  realname?: string;
  mainOrgPath?: string;
  mainOrgPathName?: string;
  roles?: string[];
  openid?: string; // 微信信息
  unionid?: string;
  headimgurl?: string;
}

export interface UcToken {
  access_token: string;
  token_type?: string;
  refresh_token?: string;
  expires_in?: number;
  scope?: string;
  expires_at?: number;
}

export interface UcAuthToken {
  data: {
    access_token: string;
    token_type?: string;
    refresh_token?: string;
    expires_in?: number;
    scope?: string;
    expires_at?: number;
  };
}


@Injectable()
export class UcAuthService {

  private static AUTH_CACHE_POOL_KEY = '__authCachePool__';
  private static AUTH_KEY = 'currentUser';
  private static TOKEN_KEY = 'token';
  private static LAST_PATH_KEY = 'lastPath';
  public static SYSTEM_ROOT = '/system';
  public static MATCHMAKING_ROOT = '/matchmaking';
  public static SYSTEM_INDEX = '/system/index';
  public static ACCOUNT_ROOT = '/account';

  private storageType: UcStorageType;
  private _currentUser: UcUser;
  private _token: UcToken;
  private _lastPath: string;
  /**
   * 在使用currentUser和Token前请检查改Subject状态
   */
  private authenticated = new ReplaySubject<boolean>(1);

  constructor(private cacheService: UcCacheService,
              private authConfig: UcAuthConfig,
              private http: HttpClient,
              private location: Location,
              private crypto: CryptoJSService) {
    this.storageType = isBlank(authConfig.storageType) ?
      UcStorageType.LocalStorage : authConfig.storageType;

    this.authenticated.next(this.tokenValidity);

    // this.token = {
    //   'access_token': '1d722f0815534b9d9bbd3427c2cf9ce1',
    //   'expires_in': 78539,
    //   'scope': 'scope_read scope_write',
    //   'token_type': 'bearer',
    //   'expires_at': 1639617681249
    // };
    // this.authenticated.next(true);
    // this.currentUser = {
    //   'username': 'admin',
    //   'accountNonExpired': true,
    //   'accountNonLocked': true,
    //   'credentialsNonExpired': true,
    //   'enabled': true,
    //   'id': 'a3c137723b344612ad602d4ae111fc4d',
    //   'initial': 0,
    //   'email': null,
    //   'mobile': null,
    //   'realname': 'admin',
    //   'mainOrgPath': '/26ab351b1619482c941c0e21d03cec9e/',
    //   'mainOrgPathName': '/厦门中坤海化石油有限公司/',
    //   'openid': null,
    //   'unionid': null,
    //   'headimgurl': null,
    //   'roles': [
    //     'frnzKsBlvhvbottpNFfipQ==',
    //     'pB53SFwn5gDnnsTjv7eFnXJbXdNE7MwK1DByfzoaoVM=',
    //     'SyLP0mWGmiU+mEeW1RcJqaDPS3uglX2QUYiqBqD3K/s=',
    //     'P1YQc9Oh8/avPMkeZ5nZZx6+GNoV1cRigMowztwCFa4=',
    //     'nOOzLpe77ZG5sspt99oDm+Ixqa2i53MX4QkE8aMsplw=',
    //     'H0ojBMKkNwoP4ZVcNgAJSdBgex6CjhwYvKmBZdlcrrQ='
    //   ]
    // };
    // this.currentUser = {
    //   'username': 'zhangns',
    //   'accountNonExpired': true,
    //   'accountNonLocked': true,
    //   'credentialsNonExpired': true,
    //   'enabled': true,
    //   'id': '4763f109b73349d688b2bf7121c8f4b8',
    //   'initial': 0,
    //   'email': null,
    //   'mobile': '1359000000',
    //   'realname': '张南山',
    //   'mainOrgPath': '/26ab351b1619482c941c0e21d03cec9e/1ec0e73448f24f9bb589e70ee005387d/',
    //   'mainOrgPathName': '/中坤海化/客户经理/',
    //   'openid': null,
    //   'unionid': null,
    //   'headimgurl': null,
    //   'roles': [
    //     '8X5jnvLj/cKnjRndqbNSx8Du8prAntCtryFflDSErBA=',
    //     '2Gu+BpmZUMpwamhcyjaiI0iCYAic7uYYfFi+aIm1+54=',
    //     'iE2RHyUdgItzJweULD3fnA==',
    //     'kEcHdZ4h4i1w2nvXr73OiA=='
    //   ]
    // };
  }

  private getClientCurrentUser(): Observable<UcUser> {
    return this.http.get<UcUser>(this.authConfig.client.user);
  }

  private getClientToken(): Observable<UcToken> {
    return this.http.get<UcToken>(this.authConfig.client.token);
  }

  private getClientLogout(): Observable<any> {
    return this.http.post<any>(this.authConfig.client.logout, null);
  }

  private getAuthLogout(): Observable<any> {
    return this.http.post<any>(this.authConfig.auth.logout, null);
  }

  private getAuthCurrentUser(): Observable<UcUser> {
    return this.http.get<UcUser>(this.authConfig.auth.user, {
      headers: {
        'Authorization': this.token.token_type + ' ' + this.token.access_token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
  }

  private getAuthToken(): Observable<UcAuthToken> {
    const state = getCode();
    return this.http.get<UcAuthToken>(this.authConfig.auth.code +
      '?client_id=' + this.authConfig.clientId + '&redirect_uri=' +
      this.authConfig.auth.loginToken + '&response_type=code&state=' + state, {});
  }

  private getCache<T>(key: string): T {
    return this.cacheService.get<T>({
      pool: UcAuthService.AUTH_CACHE_POOL_KEY,
      key: key,
      storageType: this.storageType
    });
  }

  private setCache(key: string, obj: Object) {
    this.cacheService.set({
      pool: UcAuthService.AUTH_CACHE_POOL_KEY,
      key: key,
      storageType: this.storageType
    }, obj);
  }

  private removeAllCache() {
    this.cacheService.removeAll({
      pool: UcAuthService.AUTH_CACHE_POOL_KEY,
      storageType: this.storageType
    });
  }


  set token(token: UcToken) {
    this._token = token;
    this.setCache(UcAuthService.TOKEN_KEY, token);
  }

  get token(): UcToken {
    if (this._token) {
      return this._token;
    }
    return this.getCache(UcAuthService.TOKEN_KEY);
  }

  set currentUser(user: UcUser) {
    this._currentUser = user;
    this.setCache(UcAuthService.AUTH_KEY, user);
  }

  get currentUser(): UcUser {
    if (this._currentUser) {
      return this._currentUser;
    }
    return this.getCache(UcAuthService.AUTH_KEY);
  }

  get username(): string {
    if (this.currentUser) {
      return this.currentUser.username;
    }
    return null;
  }

  get openid(): string {
    if (this.currentUser) {
      return this.currentUser.openid;
    }
    return null;
  }

  set lastPath(path: string) {
    this._lastPath = path;
    this.setCache(UcAuthService.LAST_PATH_KEY, path);
  }

  get lastPath(): string {
    if (this._lastPath) {
      return this._lastPath;
    }
    return this.getCache(UcAuthService.LAST_PATH_KEY);
  }

  get currentPath(): string {
    return this.location.path();
  }

  get currentTime(): number {
    return new Date().getTime();
  }

  get tokenValidity(): boolean {
    return this.token && this.token.expires_at && this.token.expires_at > this.currentTime;
  }

  /**
   * 检查服务器登录状态
   */
  login() {
    this.getClientToken().subscribe(
      (result) => {
        if (result && result.access_token) {
          const expires_at = this.currentTime + (result.expires_in ? result.expires_in : 1800) * 1000;
          this.token = {...result, expires_at: expires_at};
          this.getClientCurrentUser().subscribe(
            (user) => {
              this.currentUser = user;
              this.authenticated.next(true);
            }
          );
        } else {
          this.reset();
        }
      }
    );
  }

  authLogin() {
    this.getAuthToken().subscribe(res => {
      const result = res.data;
      if (result && result.access_token) {
        const expires_at = this.currentTime + (result.expires_in ? result.expires_in : 1800) * 1000;
        this.token = {...result, expires_at: expires_at};
        this.getAuthCurrentUser().subscribe(
          (user) => {
            this.currentUser = user;
            this.authenticated.next(true);
          }
        );
      } else {
        this.reset();
      }
    });
  }

  /**
   * 重置前端登录状态，跳转至登录页面
   */
  reset() {
    this.currentUser = null;
    this.token = null;
    this.lastPath = null;
    this.authenticated.next(false);
  }

  /**
   * 退出服务器登录状态，重置前端登录状态
   */
  logout() {
    this.getAuthLogout().subscribe((data) => {
      this.reset();
    });
  }


  /**
   * 判断是否已通过验证
   */
  isAuthenticated(): Observable<boolean> {
    return this.authenticated;
  }

  /**
   * 判断是否授权用户访问，拥有其中一个角色即可
   */
  isAuthorized(role: string | string[]): Observable<boolean> {
    const roles = Array.isArray(role) ? role : [role];
    if (this.currentUser && this.currentUser.roles) {
      return this.authenticated.pipe(
        map((value) => {
          return value ? this.currentUser && (!this.currentUser.roles) || roles.some(r => this.currentUser.roles.indexOf(this.crypto.encryptByEnAESn(r)) !== -1) : false;
        })
      );
    } else {
      return this.authenticated.pipe(
        map((value) => false)
      );
    }
  }
}
