<div *ngIf="sysAdmin || financeAdmin">
  <nz-layout>
    <nz-header class="uc-header">
      <div class="uc-logo" [ngStyle]="{backgroundImage: 'url(' + logo + ')'}" style="cursor: pointer;background-size: 100% 100%;"
           routerLink="/system"></div>
      <ul nz-menu [nzTheme]="'dark'" [nzMode]="'horizontal'" class="uc-header-nav">
        <ng-container>
          <li nz-menu-item routerLinkActive="ant-menu-item-selected" nzSelected>
            <a (click)="storageMobile()">联系客服</a>
          </li>
        </ng-container>
      </ul>
      <div class="uc-header-info" *ngIf="isAuthenticated | async">
        <nz-avatar [nzText]="letter | async" [nzIcon]="icon | async" nz-popover [nzPopoverTitle]="title" [nzPopoverContent]="content" nzPopoverPlacement="bottomRight"></nz-avatar>
        <ng-template #title>
          <div class="uc-header-info-title">
            <nz-avatar [nzText]="letter | async" [nzIcon]="icon | async"></nz-avatar>
            {{username | async}}
          </div>
        </ng-template>
        <ng-template #content>
          <div class="uc-header-info-content">
            <div nz-row nzGutter="8" nzType="flex" nzJustify="space-between">
              <div nz-col nzSpan="12">
                <a routerLink="/system/usercenter/my_account"><i nz-icon nzType="user" nzTheme="outline"></i> 用户信息</a>
              </div>
              <div nz-col nzSpan="12">
                <a routerLink="/system/usercenter/my_account"><i nz-icon nzType="safety" nzTheme="outline"></i> 账户安全</a>
              </div>
            </div>
            <div nz-row nzGutter="8" nzType="flex" nzJustify="space-between" hidden>
              <div nz-col nzSpan="12"><a><i nz-icon nzType="mail" nzTheme="outline"></i> 设置邮箱</a></div>
              <div nz-col nzSpan="12"><a><i nz-icon nzType="mobile" nzTheme="outline"></i> 设置手机</a></div>
            </div>
            <div nz-row nzGutter="8" nzType="flex" nzJustify="space-between">
              <div nz-col nzSpan="12"><a (click)="logout()"><i nz-icon nzType="poweroff" nzTheme="outline"></i> 退出登录</a></div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="uc-menu-right" *ngIf="financeAdmin">
        <ul nz-menu [nzTheme]="'dark'" [nzMode]="'horizontal'" class="uc-header-nav">
          <ng-container>
            <li nz-menu-item (click)="createModal('PositionList')">
              <span>我的持仓</span>
            </li>
            <li nz-menu-item (click)="createModal('OrderList')">
              <span>我的委托单</span>
            </li>
            <li nz-menu-item (click)="createModal('DealList')">
              <span>成交订单</span>
            </li>
          </ng-container>
        </ul>
      </div>
    </nz-header>
    <nz-layout class="uc-body-layout">
      <nz-layout class="uc-content-layout">
        <nz-content class="uc-content">
          <router-outlet></router-outlet>
        </nz-content>
        <nz-footer class="uc-footer">{{title}} ©2022</nz-footer>
      </nz-layout>
    </nz-layout>
    <nz-back-top></nz-back-top>
  </nz-layout>
</div>
<div *ngIf="!sysAdmin && !financeAdmin" class="none_user_content">
  <nz-empty
    [nzNotFoundImage]="'assets/images/no-data.png'"
    [nzNotFoundContent]="contentTpl"
    [nzNotFoundFooter]="footerTpl"
  >
    <ng-template #contentTpl>
      <span>当前账号没有操作权限，如有操作请联系后台人员！</span>
    </ng-template>
    <ng-template #footerTpl>
      <button nz-button nzType="primary" (click)="mmLogout()">退出登录</button>
    </ng-template>
  </nz-empty>
</div>
