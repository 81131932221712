import { Component, OnInit } from '@angular/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import {DataListBaseComponent} from '../../system/oilFinance/oilFinanceListBase.component';
import {MatchmakingService} from '../matchmaking.service';
import {UcAuthService} from '../../core/auth';
import {CryptoJSService} from '../../system/common/cryptoJS';
import { QueryModelPagination, ViewModelPagination } from 'src/app/system/system.model';
import {FrontModalOptModel} from '../../system/oilFinance/finance.model';
import {TraderBuyOrderComponent} from '../orderList/BuyOrder/traderBuyOrder.component';
import {TraderSellOrderComponent} from '../orderList/traderSellOrder/traderSellOrder.component';
@Component({
  selector: 'app-position-list',
  templateUrl: './position.html'
})
export class PositionComponent extends DataListBaseComponent implements OnInit {
  dataList: any[];

  constructor(
    private matchmakingService: MatchmakingService,
    crypto: CryptoJSService,
    authService: UcAuthService,
    messageService: NzMessageService,
    modalService: NzModalService
  ) {
    super(authService, crypto, messageService, modalService);

  }

  ngOnInit() {
    this.init();
    this.getTargetText = `用户持仓列表`;
    this.searchKey = 'oilType';
    this.searchData();
  }

  getDataListService(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    body.filters = [
      {key: 'userId', type: 'Eq', value: this.currentUser && this.currentUser.id || 'noneData'},
      ...body.filters
    ];

    return this.matchmakingService.getTraderPositionList(body);
  }

  getModalOpt(type: string, modalOpt: FrontModalOptModel): FrontModalOptModel {
    switch (type) {
      case 'Buy': {
        modalOpt.iconOpt.nzType = 'plus';
        modalOpt.titleText = '委托买入';
        modalOpt.content = TraderBuyOrderComponent;
        modalOpt.width = 500;
        modalOpt.onClose = (res) => {
          this.search();
        };
        break;
      }
      case 'Sell': {
        modalOpt.iconOpt.nzType = 'plus';
        modalOpt.titleText = '委托卖出';
        modalOpt.content = TraderSellOrderComponent;
        modalOpt.width = 500;
        modalOpt.onClose = (res) => {
          this.search();
        };
        break;
      }
    }
    return modalOpt;
  }
}
