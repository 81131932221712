import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService, NzModalRef, NzTreeNode} from 'ng-zorro-antd';
import {BehaviorSubject, Observable, of} from 'rxjs';
import * as moment from 'moment';
import {MatchmakingService} from '../../matchmaking.service';
import { OrderModel } from 'src/app/system/oilFinance/finance.model';
import { QueryModelPagination } from 'src/app/system/system.model';
import {UcAuthService, UcUser} from '../../../core/auth';
import {CryptoJSService} from '../../../system/common/cryptoJS';

@Component({
  selector: 'app-oil-trader-buy-order-add',
  templateUrl: './traderBuyOrder.html'
})
export class TraderBuyOrderComponent implements OnInit, OnDestroy {
  companyList;
  selectCompany;
  sellerCompanyData;
  selectSellerCompany;
  storageData;
  cityData;
  cityCode;
  oilTypeList = [
    {key: 'c0', label: '0#'},
    {key: 'c92', label: '92#'},
    {key: 'c95', label: '95#'}
  ];
  available;

  validateForm: FormGroup;

  isSubmitting: boolean;
  financeAdmin: boolean;
  currentUser: UcUser;
  selectUser;

  constructor(
    private matchmakingService: MatchmakingService,
    private fb: FormBuilder,
    private messageService: NzMessageService,
    private modalRef: NzModalRef,
    private authService: UcAuthService,
    private crypto: CryptoJSService
  ) {
    this.currentUser = this.authService.currentUser;
    this.financeAdmin = this.currentUser ? (this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_trader')) || this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_oil_fi_admin'))) : false;
  }

  ngOnInit() {
    this.available = 0;

    this.validateForm = this.fb.group({
      orderCode: [],
      traderId: [null, [Validators.required]],
      traderName: [null],
      traderUsername: [null],
      mobile: [null],
      flag: [false],
      oilTypeCode: [null, [Validators.required]],
      oilType: [null],
      sellerCompanyId: [null, [Validators.required]],
      sellerCompanyName: [null],
      storageId: [null, [Validators.required]],
      storageName: [null],
      companyName: [null],
      companyId: [null, [Validators.required]],
      orderDate: [null],
      orderTime: [null],
      timestamp: [null],
      operation: [1, [Validators.required]],
      direction: ['BUY'],
      status: [1, [Validators.required]],
      approveStatus: [10, [Validators.required]],
      volume: [null, [Validators.required]],
      remainingVolume: [null],
      dealVolume: [0],
      price: [null, [Validators.required]],
      min: [10, [Validators.required]],
      max: [10, [Validators.required]]
    });

    this.validateForm.get('traderId').setValue(this.currentUser.id);
    this.validateForm.get('traderUsername').setValue(this.currentUser.username);
    this.validateForm.get('traderName').setValue(this.currentUser.realname);
    this.validateForm.get('mobile').setValue(this.currentUser.mobile);
    this.getCompanyList();
    this.getCityData();
    this.getSellerCompanyData();
    this.getUserList();
  }

  ngOnDestroy() {
    this.modalRef.destroy();
  }

  generateInfoSubmitted(): OrderModel {
    const formObj = this.validateForm.value;
    const date = moment(new Date()).format('YYYYMMDDHHmmss');
    formObj.orderCode = 'WT' + date ;
    formObj.orderDate = moment(new Date()).format('YYYYMMDD');
    formObj.orderTime = new Date();
    formObj.timestamp = +new Date();
    formObj.remainingVolume = formObj.volume;
    return formObj;
  }

  getCompanyList() {
    const body: QueryModelPagination = {
      filters: [
        {key: 'enabled', type: 'Eq', value: true}
      ]
    };
    this.matchmakingService.getCompanyList(body).subscribe(res => {
      if (res.status === 200) {
        this.companyList = res.data || [];
      }
    });
  }

  companyChange(e) {
    if (e) {
      this.selectCompany = this.companyList.filter(item => item.orgId === e)[0];
      this.validateForm.get('companyName').setValue(this.selectCompany.name || '');
    }
  }

  oilTypeChange(e) {
    if (e) {
      const oiltype = this.oilTypeList.filter(item => item.key === e)[0];
      this.validateForm.get('oilType').setValue(oiltype.label);
    }
  }


  volumeChange(e) {
    const data = e || 0;
    const data2 = data % 10;

    if (data2 !== 0) {
      this.validateForm.get('max').setValue(data - data2);
    } else {
      this.validateForm.get('max').setValue(data);
    }
  }

  getCityData() {
    const body: QueryModelPagination = {
      filters: [
        {key: 'enabled', type: 'Eq', value: true}
      ]
    };

    this.matchmakingService.getCityList(body).subscribe(res => {
      if (res.status === 200) {
        this.cityData = res.data || [];
      }
    });
  }

  cityChange(e) {
    if (e) {
      this.storageData = [];
      this.validateForm.get('storageName').setValue('');
      this.validateForm.get('storageId').setValue('');
      this.getStorageData(e);
    }
  }

  getStorageData(code?) {
    const body: QueryModelPagination = {
      filters: [
        {key: 'enabled', type: 'Eq', value: true},
        {key: 'cityCode', type: 'Eq', value: code || 'noneData'},
      ]
    };

    this.matchmakingService.getStorageList(body).subscribe(res => {
      if (res.status === 200) {
        this.storageData = res.data || [];
      }
    });
  }

  storageChange(e) {
    if (e) {
      const storage = this.storageData.filter(item => item.id === e)[0];
      this.validateForm.get('storageName').setValue(storage && storage.storageName || '');
    }
  }

  getSellerCompanyData() {
    const body: QueryModelPagination = {
      filters: [
        {key: 'enabled', type: 'Eq', value: true}
      ]
    };

    this.matchmakingService.getSellerCompanyListAllByBody(body).subscribe(res => {
      if (res.status === 200) {
        this.sellerCompanyData = res.data || [];
      }
    });
  }

  sellerCompanyChange(e) {
    if (e) {
      this.selectSellerCompany = this.sellerCompanyData.filter(item => item.id === e)[0];
      this.validateForm.get('sellerCompanyName').setValue(this.selectSellerCompany && this.selectSellerCompany.name || '');
    }
  }

  submitForm() {
    this.isSubmitting = true;
    const formObj = this.generateInfoSubmitted();
    this.matchmakingService.add(formObj).subscribe(res => {
      if (res.status === 200) {
        this.messageService.success('委托下单成功，请等待审核确认！');
        this.modalRef.destroy(1);
      }
      this.isSubmitting = false;
    }, () => {
      this.messageService.error('委托下单失败！');
      this.isSubmitting = false;
    });
  }

  getUserList() {
    const body: QueryModelPagination = {
      filters: [
        {key: 'userId', type: 'Eq', value: this.currentUser && this.currentUser.id || 'noneData'}
      ]
    };

    this.matchmakingService.getTraderListAllByBody(body).subscribe(res => {
      if (res.status === 200) {
        const userList = res.data || [];
        this.selectUser = userList[0];
      }
    });
  }
}
