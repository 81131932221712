<div class="uc-container">
  <div class="uc-sub-header">
    <div nz-row nzGutter="16" nzType="flex" nzJustify="space-between" nzAlign="middle">
      <div nz-col nzSpan="15"><!--左侧功能按钮-->
        <div class="uc-btn-group">
          <button nz-button nzType="primary" class="uc-btn uc-btn-main-success" (click)="createComponentModal('Buy')">
            委托买入
          </button>
          <button nz-button nzType="primary" class="uc-btn uc-btn-main-create" (click)="createComponentModal('Sell')">
            委托卖出
          </button>
        </div>
      </div>
      <div nz-col nzSpan="9"><!--右侧搜索框-->
        <div nz-row nzGutter="5" nzType="flex" nzJustify="end" nzAlign="middle">
          <div nz-col>
            <nz-select [(ngModel)]="searchKey" (ngModelChange)="searchKeyChange()" class="uc-select-btn" nzPlaceHolder="选择字段">
              <nz-option [nzLabel]="'油品'" [nzValue]="'oilType'"></nz-option>
              <nz-option [nzLabel]="'片区'" [nzValue]="'companyName'"></nz-option>
              <nz-option [nzLabel]="'销售商'" [nzValue]="'sellerCompanyName'"></nz-option>
              <nz-option [nzLabel]="'油库'" [nzValue]="'storageName'"></nz-option>
            </nz-select>
          </div>
          <div nz-col nzSpan="10">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixSearchButton">
              <input type="text" nz-input placeholder="搜索" class="uc-input"
                     [(ngModel)]="searchValue" (keyup.enter)="search()">
            </nz-input-group>
            <ng-template #suffixSearchButton>
              <button nz-button nzSearch nzType="primary" (click)="search()"
                      class="uc-btn uc-btn-main-create uc-btn-icon">
                <i nz-icon nzType="search" nzTheme="outline"></i></button>
            </ng-template>
          </div>
          <div nz-col>
            <button nz-button nzType="primary" (click)="refresh()" class="uc-btn uc-btn-icon uc-btn-main-create"
                    style="width:32px">
              <i nz-icon nzType="reload" nzTheme="outline"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div nz-row nzGutter="16" nzType="flex" nzJustify="space-between" nzAlign="middle">
    <div nz-col nzSpan="24"><!--表格-->
      <nz-spin [nzSpinning]="isSpinning">
        <nz-table
          #infoTable class="uc-table"
          [nzData]="dataList"
          [nzScroll]="{x: '600px'}"
          nzSize="middle"
          nzVirtualScroll
          [nzLoading]="false"
          [nzNoResult]="'未查询到' + getTargetText"
          nzShowSizeChanger
          [nzFrontPagination]="false"
          [nzTotal]="total"
          [(nzPageIndex)]="pageIndex"
          [(nzPageSize)]="pageSize"
          (nzPageIndexChange)="searchData()"
          (nzPageSizeChange)="searchData(true)"
          (nzCurrentPageDataChange)="currentPageDataChange($event)"
        >
          <thead>
          <tr>
            <th nzWidth='100px'>姓名</th>
            <th nzWidth='100px'>当前持仓</th>
            <th nzWidth='100px'>可用持仓</th>
            <th nzWidth='200px'>持仓占用保证金</th>
            <th nzWidth='150px'>浮动盈亏</th>
            <th nzWidth='150px'>持仓均价</th>
            <th nzWidth='150px'>最新市价</th>
            <th nzWidth='100px'>油品</th>
            <th nzWidth='150px'>片区</th>
            <th nzWidth='150px'>销售商</th>
            <th nzWidth='150px'>油库</th>
            <th nzWidth='80px' nzShowFilter [nzFilters]="enabledFilter" [nzFilterMultiple]="false" (nzFilterChange)="updateFilter('enabled', $event)">状态</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of infoTable.data;index as index">
            <td>{{data.name}}</td>
            <td>{{data.volume | number}}</td>
            <td>{{data.available | number}}</td>
            <td>{{data.positionEquity || 0 | number}}</td>
            <td>{{data.positionProfit || 0 | number}}</td>
            <td>{{data.positionPrice || 0 | number}}</td>
            <td>{{data.close || 0 | number}}</td>
            <td>{{data.oilType}}</td>
            <td>{{data.companyName}}</td>
            <td>{{data.sellerCompanyName}}</td>
            <td>{{data.storageName}}</td>
            <td>
              <span [style.color]="data.enabled ? '#1890ff' : 'red'">
                <i nz-icon nzType="{{data.enabled ? 'check-circle' : 'close-circle'}}" nzThem="outline"></i>
              </span>
            </td>
          </tr>
          </tbody>
        </nz-table>
      </nz-spin>
    </div>
  </div>
</div>
<ng-template #modalHeader>
  <div class="uc-modal-title-wrapper">
    <span class="uc-form-header">
      <i nz-icon [nzType]='modalIconOpt.nzType' [nzTheme]='modalIconOpt.nzTheme'
         [nzSpin]="modalIconOpt.spin" [nzIconfont]="modalIconOpt.iconfont"></i>&nbsp;&nbsp;{{modalTitleText}}
    </span>
  </div>
</ng-template>
