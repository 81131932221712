import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  FrontCallBackHandler,
  FrontCallbackResultType,
  FrontModalOptModel,
  OrderModel, PositionModel, TraderModel
} from '../../system/oilFinance/finance.model';
import {MatchmakingService} from '../matchmaking.service';
import {Observable} from 'rxjs';
import {Filter, FilterType, QueryModelPagination, ViewModelPagination} from '../../system';
import { NzModalService } from 'ng-zorro-antd';
import {BuyOrderComponent} from './BuyOrder/BuyOrder.component';
import {SellOrderComponent} from './SellOrder/SellOrder.component';
import {UcAuthService, UcUser} from '../../core/auth';
import {CryptoJSService} from '../../system/common/cryptoJS';
import {UcChartComponent} from '../../system/common/ucChart/ucChart.component';
import {CashListComponent} from './cashList/cashList.component';

@Component({
  selector: 'app-matchmaking-home',
  templateUrl: 'home.html',
  styleUrls: ['./home.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('QuotationChart', {static: true}) QuotationChartRef: UcChartComponent;
  direction: number;
  dataList: OrderModel[];
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  isSpinning: boolean;
  defaultData: boolean;
  searchKey: string;
  searchValue: string;
  // nz-table filter字符串
  filterValue: any;
  filterKey: string;
  filters: Filter[];
  // sort 字符串
  sortKey: string;
  sortValue: boolean;

  allChecked: boolean;
  displayData: any[];
  indeterminate: boolean;
  disabledBatchButton: boolean;
  operating: {[name in 'toApproved' | 'toDenied' | 'toChecking' | 'onDelete' | 'toContinue' | 'toStop' | 'toBatch']?: boolean};
  checkedNumber: number;

  companyList;
  companyId;
  selectCompany;
  sellerCompanyList;
  selectSeller;
  sellerId;
  cityCode;
  cityList;
  storageList;
  selectStorage;
  storageId;

  oilTypeChartList = [
    {key: '0001', label: '0#'},
    {key: '92001', label: '92#'},
    {key: '95001', label: '95#'}
  ];
  oilTypeKey;
  selectChartOilType;

  oilTypeList = [
    {key: 'c0', label: '0#'},
    {key: 'c92', label: '92#'},
    {key: 'c95', label: '95#'}
  ];
  selectOilType;
  oilTypeCode;

  hiddenIt;
  isDestory;

  sysAdmin: boolean;
  financeAdmin: boolean;
  isTraderSpinning: boolean;
  isPositionSpinning: boolean;
  traderInfo: TraderModel;
  positionInfo: PositionModel[];

  chartDataInfo = {
    grid01: {
      title: '行情报价',
      chartId: 'c2861cd55d654bf78fde74a899223a21'
    }
  };


  get currentUser(): UcUser {
    return this.authService.currentUser;
  }


  constructor(
    private matchmakingService: MatchmakingService,
    private modalService: NzModalService,
    private authService: UcAuthService,
    private crypto: CryptoJSService
  ) {
    this.sysAdmin = this.currentUser ? (this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_sys_admin')) || this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_root_admin'))) : false;
    this.financeAdmin = this.currentUser ? (this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_trader')) || this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_oil_fi_admin'))) : false;
  }

  ngOnInit() {
    this.init();
    this.direction = 2;
    this.sortKey = 'orderTime';
    this.sortValue = false;
    this.isDestory = false;
    this.defaultData = true;
    this.oilTypeKey = '0001';
    this.selectChartOilType = this.oilTypeChartList[0];
    this.getCompanyList();
    this.getSellerCompanyData();
    this.getCity();

    this.search();

    if (this.financeAdmin) {
      this.getTrader();
    }
    this.defaultChartReset();

  }


  ngAfterViewInit() {
    const hiddenProperty = 'hidden' in document ? 'hidden' :
      'webkitHidden' in document ? 'webkitHidden' :
        'mozHidden' in document ? 'mozHidden' :
          null;
    const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

    // 浏览器标签页切换监听函数
    const onVisibilityChange = () => {
      if (!this.isDestory) {
        let hiddenSecond = 0;
        if (this.hiddenIt) {
          clearInterval(this.hiddenIt);
        }
        // 判断document.hidden
        if (document[hiddenProperty]) {
          // 切换标签页,隐藏
          this.hiddenIt = setInterval(() => {
            hiddenSecond += 1;
            if (hiddenSecond > 5) {
              clearInterval(this.hiddenIt);
            }
          }, 1000);
        } else {
          // 切换标签页,显示
          this.search();
          if (this.financeAdmin) {
            this.getTrader();
          }
        }
      }
    };
    document.addEventListener(visibilityChangeEvent, onVisibilityChange);


    this.matchmakingService.closeObservable.subscribe(res => {
      if (res && res === 1) {
        this.refresh();
        if (this.financeAdmin) {
          this.getTrader();
        }
      }
    });
  }

  ngOnDestroy() {
    this.isDestory = true;
  }

  directionChange(e) {
    this.direction = e || 1;
    this.refresh();
  }

  getDatalistObservice(body: QueryModelPagination): Observable<ViewModelPagination<OrderModel>> {
    body.filters = [
      ...body.filters,
      {key: 'status', type: 'Lt', value: 20},
      {key: 'flag', type: 'Eq', value: false}
    ];


    if (!this.defaultData) {
      body.filters = [
        ...body.filters,
        {key: 'companyId', type: 'Eq', value: this.companyId || 'NoneData'},
        {key: 'sellerCompanyId', type: 'Eq', value: this.sellerId || 'NoneData'},
        {key: 'storageId', type: 'Eq', value: this.storageId || 'NoneData'},
        {key: 'oilTypeCode', type: 'Eq', value: this.oilTypeCode || 'NoneData'}
      ];
    }

    return this.matchmakingService.getList(body);
  }


  init(): void {
    this.dataList = [];
    this.pageIndex = 1;
    this.pageSize = 20;
    this.totalItems = 0;
    this.searchValue = null;
    this.filterValue = null;
    this.filterKey = null;
    this.filters = [];

    this.oilTypeCode = 'c0';
  }

  searchData(reset: boolean = false): void {
    this.isSpinning = true;
    const defaultFilters = {
      resetFilters: (key: string, type: string, value: any) => {
        if (this.filters.length > 0) {
          const include = this.filters.map((f, num) => {
            if (f.key === key) {
              if (value !== null && value !== '') {
                f.value = value;
              } else {
                this.filters.splice(num, 1);
              }
            }
            return f.key === key;
          });

          if (!include.includes(true)) {
            if (value !== null && value !== '') {
              this.filters.push({key: key, type: type, value: value});
            }
          }
        } else {
          if (value !== null && value !== '') {
            this.filters.push({key: key, type: type, value: value});
          }
        }
      }
    };

    if (reset) {
      this.pageIndex = 1;
    }

    const body: QueryModelPagination = {
      pageSize: this.pageSize,
      pageIndex: this.pageIndex
    };
    const searchFilters = [];

    if (this.searchValue) {
      if (this.searchKey === 'companyId') {
        searchFilters.push({key: this.searchKey, type: FilterType[FilterType.Eq], value: this.searchValue});
      } else if (this.searchKey === 'companyIds') {
        searchFilters.push({key: this.searchKey, type: FilterType[FilterType.In], value: this.searchValue});
      } else {
        searchFilters.push({key: this.searchKey, type: FilterType[FilterType.Like], value: this.searchValue});
      }
    }

    if (this.filterKey) {
      if (this.filterKey === 'status' || this.filterKey === 'enabled' || this.filterKey === 'isPay'
        || this.filterKey === 'code' || this.filterKey === 'companyId' || this.filterKey === 'id') {
        defaultFilters.resetFilters(this.filterKey, FilterType[FilterType.Eq], this.filterValue);
      } else if (this.filterKey === 'companyIds') {
        searchFilters.push({key: this.filterKey, type: FilterType[FilterType.In], value: this.filterValue});
      } else {
        defaultFilters.resetFilters(this.filterKey, FilterType[FilterType.Like], this.filterValue);
      }
    }

    if (this.sortKey) {
      body.sort = {
        key: this.sortKey,
        ascending: this.sortValue
      };
    }

    body.filters = [
      ...searchFilters,
      ...this.filters,
      {key: 'operation', type: 'Eq', value: this.direction}
    ];

    this.getDatalistObservice(body).subscribe(res => {
      if (res.status === 200) {
       this.dataList = res.data || [];
       this.totalItems = res.totalItems;
      }
      this.isSpinning = false;
    }, () => {
      this.isSpinning = false;
    });
  }

  /**
   * 搜索
   */
  search(): void {
    this.searchData(true);
  }

  filterSearch() {
    this.defaultData = false;
    this.searchData(true);
  }

  searchKeyChange() {
    // this.searchValue = null;
  }

  /**
   * 全组件处理回调结果，注意闭包问题
   * @param resType 回调结果类型
   */
  handleCallBack: FrontCallBackHandler = (resType: FrontCallbackResultType): void => {
    switch (resType) {
      case FrontCallbackResultType.refresh: {
        this.searchData();
        this.getTrader();
        break;
      }
      default : {
        // this.searchData();
        break;
      }
    }
  }

  /**
   * 表格功能：表格当前页的数据源改变时，读取当前页数据作为属性值
   * @param $event 新的当前页数据
   */
  currentPageDataChange($event: Array<any>): void {
    this.displayData = $event;
    if (this.displayData.length !== 0) {
      this.refreshStatus();
    }
  }

  /**
   * 表格功能：判断当前选择状态，并更新有关的属性变量
   */
  refreshStatus(e?): void {
    const allChecked = this.displayData.every(value => value.checked === true);
    /* .every() 不会检测空数组 */
    const allUnChecked = this.displayData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    this.disabledBatchButton = !this.dataList.some(value => value.checked);
    this.checkedNumber = this.dataList.filter(value => value.checked).length;
  }

  /**
   * 表格功能：全选
   * @param value 全选，或（全选时）全清
   */
  checkAll(value: boolean): void {
    this.displayData.forEach(data => data.checked = value);
    this.refreshStatus();
  }

  /**
   * 表格功能：清空所有选中状态，注意闭包问题
   */
  clearSelection = (): void => {
    this.dataList.forEach((data): void => {
      data.checked = false;
    });
    this.displayData = this.dataList;
    this.refreshStatus();
  }

  /**
   * 清空搜索状态并刷新列表
   */
  refresh(): void {
    this.searchValue = null;
    this.filterValue = null;
    this.filterKey = null;
    this.filters = [];
    this.defaultData = true;
    this.searchData(true);
  }

  updateFilter(key: string, value: string): void {
    this.filterKey = key;
    this.filterValue = value;
    this.searchData(true);
  }

  /**
   * 排序
   */
  sort(sort: { key: string, value: string }) {
    this.sortKey = sort.value !== null ? sort.key : null;
    this.sortValue = sort.value === 'ascend' ? true : false;
    this.searchData(true);
  }

  // 获取片区
  getCompanyList() {
    this.matchmakingService.getCompanyList({filters: [{key: 'enabled', type: 'Eq', value: true}]}).subscribe(res => {
      if (res.status === 200) {
        this.companyList = res.data || [];

        if (this.companyList.length > 0) {
          this.companyId = this.companyList[0]['id'];
        }
      }
    });
  }

  companyChange(e) {
    if (e) {
      this.selectCompany = this.companyList.filter(item => item.orgId === e)[0];
    }
  }

  oilTypeChange(e) {
    if (e) {
      this.selectOilType = this.oilTypeList.filter(item => item.key === e)[0];
    }
  }

  getSellerCompanyData() {
    const body = {filters: [{key: 'enabled', type: 'Eq', value: true}]};

    this.matchmakingService.getSellerCompanyListAllByBody(body).subscribe(res => {
      if (res.status === 200) {
        this.sellerCompanyList = res.data || [];

        if (this.sellerCompanyList.length > 0) {
          this.sellerId = this.sellerCompanyList[0]['id'];
        }
      }
    });
  }

  sellerChange(e) {
    if (e) {
      this.selectSeller = this.sellerCompanyList.filter(item => item.id === e)[0];
    }
  }

  getCity() {
    const body = {filters: [{key: 'enabled', type: 'Eq', value: true}]};

    this.matchmakingService.getCityList(body).subscribe(res => {
      if (res.status === 200) {
        this.cityList = res.data || [];

        if (this.cityList.length > 0) {
          this.cityCode = this.cityList[0]['cityCode'];
        }

        this.getStorageData(this.cityCode);
      }
    });
  }

  cityChange(e) {
    if (e) {
      this.getStorageData(e);
    }
  }

  getStorageData(cityCode) {
    const body = {
      filters: [
        {key: 'enabled', type: 'Eq', value: true},
        {key: 'cityCode', type: 'Eq', value: cityCode || 'noneData'}
      ]
    };

    this.matchmakingService.getStorageList(body).subscribe(res => {
      if (res.status === 200) {
        this.storageList = res.data || [];

        if (this.storageList.length > 0) {
          this.storageId = this.storageList[0]['id'];
        }
      }
    });
  }

  storageChange(e) {
    if (e) {
      this.selectStorage = this.storageList.filter(item => item.id === e)[0];
    }
  }

  /**
   * modal创建
   */

  createModal(type, data) {
    // 默认配置
    let modalOpt: FrontModalOptModel = {
      iconOpt: {nzType: null, nzTheme: 'outline'}, /* 默认设 nzTheme='outline' */
      titleText: null,
      width: 520, /* 默认值 520 */
      content: null,
      footer: null, /* 默认值 null */
      onClose: this.handleCallBack,
      maskClosable: false /* 默认值设为 false，点击蒙层不关闭 */
    };

    modalOpt = this.getModalOpt(type, modalOpt);

    const modal = this.modalService.create({
      nzTitle: `<div class="uc-modal-title-wrapper">
                        <span class="uc-form-header"><i nz-icon nzType="table" nzTheme="outline"></i> ${modalOpt.titleText}</span>
                      </div>`,
      nzContent: modalOpt.content,
      nzComponentParams: {
        info: data
      },
      nzMaskClosable: modalOpt.maskClosable,
      nzWrapClassName: 'uc-modal',
      nzWidth: modalOpt.width,
      nzFooter: modalOpt.footer
    });

    // 订阅该模态框的关闭事件
    if (modalOpt.onClose) {
      modal.afterClose.subscribe(modalOpt.onClose);
    }
  }

  getModalOpt(type: string, modalOpt: FrontModalOptModel): FrontModalOptModel {
    switch (type) {
      case 'Buy': {
        modalOpt.titleText = '委托买入';
        modalOpt.content = BuyOrderComponent;
        modalOpt.width = 700;
        modalOpt.onClose = (res) => {
          this.search();
        };
        break;
      }
      case 'Sell': {
        modalOpt.titleText = '委托卖出';
        modalOpt.content = SellOrderComponent;
        modalOpt.width = 700;
        modalOpt.onClose = (res) => {
          this.search();
        };
        break;
      }
      case 'FUNDING': {
        modalOpt.titleText = '出入金记录';
        modalOpt.content = CashListComponent;
        modalOpt.width = 800;
        modalOpt.onClose = (res) => {
          this.search();
        };
        break;
      }
    }
    return modalOpt;
  }


  /**
   * 获取账户、持仓
   */
  getTrader() {
    if (this.financeAdmin) {
      this.isTraderSpinning = true;
      const body: QueryModelPagination = {
        filters: [
          {key: 'userId', type: 'Eq', value: this.currentUser && this.currentUser.id || 'noneData'}
        ]
      };

      this.matchmakingService.getTraderList(body).subscribe(res => {
        if (res.status === 200) {
          if (res.data && res.data.length > 0) {
            this.traderInfo = res.data[0];
          } else {
            this.traderInfo = null;
          }
        }
        this.isTraderSpinning = false;
      });
    }
  }



  rateToValue(r): number {
    let rate = 0;
    if (r) {
      rate = r * 100;
    }
    return rate;
  }

  /**
   * 图表
   */

  defaultChart(chartRef: UcChartComponent, grid) {
    if (chartRef) {
      chartRef.id = grid.chartId;
      chartRef.parseChart();
    }
  }

  restModelList(ref, body: QueryModelPagination) {
    if (ref) {
      ref.queryModelList = body;
    }
  }

  restChartQueryModelList() {
    const body: QueryModelPagination = {
      pageSize: 10000000,
      sort: {key: 'timestamp', ascending: false},
      filters: [
        {key: 'close', type: 'Not', value: 0},
        {key: 'contract', type: 'Eq', value: this.oilTypeKey}
      ]
    };
    this.restModelList(this.QuotationChartRef, body);
  }

  defaultChartReset() {
    setTimeout(() => {
      this.restChartQueryModelList();
      this.defaultChart(this.QuotationChartRef, this.chartDataInfo.grid01);
    });
  }

  resetChart(ref: UcChartComponent) {
    if (ref) {
      this.restChartQueryModelList();
      ref.restChartTmplate();
    }
  }

  oilChartTypeChange(e) {
    if (e) {
      this.selectChartOilType = this.oilTypeChartList.filter(item => item.key === e)[0];
      this.defaultChartReset();
    }
  }
}
