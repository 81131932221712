import {Component, OnInit} from '@angular/core';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {MatchmakingService} from '../matchmaking.service';
import {UcAuthService} from '../../core/auth';
import {DataTabListBaseComponent} from '../../system/oilFinance/oilFinanceListBase.component';
import {XlsxService} from '../../core/common/xlsx.service';
import {CryptoJSService} from '../../system/common/cryptoJS';
import { QueryModelPagination, ViewModelPagination, MessageModel } from 'src/app/system';
import {OrderModel, FrontModalOptModel, FrontCallbackResultType} from '../../system/oilFinance/finance.model';
import {TraderBuyOrderComponent} from './BuyOrder/traderBuyOrder.component';
import {TraderSellOrderComponent} from './traderSellOrder/traderSellOrder.component';
import {ApproveListComponent} from './approveList/approveList.component';
import {differenceInCalendarDays} from 'date-fns';
import {ApproveOrderComponent} from './approveList/approveOrder.component';

@Component({
  selector: 'app-finance-order-list',
  templateUrl: './orderList.html',
  styles: [`
    .head-example {
      padding-right: 20px;
    }

    .uc-list-selects-select-date {
      margin-top: 15px;
    }

    .uc-list-selects-select-date div {
      float: left;
      margin-right: 15px;
    }
  `]
})
export class OrderListComponent extends DataTabListBaseComponent implements OnInit {
  orderStatus = {
    1: '委托中',
    10: '部成',
    20: '已成',
    200: '废单',
    201: '部撤',
    203: '已撤'
  };

  orderStatusFilter1 = [
    {text: '委托中', value: 1},
    {text: '部成', value: 10}
  ];

  orderStatusFilter = [
    {text: '委托中', value: 1},
    {text: '部成', value: 10},
    {text: '已成', value: 20},
    {text: '废单', value: 200},
    {text: '部撤', value: 201},
    {text: '已撤', value: 203},
  ];

  approveStatus = {
    1: '待审核',
    5: '待结清',
    10: '已结算',
    200: '已取消',
    201: '已作废',
    203: '已撤单'
  };

  approveStatusFilter = [
    {text: '待审核', value: 1},
    {text: '待结清', value: 5},
    {text: '已结算', value: 10},
    {text: '已取消', value: 200},
    {text: '已作废', value: 201},
    {text: '已撤单', value: 203}
  ];

  operation = {
    1: '买入',
    2: '卖出'
  };

  dateType: boolean;
  startTime;
  startDate;
  endTime;
  endDate;

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date) > 0;
  }

  constructor(
    private matchmakingService: MatchmakingService,
    private xlsxService: XlsxService,
    authService: UcAuthService,
    crypto2: CryptoJSService,
    messageService: NzMessageService,
    private modalservice: NzModalService
  ) {
    super(authService, crypto2, messageService, modalservice);

    this.tabs = [
      {
        title: '委托中', type: 1
      },
      {
        title: '全部', type: 2
      }
    ];

    this.approvalCount = 0;
  }

  ngOnInit() {
    this.init();
    this.getTargetText = `委托订单`;
    this.searchKey = 'traderName';
    this.sortKey = 'orderTime';
    this.sortValue = false;

    this.dateType = true;
    const date = moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00';
    const endDate = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';
    this.startTime = +new Date(date.replace(/-/g, '/'));
    this.endTime = +new Date(endDate.replace(/-/g, '/'));
    this.startDate = new Date(this.startTime);
    this.endDate = new Date(this.endTime);
    this.defaultSearch();
  }

  getDataListService(body: QueryModelPagination): Observable<ViewModelPagination<OrderModel>> {
    body.filters = [
      ...body.filters,
      {key: 'timestamp', type: 'Ge', value: this.startTime},
      {key: 'timestamp', type: 'Le', value: this.endTime}
    ];
    return this.matchmakingService.getList(body);
  }

  singleUpdateService(targetId: string, body: OrderModel): Observable<MessageModel> {
    return this.matchmakingService.update(targetId, body);
  }

  singleApprovalService(targetId: string, body: OrderModel): Observable<MessageModel> {
    return this.matchmakingService.approval(targetId, body);
  }

  singleDeleteService(targetId: string): Observable<MessageModel> {
    return this.matchmakingService.delete(targetId);
  }

  getModalOpt(type: string, modalOpt: FrontModalOptModel): FrontModalOptModel {
    switch (type) {
      case 'Buy': {
        modalOpt.iconOpt.nzType = 'plus';
        modalOpt.titleText = '委托买入';
        modalOpt.content = TraderBuyOrderComponent;
        modalOpt.width = 500;
        modalOpt.onClose = (res) => {
          this.search();
        };
        break;
      }
      case 'Sell': {
        modalOpt.iconOpt.nzType = 'plus';
        modalOpt.titleText = '委托卖出';
        modalOpt.content = TraderSellOrderComponent;
        modalOpt.width = 500;
        modalOpt.onClose = (res) => {
          this.search();
        };
        break;
      }
      case 'ApproveList': {
        modalOpt.iconOpt.nzType = 'table';
        modalOpt.titleText = '撮合列表';
        modalOpt.content = ApproveListComponent;
        modalOpt.width = 1000;
        modalOpt.onClose = (res) => {
          this.search();
        };
        break;
      }
      case 'ApproveOrder': {
        modalOpt.iconOpt.nzType = 'unordered-list';
        modalOpt.titleText = '撮合委托单';
        modalOpt.content = ApproveOrderComponent;
        modalOpt.width = 500;
        modalOpt.onClose = (res) => {
          this.search();
        };
        break;
      }
    }
    return modalOpt;
  }

  defaultSearch() {
    this.currentTab = 0;
    this.tabFiltersChange();
    this.searchData(true);
  }

  tabFiltersChange() {
    this.tabFitlers = [];
    this.currentTabType = this.tabs[this.currentTab]['type'];
    if (this.tabs[this.currentTab]['type'] === 1) {
      if (this.sysAdmin || this.rootAdmin) {
        this.tabFilterType = 'And';
        this.tabFitlers = [
          {key: 'status', type: 'Lt', value: 20}
        ];
      } else {
        this.tabFilterType = 'And';
        this.tabFitlers = [
          {key: 'status', type: 'Lt', value: 20},
          {key: 'traderId', type: 'Eq', value: this.currentUser && this.currentUser.id}
        ];
      }
    }
    if (this.tabs[this.currentTab]['type'] === 2) {
      if (this.sysAdmin || this.rootAdmin) {
        this.tabFilterType = 'And';
        this.tabFitlers = [
        ];
      } else {
        this.tabFilterType = 'And';
        this.tabFitlers = [
          {key: 'traderId', type: 'Eq', value: this.currentUser && this.currentUser.id},
        ];
      }
    }
  }

  getApprovalCount() {
  }

  rateToValue(r): number {
    let rate = 0;
    if (r) {
      rate = r * 100;
    }
    return rate;
  }

  cancelOrder(targetId: string, deleteConfirmMsg?: { title?: string, content?: string }): void {
    let resType;
    this.modalService.confirm({
      nzTitle: deleteConfirmMsg.title ? deleteConfirmMsg.title : `委托订单撤单`,
      nzContent: deleteConfirmMsg.content ? deleteConfirmMsg.content : null,
      nzOnOk: () => {
        this.matchmakingService.cancel(targetId).subscribe(res => {
          if (res.status === 200) {
            this.messageService.create('success', '已成功撤销委托');
            resType = FrontCallbackResultType.refresh;
          } else {
            this.messageService.create('warning', `委托撤销失败，请联系后台人员！`);
            resType = FrontCallbackResultType.error;
          }
          this.handleCallBack(resType);
        }, () => {
          this.messageService.create('warning', `委托撤销失败，请联系后台人员！`);
          resType = FrontCallbackResultType.error;
          this.handleCallBack(resType);
        });
      }
    });
  }

  /**
   * 时间类型
   */
  dateTypeChange(e) {
    if (e) {
      const startDate = moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00';
      const endDate = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';

      this.startTime = +new Date(startDate.replace(/-/g, '/'));
      this.endTime = +new Date(endDate.replace(/-/g, '/'));
      this.startDate = new Date(this.startTime);
      this.endDate = new Date(this.endTime);

      this.refresh();
    } else {
      const date = moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00';
      const endDate = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';
      this.startTime = +new Date(date.replace(/-/g, '/')) - 360 * 24 * 60 * 60 * 1000;
      this.endTime = +new Date(endDate.replace(/-/g, '/')) - 24 * 60 * 60 * 1000;
      this.startDate = new Date(this.startTime);
      this.endDate = new Date(this.endTime);

      this.refresh();
    }
  }


  onStartOk(e) {
    if (e) {
      const date = moment(new Date(e)).format('YYYY-MM-DD') + ' 00:00:00';
      this.startTime = +new Date(date.replace(/-/g, '/'));
      this.startDate = new Date(this.startTime);
      this.refresh();
    }
  }

  onEndOk(e) {
    if (e) {
      const date = moment(new Date(e)).format('YYYY-MM-DD') + ' 23:59:59';
      this.endTime = +new Date(date.replace(/-/g, '/'));
      this.endDate = new Date(this.endTime);
      this.refresh();
    }
  }
}
