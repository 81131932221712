import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UcCommonService} from '../common.service';
import {OilRiskOrderModel} from '../../oil/oil-sell.model';
import {QueryModelPagination, Filter} from '../../system.model';
import * as moment from 'moment';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-risk-order-common',
  templateUrl: './riskOrderCommon.html'
})
export class RiskOrderCommonComponent implements OnInit {
  @Input() info: Filter[];
  @Input() remainAmount: number;
  @Input() riskOrderIds: string[];
  @Output() submitChagne = new EventEmitter<any>();
  riskOrder: OilRiskOrderModel[];
  selectRisk: OilRiskOrderModel;
  closeAmount = 0;
  isEnabled;
  sellerType;

  validateForm: FormGroup;
  orderTypeStr = {
    1: '上游采购',
    2: '内部成交',
    3: '主体货权转移',
    4: '内部借卖',
    5: '内部借提',
    6: '内部换油',
    7: '内部借归还'
  };

  constructor(
    private commonService: UcCommonService,
    private fb: FormBuilder,
    private modalRef: NzModalRef
  ) {
  }

  ngOnInit() {
    this.sellerType = 0;
    this.validateForm = this.fb.group({
      id: [null, Validators.required],
      code: [null, Validators.required],
      companyId: [null, Validators.required],
      companyName: [null, Validators.required],
      sellerId: [null, Validators.required],
      sellerName: [null, Validators.required],
      oilType: [null, Validators.required],
      amount: [null, Validators.required],
      desc: []
    });
    this.getRiskOrderList();
    // this.validateForm.valueChanges.subscribe(data => {this.submitChagne.emit(data)});
  }

  getRiskOrderList(e?) {
    const body: QueryModelPagination = {
      filters: [
        {key: 'status', type: 'Eq', value: 0},
        {key: 'closeAmount', type: 'Gt', value: 0}
      ],
      sort: {key: 'companyOrderDate', ascending: true}
    };

    if (this.info) {
      body.filters = [
        ...body.filters,
        ...this.info
      ];
    } else {
      body.filters = [
        ...body.filters,
        {key: 'buyUserId', type: 'Eq', value: 'noneData'}
      ];
    }

    if (this.sellerType === 1) {
      const filter =  body.filters.filter(item => item.key !== 'sellerId' && item.key !== 'companyId');
      body.filters = [
        ...filter
      ];
      body.filtersType = 'Or';
      body.filters = [
        {
          type: 'And',
          filters: [
            ...body.filters,
            {key: 'sellerName', type: 'Like', value: e || ''},
          ]
        },
        {
          type: 'And',
          filters: [
            ...body.filters,
            {key: 'riskOrderBuyName', type: 'Like', value: e || ''},
          ]
        }
      ];

    } else if (this.sellerType === 3) {
      const filter =  body.filters.filter(item => item.key !== 'buyUserId' && item.key !== 'companyId');
      body.filters = [
        ...filter
      ];

      body.filtersType = 'Or';
      body.filters = [
        {
          type: 'And',
          filters: [
            ...filter,
            {key: 'buyName', type: 'Like', value: e || ''},
          ]
        },
        {
          type: 'And',
          filters: [
            ...filter,
            {key: 'riskOrderBuyName', type: 'Like', value: e || ''},
          ]
        }
      ];
    } else if (this.sellerType === 4) {
      if (this.sellerType === 4) {
        const filter =  body.filters.filter(item => item.key !== 'sellerId' && item.key !== 'buyUserId' && item.key !== 'companyId');

        body.filtersType = 'Or';
        body.filters = [
          {
            type: 'And',
            filters: [
              ...filter,
              {key: 'sellerName', type: 'Like', value: e || ''},
            ]
          },
          {
            type: 'And',
            filters: [
              ...filter,
              {key: 'buyName', type: 'Like', value: e || ''},
            ]
          },
          {
            type: 'And',
            filters: [
              ...filter,
              {key: 'riskOrderBuyName', type: 'Like', value: e || ''},
            ]
          }
        ];
      }
    } else if (this.sellerType === 0) {
      body.filtersType = 'And';
      body.filters = [
        ...body.filters,
        {key: 'riskOrderBuyName', type: 'Like', value: e || ''},
      ];
    }

    this.commonService.getAgentRiskOrderListAll(body).subscribe(res => {
      if (res.status === 200) {
        this.riskOrder = res.data || [];
      }
    });
  }

  riskOrderSearch(e) {
    this.getRiskOrderList(e);
  }

  sellerTypeChange(e) {
    this.getRiskOrderList();
  }

  riskOrderChange(e) {
    if (e) {
      this.selectRisk = this.riskOrder.filter(item => item.id === e)[0];

      this.closeAmount = this.selectRisk && this.selectRisk.closeAmount || 0;
      this.validateForm.get('code').setValue(this.selectRisk && this.selectRisk.code || null);
      this.validateForm.get('companyId').setValue(this.selectRisk && this.selectRisk.companyId || null);
      this.validateForm.get('companyName').setValue(this.selectRisk && this.selectRisk.companyName || null);
      this.validateForm.get('sellerId').setValue(this.selectRisk && this.selectRisk.sellerId || null);
      this.validateForm.get('sellerName').setValue(this.selectRisk && this.selectRisk.sellerName || null);
      this.validateForm.get('oilType').setValue(this.selectRisk && this.selectRisk.oilType || null);

      this.getIsEnabled(e);
    }
  }

  // 采购天数
  getBuyDays(date) {
    if (date) {
      const startTimestamp = +new Date(date);
      const buyTimestamp = +new Date() - startTimestamp;
      const days = Math.ceil(buyTimestamp / (24 * 60 * 60 * 1000));
      return days || 0;
    } else {
      return 0;
    }
  }

  getDate(date) {
    return moment(new Date(date)).format('yyyy/MM/DD');
  }

  submitForm() {
    if (this.validateForm.valid) {
      const formData = this.validateForm.value;
      // this.submitChagne.emit(formData);

      this.modalRef.destroy(formData);
    }
  }

  getIsEnabled(id) {
    if (id) {
      this.isEnabled = this.riskOrderIds.includes(id);
      return  this.isEnabled ? '已关联' : '未关联';
    } else {
      return '未关联';
    }
  }

  getOrderTypeStr(type) {
    if (type) {
      return this.orderTypeStr[type];
    } else {
      return '无';
    }
  }
}
