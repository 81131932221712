<div class="uc-container">
  <div class="uc-sub-header">
    <div nz-row nzGutter="16" nzType="flex" nzJustify="space-between" nzAlign="middle">
      <div nz-col nzSpan="12"><!--左侧功能按钮-->
        <div class="uc-btn-group">
        </div>
      </div>
      <div nz-col nzSpan="12"><!--右侧搜索框-->
        <div nz-row nzGutter="5" nzType="flex" nzJustify="end" nzAlign="middle">
          <div nz-col>
            <button nz-button nzType="primary" (click)="refresh()" class="uc-btn uc-btn-icon uc-btn-main-create"
                    style="width:32px">
              <i nz-icon nzType="reload" nzTheme="outline"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24">
      <nz-tabset class="uc-tabset" (nzSelectChange)="selectTab($event)" [nzType]="'card'" [(nzSelectedIndex)]="currentTab">
        <nz-tab [nzTitle]="titleTemplate" *ngFor="let tab of tabs">
          <ng-template #titleTemplate>
            <div>
              {{ tab.title }}
            </div>
          </ng-template>
          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-spin [nzSpinning]="isSpinning">
                <nz-table
                  #infoTable class="uc-table"
                  [nzData]="dataList"
                  nzVirtualScroll
                  nzSize="middle"
                  [nzLoading]="false"
                  nzShowSizeChanger
                  [nzFrontPagination]="false"
                  [nzTotal]="total"
                  [(nzPageIndex)]="pageIndex"
                  [(nzPageSize)]="pageSize"
                  (nzPageIndexChange)="searchData()"
                  (nzPageSizeChange)="searchData(true)"
                  (nzCurrentPageDataChange)="currentPageDataChange($event)">
                  <thead (nzSortChange)="sort($event)" nzSingleSort>
                  <tr>
                    <th nzWidth='150px'>交易用户</th>
                    <th nzWidth='100px'>金额</th>
                    <th nzWidth='100px'>类型</th>
                    <th nzWidth='100px'>备注</th>
                    <th nzWidth='150px'>时间</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let data of infoTable.data;index as index">
                    <td>{{data.traderName}}</td>
                    <td>{{data.amount || 0 | number: '0.0-4'}}</td>
                    <td>
                      <nz-tag [nzColor]="'#05c28c'" *ngIf="data.type === 1">入金</nz-tag>
                      <nz-tag [nzColor]="'#e3493f'" *ngIf="data.type === 2">出金</nz-tag>
                    </td>
                    <td>{{data.desc}}</td>
                    <td>{{data.createTime | date: 'yyyy-MM-dd HH:mm'}}</td>
                  </tr>
                  </tbody>
                </nz-table>
              </nz-spin>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>
<ng-template #modalHeader>
  <div class="uc-modal-title-wrapper">
    <span class="uc-form-header">
      <i nz-icon [nzType]='modalIconOpt.nzType' [nzTheme]='modalIconOpt.nzTheme'
         [nzSpin]="modalIconOpt.spin" [nzIconfont]="modalIconOpt.iconfont"></i>&nbsp;&nbsp;{{modalTitleText}}
    </span>
  </div>
</ng-template>
