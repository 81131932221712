import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {LocationStrategy, PathLocationStrategy, APP_BASE_HREF, CommonModule} from '@angular/common';
import {NgZorroAntdModule, NZ_I18N, zh_CN} from 'ng-zorro-antd';
import {registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/en';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {UcThemeModule} from './core/theme';
import {SystemModule} from './system/system.module';

import {SelectivePreloadingStrategy} from './core/common';
import {UcHttpModule} from './core/http';
import {UcAuthModule} from './core/auth';
import {UcCacheModule} from './core/cache';
import {UcStateModule} from './core/state';
import {UcEnvironmentService} from './core/common';
import {UcAccountModule} from './account';

import {TradingDayService} from './core/common/tradingDay.service';
import {CurrentCompanyService} from './core/common/currentCompany.service';
import {MatchmakingModule} from './matchmaking/matchmaking.module';
import { CryptoJSService } from './system/common/cryptoJS';

// Angular国际化
registerLocaleData(zh);

const UC_MODULES = [
  UcHttpModule,
  UcCacheModule,
  UcStateModule,
  UcThemeModule,
  UcAuthModule,
  UcAccountModule,
  SystemModule,
  AppRoutingModule,
  MatchmakingModule
];

const UC_PROVIDERS = [
  {provide: APP_BASE_HREF, useValue: environment.baseUrl || '/'},
  {provide: LocationStrategy, useClass: PathLocationStrategy},
  SelectivePreloadingStrategy,
  {provide: NZ_I18N, useValue: zh_CN},
  UcEnvironmentService,
  TradingDayService,
  CurrentCompanyService,
  CryptoJSService
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ...UC_MODULES
  ],
  providers: [...UC_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule {
}
