<nz-spin [nzSpinning]="isSubmitting">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">交易用户</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <nz-form-text>{{currentUser?.realname}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" [nzRequired]="true" nzFor="mobile">手机号</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入手机号" >
        <input nz-input formControlName="mobile" id="mobile" placeholder="手机号" type="text" class="uc-input"  style="width: 200px;">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="traderId">用户持仓</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请选用户持仓">
        <nz-select style="width: 100%;" formControlName="positionId" nzMode="default" nzPlaceHolder="请选择用户持仓"
                   (ngModelChange)="positionChange($event)">
          <ng-container *ngFor="let p of positionList">
            <nz-option [nzValue]="p.id" [nzLabel]="p.oilType + '-可用持仓：' + p.available + '-持仓总量：' + p.volume + p.companyName"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="positionId">
      <nz-form-label [nzSm]="6" [nzXs]="24">片区</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text>{{selectPosition.companyName}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="6" [nzXs]="24">销售商</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text>{{selectPosition.sellerCompanyName}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="positionId">
      <nz-form-label [nzSm]="6" [nzXs]="24">油库</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text>{{selectPosition.storageName}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="6" [nzXs]="24">油品</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text>{{selectPosition.oilType}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="positionId">
      <nz-form-label [nzSm]="6" [nzXs]="24">持仓总量</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text>{{selectPosition?.volume || 0 | number}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="6" [nzXs]="24">可用持仓</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text style="color: red; font-weight: bold;">{{selectPosition?.available || 0 | number}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="volume">委托数量</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入委托数量">
        <nz-input-number formControlName="volume" [nzMin]="0" [nzStep]="10" style="width: 150px"
                         [nzDisabled]="!selectPosition" (ngModelChange)="volumeChange($event)"></nz-input-number>
        <div style="color: red;">
          <ng-container *ngIf="validateForm.get('volume').value > available">
            <ng-container>
              *出售数量不能多于当前可用持仓
            </ng-container>
          </ng-container>
          <ng-container *ngIf="validateForm.get('volume').value <= available && validateForm.get('volume').value % 10 !== 0">
            <ng-container>
              *委托数量只能为10或10的整数倍数量
            </ng-container>
          </ng-container>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="price">委托价格</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入委托价格">
        <nz-input-number formControlName="price" [nzDisabled]="!selectPosition" [nzMin]="0"  [nzStep]="10" style="width: 150px"></nz-input-number> <span> (元/吨)</span>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item >
      <nz-form-label [nzSm]="6" [nzXs]="24">操作方向</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <nz-form-text>委托卖出</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="min">最小成交</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入最小成交">
        <nz-input-number formControlName="min" [nzDisabled]="!selectPosition" [nzMin]="10"  [nzStep]="10" style="width: 150px"></nz-input-number>
        <div style="color: red;">
          <ng-container *ngIf="validateForm.get('min').value % 10 !== 0">
            <ng-container>
              *最小成交量只能为10或10的整数倍数量
            </ng-container>
          </ng-container>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="max">最大成交</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入最大成交">
        <nz-input-number formControlName="max"[nzDisabled]="!selectPosition"  [nzMin]="10"  [nzStep]="10" style="width: 150px"></nz-input-number>
        <div style="color: red;">
          <ng-container *ngIf="validateForm.get('max').value % 10 !== 0">
            <ng-container>
              *最大成交量只能为10或10的整数倍数量
            </ng-container>
          </ng-container>
          <ng-container *ngIf="validateForm.get('max').value % 10 === 0 && validateForm.get('max').value < validateForm.get('min').value">
            <ng-container>
              *最大成交量 小于 最小成交量
            </ng-container>
          </ng-container>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row style="margin-bottom:8px;">
      <nz-form-control [nzSm]="{ span:14, offset:6 }" [nzXs]="{ span:14, offset:1 }">
        <button nz-button class="uc-btn uc-btn-main-create" nzType="primary" type="submit"
                [disabled]="!validateForm.valid || validateForm.get('volume').value > available ||
                (validateForm.get('volume').value <= available && validateForm.get('volume').value % 10 !== 0) ||
                validateForm.get('min').value % 10 !== 0 || validateForm.get('max').value % 10 !== 0 ||
                validateForm.get('max').value < validateForm.get('min').value">确定
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
