<form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">

  <div *ngIf="riskOrder">
    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24">公司主体</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{riskOrder && riskOrder.companyName || '-'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">销售商</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{riskOrder && riskOrder.sellerName || '-'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">初始采购销售</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{riskOrder && riskOrder.riskOrderBuyName || '-'}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24">油品</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text style="font-weight: bold;color: red;">{{riskOrder && riskOrder.oilType || '-'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">风控单类型</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{orderTypeStr[riskOrder.orderType] || '-'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">当前销售(库存)</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text style="font-weight: bold;color: red;">{{riskOrder && riskOrder.buyName || '-'}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24">风控天数</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text style="font-weight: bold;">{{getBuyDays(riskOrder && riskOrder.companyOrderDate) || 0 | number}} 天</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">采购日期</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{riskOrder && riskOrder.companyOrderDate | date: 'yyyy-MM-dd'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">数量</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{riskOrder && riskOrder.amount || 0 | number: '0.1-4'}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24">可修改未提数量</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text style="font-weight: bold;color: red;">{{closeAmount || 0 | number: '0.1-4'}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
  </div>
  <nz-divider></nz-divider>
  <nz-form-item>
    <nz-form-label [nzSm]="4" [nzXs]="24">可关联数量</nz-form-label>
    <nz-form-control [nzSm]="6" [nzXs]="24">
      <nz-form-text style="font-weight: bold;color: #ff0000;">{{newRemainAmount || 0 | number: '0.1-4' }}</nz-form-text>
    </nz-form-control>
    <nz-form-label [nzSm]="4" [nzXs]="24">当前关联数量</nz-form-label>
    <nz-form-control [nzSm]="6" [nzXs]="24">
      <nz-form-text style="font-weight: bold;color: #1EB2F6;">{{info?.amount || 0 | number: '0.1-4' }}</nz-form-text>
    </nz-form-control>
  </nz-form-item>
  <nz-divider></nz-divider>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="amount">数量</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入数量">
      <nz-input-number formControlName="amount" [nzStep]="100" style="width: 150px"></nz-input-number>
      <div>
        <nz-form-text>{{validateForm.get('amount').value || 0 | number: '0.1-4'}}</nz-form-text>
      </div>
      <div style="color: red;">
        <div>
          <ng-container *ngIf="validateForm.get('amount').value > newRemainAmount">
            <ng-container>
              *1、关联不能多于可关联数量
            </ng-container>
          </ng-container>
        </div>
        <div>
          <ng-container *ngIf="validateForm.get('amount').value > closeAmount">
            <ng-container>
              *2、关联不能多于当前风控可修改未提数量
            </ng-container>
          </ng-container>
        </div>
      </div>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24">备注</nz-form-label>
    <nz-form-control [nzSm]="18" [nzXs]="24">
      <textarea row="4" nz-input formControlName="desc" class="uc-input" [nzAutosize]="{ minRows: 2, maxRows: 8 }"></textarea>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item nz-row style="margin-bottom:8px;">
    <nz-form-control [nzSm]="{ span:14, offset:6 }" [nzXs]="{ span:14, offset:1 }">
      <button nz-button class="uc-btn uc-btn-main-create" nzType="primary" type="submit" [disabled]="!validateForm.valid || validateForm.get('amount').value > newRemainAmount || validateForm.get('amount').value > closeAmount">确定</button>
    </nz-form-control>
  </nz-form-item>
</form>
