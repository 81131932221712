<div class="uc-container">
  <div class="uc-sub-header">
    <div nz-row nzGutter="16" nzType="flex" nzJustify="space-between" nzAlign="middle">
      <div nz-col nzSpan="12"><!--左侧功能按钮-->
        <div class="uc-btn-group">
          <button nz-button nzType="primary" class="uc-btn uc-btn-main-success" (click)="createComponentModal('Buy')">
            委托买入
          </button>
          <button nz-button nzType="primary" class="uc-btn uc-btn-main-create" (click)="createComponentModal('Sell')">
            委托卖出
          </button>
        </div>
      </div>
      <div nz-col nzSpan="12"><!--右侧搜索框-->
        <div nz-row nzGutter="5" nzType="flex" nzJustify="end" nzAlign="middle">
          <div nz-col *ngIf="sysAdmin">
            <nz-select [(ngModel)]="searchKey" class="uc-select-btn" nzPlaceHolder="选择字段">
              <nz-option [nzLabel]="'委托编号'" [nzValue]="'orderCode'"></nz-option>
              <nz-option [nzLabel]="'交易用户'" [nzValue]="'traderName'"></nz-option>
              <nz-option [nzLabel]="'手机号'" [nzValue]="'mobile'"></nz-option>
            </nz-select>
          </div>
          <div nz-col nzSpan="10" *ngIf="sysAdmin">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixSearchButton">
              <input type="text" nz-input placeholder="搜索" class="uc-input"
                     [(ngModel)]="searchValue" (keyup.enter)="search()">
            </nz-input-group>
            <ng-template #suffixSearchButton>
              <button nz-button nzSearch nzType="primary" (click)="search()"
                      class="uc-btn uc-btn-main-create uc-btn-icon">
                <i nz-icon nzType="search" nzTheme="outline"></i></button>
            </ng-template>
          </div>
          <div nz-col>
            <button nz-button nzType="primary" (click)="refresh()" class="uc-btn uc-btn-icon uc-btn-main-create"
                    style="width:32px">
              <i nz-icon nzType="reload" nzTheme="outline"></i>
            </button>
          </div>
        </div>
      </div>
      <div nz-col nzSpan="24">
        <div class="uc-list-selects-select-date">
          <nz-radio-group [(ngModel)]="dateType" (ngModelChange)="dateTypeChange($event)">
            <label nz-radio [nzValue]="true">当日委托</label>
            <label nz-radio [nzValue]="false">历史委托</label>
          </nz-radio-group>
        </div>
      </div>
      <div nz-col nzSpan="24">
        <div *ngIf="!dateType" class="uc-list-selects-select-date">
          <div>
            <span>开始日期：</span>
            <nz-date-picker
              [ngModel]="startDate"
              nzFormat="yyyy-MM-dd"
              [nzAllowClear]="false"
              [nzShowToday]="true"
              [nzDisabledDate]="disabledDate"
              (ngModelChange)="onStartOk($event)">
            </nz-date-picker>
          </div>
          <div>
            <span>结束日期：</span>
            <nz-date-picker
              [ngModel]="endDate"
              nzFormat="yyyy-MM-dd"
              [nzAllowClear]="false"
              [nzShowToday]="true"
              [nzDisabledDate]="disabledDate"
              (ngModelChange)="onEndOk($event)">
            </nz-date-picker>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24">
      <nz-tabset class="uc-tabset" (nzSelectChange)="selectTab($event)" [nzType]="'card'" [(nzSelectedIndex)]="currentTab">
        <nz-tab [nzTitle]="titleTemplate" *ngFor="let tab of tabs">
          <ng-template #titleTemplate>
            <div>
              {{ tab.title }}
            </div>
          </ng-template>
          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-spin [nzSpinning]="isSpinning">
                <nz-table
                  #infoTable class="uc-table"
                  [nzData]="dataList"
                  nzVirtualScroll
                  nzSize="middle"
                  [nzScroll]="{x: '100vw'}"
                  [nzLoading]="false"
                  nzShowSizeChanger
                  [nzFrontPagination]="false"
                  [nzTotal]="total"
                  [(nzPageIndex)]="pageIndex"
                  [(nzPageSize)]="pageSize"
                  (nzPageIndexChange)="searchData()"
                  (nzPageSizeChange)="searchData(true)"
                  (nzCurrentPageDataChange)="currentPageDataChange($event)">
                  <thead (nzSortChange)="sort($event)" nzSingleSort>
                  <tr>
                    <th nzWidth='150px'>委托编号</th>
                    <th nzWidth="100px" [nzFilters]="currentTab === 0 ? orderStatusFilter1 : orderStatusFilter"
                        [nzFilterMultiple]="false" (nzFilterChange)="updateFilter('status', $event)">委托状态</th>
<!--                    <th nzWidth="100px" [nzFilters]="approveStatusFilter"-->
<!--                        [nzFilterMultiple]="false" (nzFilterChange)="updateFilter('approveStatus', $event)">审核状态</th>-->
                    <th nzWidth="100px">油品</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="volume">委托数量</th>
                    <th nzWidth="150px" nzShowSort nzSortKey="price">委托价格(元/吨)</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="remainingVolume">未成数量</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="dealVolume">成交量</th>
                    <th nzWidth="100px" [nzFilters]="[{text: '买入', value: 1},{text: '卖出', value: 2}]"
                        [nzFilterMultiple]="false" (nzFilterChange)="updateFilter('operation', $event)">操作类型</th>
                    <th nzWidth="120px" nzShowSort nzSortKey="orderTime">委托时间</th>
                    <th nzWidth="100px">保证金</th>
                    <th nzWidth="100px">委托用户</th>
                    <th nzWidth="140px">手机</th>
                    <th nzWidth="150px">片区</th>
                    <th nzWidth='150px'>销售商</th>
                    <th nzWidth='150px'>油库</th>
                    <th nzWidth="120px" *ngIf="currentTab === 1" nzShowSort nzSortKey="approveTime">审核时间</th>
                    <th nzWidth="150px" [nzRight]="true">操作</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let data of infoTable.data;index as index">
                    <td>
                      <nz-badge  [nzDot]="data.flag">{{data.orderCode}}</nz-badge>
                    </td>
                    <td>
                      <nz-tag [nzColor]="'green'" *ngIf="data.status === 1">{{orderStatus[data.status]}}</nz-tag>
                      <nz-tag [nzColor]="'orange'" *ngIf="data.status === 10">{{orderStatus[data.status]}}</nz-tag>
                      <nz-tag [nzColor]="'#2db7f5'" *ngIf="data.status === 20">{{orderStatus[data.status]}}</nz-tag>
                      <nz-tag *ngIf="data.status > 100">{{orderStatus[data.status]}}</nz-tag>
                    </td>
<!--                    <td>-->
<!--                      <nz-tag [nzColor]="'#f50'" *ngIf="data.approveStatus === 1">{{approveStatus[data.approveStatus]}}</nz-tag>-->
<!--                      <nz-tag [nzColor]="'#ffa500'" *ngIf="data.approveStatus === 5">{{approveStatus[data.approveStatus]}}</nz-tag>-->
<!--                      <nz-tag [nzColor]="'#87d068'" *ngIf="data.approveStatus === 10">{{approveStatus[data.approveStatus]}}</nz-tag>-->
<!--                      <nz-tag *ngIf="data.approveStatus > 100">{{approveStatus[data.approveStatus]}}</nz-tag>-->
<!--                    </td>-->
                    <td>{{data.oilType}}</td>
                    <td>{{data.volume || 0 | number}}</td>
                    <td>{{data.price || 0 | number}}</td>
                    <td>{{data.remainingVolume || 0 | number}}</td>
                    <td>{{data.dealVolume || 0 | number}}</td>
                    <td>
                      <nz-tag [nzColor]="'#05c28c'" *ngIf="data.operation === 1">{{operation[data.operation]}}</nz-tag>
                      <nz-tag [nzColor]="'#e3493f'" *ngIf="data.operation === 2">{{operation[data.operation]}}</nz-tag>
                    </td>
                    <td>{{data.orderTime | date: 'yyyy-MM-dd HH:mm'}}</td>
                    <td>{{data.bond || 0 | number}}</td>
                    <td>{{data.traderName}}</td>
                    <td>{{data.mobile}}</td>
                    <td>{{data.companyName}}</td>
                    <td>{{data.sellerCompanyName}}</td>
                    <td>{{data.storageName}}</td>
                    <td *ngIf="currentTab === 1">{{data.approveTime | date: 'yyyy-MM-dd HH:mm'}}</td>
                    <td [nzRight]="true">
                      <div class="uc-btn-group">
                        <button nz-button nzType="primary" class="uc-btn uc-btn-small uc-btn-sub-create" *ngIf="!data.flag && (data.status < 100 || data.status === 201)" (click)="createComponentModal('ApproveList', data)">撮合列表</button>
                        <button nz-button nzType="primary" class="uc-btn uc-btn-small uc-btn-sub-create" *ngIf="data.flag && data.approveStatus <= 10" (click)="createComponentModal('ApproveOrder', data)">撮合列表</button>
                        <button nz-button nzType="primary" class="uc-btn uc-btn-small uc-btn-sub-success" *ngIf="(data.status < 20 && !data.flag) || (data.status < 20 && data.approveStatus === 1)"
                                (click)="cancelOrder(data.id, {title: '委托单撤单',
                              content: '委托编号：' + data.orderCode + '<br />委托用户：' + data.traderName + '<br />委托片区：' + data.companyName +
                              '<br />委托品种：' + data.oilType + '<br />委托数量：' + data.volume + '<br />委托价格：' + data.price +
                              '<br />未成数量：' + data.remainingVolume + '<br />已成数量：' + data.dealVolume + '<br />操作方式：' + data.direction})">撤单
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </nz-table>
              </nz-spin>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>
<ng-template #modalHeader>
  <div class="uc-modal-title-wrapper">
    <span class="uc-form-header">
      <i nz-icon [nzType]='modalIconOpt.nzType' [nzTheme]='modalIconOpt.nzTheme'
         [nzSpin]="modalIconOpt.spin" [nzIconfont]="modalIconOpt.iconfont"></i>&nbsp;&nbsp;{{modalTitleText}}
    </span>
  </div>
</ng-template>
