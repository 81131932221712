import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UcEnvironmentService} from '../core/common/index';
import {UcAuthService, UcUser} from '../core/auth/auth.service';
import {UcHttpService} from '../core/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NzModalService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {TradingDayService} from '../core/common/tradingDay.service';
import {MenusListService} from '../system/menusList.service';
import {CryptoJSService} from '../system/common/cryptoJS';
import {Title} from '@angular/platform-browser';
import {FrontCallBackHandler, FrontCallbackResultType, FrontModalOptModel} from '../system/oilFinance/finance.model';
import {OrderListComponent} from './orderList/orderList.component';
import {DealOrderListComponent} from './dealOrderList/dealOrderList.component';
import {PositionComponent} from './position/position.component';
import {MatchmakingService} from './matchmaking.service';

@Component({
  selector: 'app-matchmaking',
  templateUrl: 'matchmaking.html',
  styleUrls: ['./matchmaking.scss']
})
export class MatchmakingComponent implements OnInit, AfterViewInit {
  closeModal;
  width = 200;
  collapsedWidth = 50;
  isCollapsed = false;
  menuItems: any[];
  baseUrl: string;
  logo: string;
  title: string;
  financeAdmin: boolean;
  sysAdmin: boolean;

  logout() {
    this.authService.logout();
  }

  get isAuthenticated(): Observable<boolean> {
    return this.authService.isAuthenticated();
  }

  get username(): Observable<string> {
    return this.isAuthenticated.pipe(map(value => value ? this.authService.username : ''));
  }

  get letter(): Observable<string> {
    return this.username.pipe(map(value => value ? value.slice(0, 1).toUpperCase() : ''));
  }

  get icon(): Observable<string> {
    return this.username.pipe(map(value => value ? '' : 'user'));
  }

  get isTradingDay(): Observable<boolean> {
    return this.tradingDayService.isTradingdaysed();
  }

  get currentUser(): UcUser {
    return this.authService.currentUser;
  }

  constructor(
    private matchmakingService: MatchmakingService,
    private modalService: NzModalService,
    private authService: UcAuthService,
    private router: Router,
    private tradingDayService: TradingDayService,
    private environmentService: UcEnvironmentService,
    private menusListService: MenusListService,
    private crypto: CryptoJSService,
    private titleService: Title
  ) {
    this.tradingDayService.resetDays();
    this.baseUrl = this.environmentService.environment.host;
    this.logo = this.baseUrl + this.environmentService.environment.baseUrl + 'assets/images/logo-black.png';
    this.title = this.environmentService.environment.footerTitle;
    this.financeAdmin = this.currentUser ? (this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_trader')) || this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_oil_fi_admin'))) : false;
    this.sysAdmin = this.currentUser ? (this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_sys_admin')) || this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_root_admin'))) : false;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.environmentService.environment.title2);
  }

  mmLogout() {
    this.authService.logout();
  }

  storageMobile() {
    this.modalService.warning({
      nzTitle: '联系客服！',
      nzContent: '客服电话1：15060158802 (林)' + `<br />` + '客服电话2：18758787575 (吴)' + `<br />` + '工作时间：9:00 - 21:00',
      nzOnOk: () => {
      }
    });
  }


  /**
   * modal创建
   */

  createModal(type, data?) {
    // 默认配置
    let modalOpt: FrontModalOptModel = {
      iconOpt: {nzType: null, nzTheme: 'outline'}, /* 默认设 nzTheme='outline' */
      titleText: null,
      width: 520, /* 默认值 520 */
      content: null,
      footer: null, /* 默认值 null */
      onClose: this.handleCallBack,
      maskClosable: false /* 默认值设为 false，点击蒙层不关闭 */
    };

    modalOpt = this.getModalOpt(type, modalOpt);

    const modal = this.modalService.create({
      nzTitle: `<div class="uc-modal-title-wrapper">
                        <span class="uc-form-header"><i nz-icon nzType="table" nzTheme="outline"></i> ${modalOpt.titleText}</span>
                      </div>`,
      nzContent: modalOpt.content,
      nzComponentParams: {
        info: data
      },
      nzMaskClosable: modalOpt.maskClosable,
      nzWrapClassName: 'uc-modal',
      nzWidth: modalOpt.width,
      nzFooter: modalOpt.footer
    });

    // 订阅该模态框的关闭事件
    if (modalOpt.onClose) {
      modal.afterClose.subscribe(modalOpt.onClose);
    }
  }

  getModalOpt(type: string, modalOpt: FrontModalOptModel): FrontModalOptModel {
    switch (type) {
      case 'OrderList': {
        modalOpt.titleText = '我的委托列表';
        modalOpt.content = OrderListComponent;
        modalOpt.width = 1200;
        modalOpt.onClose = () => {
          this.matchmakingService.closeObservable.next(1);
        };
        break;
      }
      case 'DealList': {
        modalOpt.titleText = '我的成交列表';
        modalOpt.content = DealOrderListComponent;
        modalOpt.width = 1200;
        modalOpt.onClose = () => {
          this.matchmakingService.closeObservable.next(1);
        };
        break;
      }
      case 'PositionList': {
        modalOpt.titleText = '我的持仓';
        modalOpt.content = PositionComponent;
        modalOpt.width = 1000;
        modalOpt.onClose = () => {
          this.matchmakingService.closeObservable.next(1);
        };
        break;
      }
    }
    return modalOpt;
  }


  /**
   * 全组件处理回调结果，注意闭包问题
   * @param resType 回调结果类型
   */
  handleCallBack: FrontCallBackHandler = (resType: FrontCallbackResultType): void => {
    switch (resType) {
      case FrontCallbackResultType.refresh: {
        break;
      }
      default : {
        // this.searchData();
        break;
      }
    }
  }
}
