import {NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import { MatchmakingRouting } from './matchmaking.routing';
import {MatchmakingComponent} from './matchmaking.component';
import {HomeComponent} from './home/home.component';
import {MatchmakingService} from './matchmaking.service';
import {SellOrderComponent} from './home/SellOrder/SellOrder.component';
import {BuyOrderComponent} from './home/BuyOrder/BuyOrder.component';
import {OrderListComponent} from './orderList/orderList.component';
import { XlsxService } from '../core/common/xlsx.service';
import {DealOrderListComponent} from './dealOrderList/dealOrderList.component';
import {TraderBuyOrderComponent} from './orderList/BuyOrder/traderBuyOrder.component';
import {TraderSellOrderComponent} from './orderList/traderSellOrder/traderSellOrder.component';
import {PositionComponent} from './position/position.component';
import {ApproveListComponent} from './orderList/approveList/approveList.component';
import zh from '@angular/common/locales/zh';
import {UcChartModule} from '../system/common/ucChart/ucChart.module';
import {ApproveOrderComponent} from './orderList/approveList/approveOrder.component';
import {CashListComponent} from './home/cashList/cashList.component';
registerLocaleData(zh);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    MatchmakingRouting,
    UcChartModule
  ],
  declarations: [
    MatchmakingComponent,
    HomeComponent,
    SellOrderComponent,
    BuyOrderComponent,
    OrderListComponent,
    DealOrderListComponent,
    TraderBuyOrderComponent,
    TraderSellOrderComponent,
    PositionComponent,
    ApproveListComponent,
    ApproveOrderComponent,
    CashListComponent
  ],
  entryComponents: [
    SellOrderComponent,
    BuyOrderComponent,
    OrderListComponent,
    DealOrderListComponent,
    TraderBuyOrderComponent,
    TraderSellOrderComponent,
    PositionComponent,
    ApproveListComponent,
    ApproveOrderComponent,
    CashListComponent
  ],
  providers: [
    MatchmakingService,
    XlsxService
  ]
})
export class MatchmakingModule {
}
