import {Injectable} from '@angular/core';
import Menu = Handsontable.plugins.Menu;
import {HttpClient} from '@angular/common/http';
import {UcEnvironmentService} from '../core/common';
import {QueryModelPagination, ViewModelPagination} from './system.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UcCacheService, UcStorageType} from '../core/cache';

export interface MenuItem {
  id?: string;
  path?: string;
  title?: string;
  breadcrumb?: string;
  icon?: string;
  inSider?: boolean;
  inHeader?: boolean;
  num?: number;
  deep?: number;
  roles?: string[];
  parentId?: string;
  enabled?: boolean;
  locked?: boolean;
  children?: MenuItem[];
  url?: string;
}

@Injectable()
export class MenusListService {
  _menuItems: MenuItem[];
  private storageType: UcStorageType;

  constructor(
    private http: HttpClient,
    private cacheService: UcCacheService,
    private env: UcEnvironmentService
  ) {
    this.storageType = UcStorageType.LocalStorage;
  }



  private getCache<T>(key: string): T {
    return this.cacheService.get<T>({
      pool: '_CONSOLE_MENU_ITEMS_',
      key: key,
      storageType: this.storageType
    });
  }

  private setCache(key: string, obj: Object) {
    this.cacheService.set({
      pool: '_CONSOLE_MENU_ITEMS_',
      key: key,
      storageType: this.storageType
    }, obj);
  }

  private removeAllCache() {
    this.cacheService.removeAll({
      pool: '_CONSOLE_MENU_ITEMS_',
      storageType: this.storageType
    });
  }

  set menuItems(_menuItems: MenuItem[]) {
    this._menuItems = _menuItems;
    this.setCache('MENU_ITEMS', _menuItems);
  }

  get menuItems(): MenuItem[] {
    if (this._menuItems) {
      return this._menuItems;
    }
    return this.getCache('MENU_ITEMS');
  }

  getMenusList(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.env.api.usercenter + '/menus/member/own_menus', body);
  }

  getParentMenuItem(): Observable<any> {
    return this.getMenusList({product: null}).pipe(map(res => {
      const menusList = res.data || [];
      let menusItems = [];
      const childMenuItems = [];
      menusList.map((menu, num) => {
        if (menu.deep === 0) {
          menu.children = [];
          menu.url = menu.path;
          menusItems  = [...menusItems, menu];

          menusItems = this.insertUnacecnding(menusItems, 'num');
        } else if (menu.deep === 1) {
          childMenuItems.push(menu);
        }
      });

      childMenuItems.map((menu, num) => {
        if (menu.deep === 1) {
          menusItems.forEach(item => {
            if (item.id === menu.parentId) {
              menu.url = item.path + '/' + menu.path;
              item.children = [
                ...item.children,
                menu
              ];
              item.children = this.insertUnacecnding(item.children, 'num');
            }
          });
        }
      });


      this.menuItems = menusItems;
      return menusItems;
    }));
  }

  insertUnacecnding(arr, sortKey) {
    const length = arr.length;
    for (let i = 1; i < length; i ++) {
      const temp = arr[i];
      let j = i;
      for (; j > 0; j--) {
        if (temp[sortKey] >= arr[j - 1][sortKey]) {
          break;
        }
        arr[j] = arr [j - 1];
      }
      arr[j] = temp;
    }
    return arr;
  }
}
