<div class="uc-container">
  <div class="uc-sub-header">
    <div nz-row nzGutter="16" nzType="flex" nzJustify="space-between" nzAlign="middle">
      <div nz-col nzSpan="12"><!--左侧功能按钮-->
        <div class="uc-btn-group">
          <div>
            <nz-radio-group [(ngModel)]="dateType" (ngModelChange)="dateTypeChange($event)">
              <label nz-radio [nzValue]="true">当日成交</label>
              <label nz-radio [nzValue]="false">历史成交</label>
            </nz-radio-group>
          </div>
        </div>
      </div>
      <div nz-col nzSpan="12"><!--右侧搜索框-->
        <div nz-row nzGutter="5" nzType="flex" nzJustify="end" nzAlign="middle">
          <div nz-col *ngIf="sysAdmin">
            <nz-select [(ngModel)]="searchKey" class="uc-select-btn" nzPlaceHolder="选择字段">
              <nz-option [nzLabel]="'成交编号'" [nzValue]="'orderCode'"></nz-option>
              <nz-option [nzLabel]="'成交用户'" [nzValue]="'traderName'"></nz-option>
              <nz-option [nzLabel]="'手机号'" [nzValue]="'mobile'"></nz-option>
            </nz-select>
          </div>
          <div nz-col nzSpan="10" *ngIf="sysAdmin">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixSearchButton">
              <input type="text" nz-input placeholder="搜索" class="uc-input"
                     [(ngModel)]="searchValue" (keyup.enter)="search()">
            </nz-input-group>
            <ng-template #suffixSearchButton>
              <button nz-button nzSearch nzType="primary" (click)="search()"
                      class="uc-btn uc-btn-main-create uc-btn-icon">
                <i nz-icon nzType="search" nzTheme="outline"></i></button>
            </ng-template>
          </div>
          <div nz-col>
            <button nz-button nzType="primary" (click)="refresh()" class="uc-btn uc-btn-icon uc-btn-main-create"
                    style="width:32px">
              <i nz-icon nzType="reload" nzTheme="outline"></i>
            </button>
          </div>
        </div>
      </div>
      <div nz-col nzSpan="24">
        <div *ngIf="!dateType" class="uc-list-selects-select-date">
          <div>
            <span>开始日期：</span>
            <nz-date-picker
              [ngModel]="startDate"
              nzFormat="yyyy-MM-dd"
              [nzAllowClear]="false"
              [nzShowToday]="true"
              [nzDisabledDate]="disabledDate"
              (ngModelChange)="onStartOk($event)">
            </nz-date-picker>
          </div>
          <div>
            <span>结束日期：</span>
            <nz-date-picker
              [ngModel]="endDate"
              nzFormat="yyyy-MM-dd"
              [nzAllowClear]="false"
              [nzShowToday]="true"
              [nzDisabledDate]="disabledDate"
              (ngModelChange)="onEndOk($event)">
            </nz-date-picker>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24">
      <nz-tabset class="uc-tabset" (nzSelectChange)="selectTab($event)" [nzType]="'card'" [(nzSelectedIndex)]="currentTab">
        <nz-tab [nzTitle]="titleTemplate" *ngFor="let tab of tabs">
          <ng-template #titleTemplate>
            <!--            <div *ngIf="tab.type === 1">-->
            <!--              <nz-badge [nzCount]="approvalCount" nzShowZero>-->
            <!--                <span class="head-example">{{ tab.title }}</span>-->
            <!--              </nz-badge>-->
            <!--            </div>-->
            <div>
              {{ tab.title }}
            </div>
          </ng-template>
          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-spin [nzSpinning]="isSpinning">
                <nz-table
                  #infoTable class="uc-table"
                  [nzData]="dataList"
                  nzVirtualScroll
                  nzSize="middle"
                  [nzScroll]="{x: '100vw'}"
                  [nzLoading]="false"
                  nzShowSizeChanger
                  [nzFrontPagination]="false"
                  [nzTotal]="total"
                  [(nzPageIndex)]="pageIndex"
                  [(nzPageSize)]="pageSize"
                  (nzPageIndexChange)="searchData()"
                  (nzPageSizeChange)="searchData(true)"
                  (nzCurrentPageDataChange)="currentPageDataChange($event)">
                  <thead (nzSortChange)="sort($event)" nzSingleSort>
                  <tr>
                    <th nzWidth='170px'>成交编号</th>
                    <th nzWidth="100px" [nzFilters]="orderStatusFilter"
                        [nzFilterMultiple]="false" (nzFilterChange)="updateFilter('status', $event)">状态</th>
                    <th nzWidth="100px" [nzFilters]="[{text: '买入', value: 1},{text: '卖出', value: 2}]"
                        [nzFilterMultiple]="false" (nzFilterChange)="updateFilter('operation', $event)">操作类型</th>
                    <th nzWidth="100px">油品</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="volume">成交数量</th>
                    <th nzWidth="150px" nzShowSort nzSortKey="price">成交价格(元/吨)</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="fee">手续费</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="bond">保证金</th>
                    <th nzWidth="150px" nzShowSort nzSortKey="dealTime">成交时间</th>
                    <th nzWidth="120px" nzShowSort nzSortKey="closeProfit">平仓盈亏</th>
                    <th nzWidth="100px">成交用户</th>
                    <th nzWidth="100px">手机</th>
                    <th nzWidth="150px">片区</th>
                    <th nzWidth='150px'>销售商</th>
                    <th nzWidth='150px'>油库</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let data of infoTable.data;index as index">
                    <td>{{data.dealCode}}</td>
                    <td>
                      <nz-tag [nzColor]="'green'" *ngIf="data.status === 1">{{orderStatus[data.status]}}</nz-tag>
                      <nz-tag [nzColor]="'orange'" *ngIf="data.status === 10">{{orderStatus[data.status]}}</nz-tag>
                      <nz-tag [nzColor]="'#2db7f5'" *ngIf="data.status === 20">{{orderStatus[data.status]}}</nz-tag>
                      <nz-tag *ngIf="data.status > 100">{{orderStatus[data.status]}}</nz-tag>
                    </td>
                    <td>
                      <nz-tag [nzColor]="'#05c28c'" *ngIf="data.operation === 1">{{operation[data.operation]}}</nz-tag>
                      <nz-tag [nzColor]="'#e3493f'" *ngIf="data.operation === 2">{{operation[data.operation]}}</nz-tag>
                    </td>
                    <td>{{data.oilType}}</td>
                    <td>{{data.volume || 0 | number}}</td>
                    <td>{{data.price || 0 | number}}</td>
                    <td>{{data.fee || 0 | number}}</td>
                    <td>{{data.bond || 0 | number}}</td>
                    <td>{{data.dealTime | date: 'yyyy-MM-dd HH:mm'}}</td>
                    <td>
                      <span *ngIf="data.operation === 1">-</span>
                      <span *ngIf="data.operation === 2">{{data.closeProfit || 0 | number}}</span>
                    </td>
                    <td>{{data.traderName}}</td>
                    <td>{{data.mobile}}</td>
                    <td>{{data.companyName}}</td>
                    <td>{{data.sellerCompanyName}}</td>
                    <td>{{data.storageName}}</td>
                  </tr>
                  </tbody>
                </nz-table>
              </nz-spin>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>
<ng-template #modalHeader>
  <div class="uc-modal-title-wrapper">
    <span class="uc-form-header">
      <i nz-icon [nzType]='modalIconOpt.nzType' [nzTheme]='modalIconOpt.nzTheme'
         [nzSpin]="modalIconOpt.spin" [nzIconfont]="modalIconOpt.iconfont"></i>&nbsp;&nbsp;{{modalTitleText}}
    </span>
  </div>
</ng-template>
