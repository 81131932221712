import {Component, Input, OnInit} from '@angular/core';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {MatchmakingService} from '../../matchmaking.service';
import {UcAuthService} from '../../../core/auth';
import {DataTabListBaseComponent} from '../../../system/oilFinance/oilFinanceListBase.component';
import {XlsxService} from '../../../core/common/xlsx.service';
import {CryptoJSService} from '../../../system/common/cryptoJS';
import { QueryModelPagination, ViewModelPagination, MessageModel } from 'src/app/system';
import {OrderModel, FrontModalOptModel} from '../../../system/oilFinance/finance.model';

@Component({
  selector: 'app-finance-mm-approve-order',
  templateUrl: './approveOrder.html',
  styles: [`
    .head-example {
      padding-right: 20px;
    }
  `]
})
export class ApproveOrderComponent implements OnInit {
  @Input() info: OrderModel;
  orderStatus = {
    1: '委托中',
    10: '部成',
    20: '已成',
    200: '废单',
    201: '部撤',
    203: '已撤'
  };

  orderStatusFilter1 = [
    {text: '委托中', value: 1},
    {text: '部成', value: 10}
  ];

  orderStatusFilter = [
    {text: '委托中', value: 1},
    {text: '部成', value: 10},
    {text: '已成', value: 20},
    {text: '废单', value: 200},
    {text: '部撤', value: 201},
    {text: '已撤', value: 203},
  ];

  approveStatus = {
    1: '待审核',
    5: '待结清',
    10: '已结算',
    200: '已取消',
    201: '已作废',
    203: '已撤单'
  };

  approveStatusFilter = [
    {text: '待审核', value: 1},
    {text: '待结清', value: 5},
    {text: '已结算', value: 10},
    {text: '已取消', value: 200},
    {text: '已作废', value: 201},
    {text: '已撤单', value: 203}
  ];

  operation = {
    1: '买入',
    2: '卖出'
  };

  constructor(
    private matchmakingService: MatchmakingService,
    private messageService: NzMessageService,
    private modalservice: NzModalService
  ) {
  }

  ngOnInit() {
  }


  rateToValue(r): number {
    let rate = 0;
    if (r) {
      rate = r * 100;
    }
    return rate;
  }
}
