import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService, NzModalRef, NzTreeNode} from 'ng-zorro-antd';
import {BehaviorSubject, Observable, of} from 'rxjs';
import * as moment from 'moment';
import {MatchmakingService} from '../../matchmaking.service';
import { OrderModel } from 'src/app/system/oilFinance/finance.model';
import { QueryModelPagination } from 'src/app/system/system.model';
import {UcAuthService, UcUser} from '../../../core/auth';
import {CryptoJSService} from '../../../system/common/cryptoJS';

@Component({
  selector: 'app-oil-buy-order-add',
  templateUrl: './BuyOrder.html'
})
export class BuyOrderComponent implements OnInit, OnDestroy {
  @Input() info: OrderModel;
  userList;
  selectUser;
  available;
  max;
  min;

  validateForm: FormGroup;

  isSubmitting: boolean;
  sysAdmin: boolean;
  currentUser: UcUser;

  constructor(
    private matchmakingService: MatchmakingService,
    private fb: FormBuilder,
    private messageService: NzMessageService,
    private modalRef: NzModalRef,
    private authService: UcAuthService,
    private crypto: CryptoJSService
  ) {
    this.currentUser = this.authService.currentUser;
    this.sysAdmin = this.currentUser ? (this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_sys_admin')) || this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_root_admin'))) : false;
  }

  ngOnInit() {
    this.available = this.info && this.info.remainingVolume || 0;
    this.max = this.info && this.info.max || 0;
    this.min = this.info && this.info.min || 0;

    this.validateForm = this.fb.group({
      orderCode: [],
      traderId: [null, [Validators.required]],
      traderName: [null],
      traderUsername: [null],
      mobile: [null],
      flag: [true],
      oilTypeCode: [this.info && this.info.oilTypeCode || null, [Validators.required]],
      oilType: [this.info && this.info.oilType || null],
      companyName: [this.info && this.info.companyName || null],
      companyId: [this.info && this.info.companyId || null],
      sellerCompanyId: [this.info && this.info.sellerCompanyId || null],
      sellerCompanyName: [this.info && this.info.sellerCompanyName || null],
      storageId: [this.info && this.info.storageId || null],
      storageName: [this.info && this.info.storageName || null],
      orderDate: [null],
      orderTime: [null],
      timestamp: [null],
      operation: [1, [Validators.required]],
      direction: ['BUY'],
      status: [1, [Validators.required]],
      approveStatus: [1, [Validators.required]],
      volume: [null, [Validators.required]],
      remainingVolume: [null],
      dealVolume: [0],
      price: [this.info && this.info.price || null],
      min: [this.info && this.info.min || 10],
      max: [this.info && this.info.max || this.info.volume],
      orgTraderUsername: [this.info && this.info.traderUsername || null],
      orgTraderId: [this.info && this.info.traderId || null],
      orgTraderName: [this.info && this.info.traderName || null],
      orgTraderUserMobile: [this.info && this.info.mobile || null],
      orgOrderId: [this.info && this.info.id || null],
      orgOrderCode: [this.info && this.info.orderCode || null],
    });


    if (this.sysAdmin) {
      this.onSearch('');
    } else {
      this.validateForm.get('traderId').setValue(this.currentUser.id);
      this.validateForm.get('traderUsername').setValue(this.currentUser.username);
      this.validateForm.get('traderName').setValue(this.currentUser.realname);
      this.validateForm.get('mobile').setValue(this.currentUser.mobile);
      this.onSearch('');
    }
  }

  ngOnDestroy() {
    this.modalRef.destroy();
  }

  generateInfoSubmitted(): OrderModel {
    const formObj = this.validateForm.value;
    const date = moment(new Date()).format('YYYYMMDDHHmmss');
    formObj.orderCode = 'WT' + date ;
    formObj.orderDate = moment(new Date()).format('YYYYMMDD');
    formObj.orderTime = new Date();
    formObj.timestamp = +new Date();
    formObj.remainingVolume = formObj.volume;
    return formObj;
  }

  getUserList(e) {
    const body: QueryModelPagination = {
      filtersType: 'Or',
      filters: [
        {
          type: 'And',
          filters: [
            {key: 'mobile', type: 'Like', value: e || ''}
          ]
        },
        {
          type: 'And',
          filters: [
            {key: 'name', type: 'Like', value: e || ''}
          ]
        }
      ]
    };

    if (!this.sysAdmin) {
      body.filtersType = 'And';
      body.filters = [
        {key: 'userId', type: 'Eq', value: this.currentUser.id}
      ];
    }

    this.matchmakingService.getTraderListAllByBody(body).subscribe(res => {
      if (res.status === 200) {
        this.userList = res.data || [];

        if (!this.sysAdmin) {
          this.selectUser = this.userList[0];
        }
      }
    });
  }

  onSearch(e) {
    this.getUserList(e);
  }

  userChange(e) {
    if (e) {
      this.selectUser = this.userList.filter(item => item.userId === e)[0];

      this.validateForm.get('traderUsername').setValue(this.selectUser.username);
      this.validateForm.get('traderName').setValue(this.selectUser.name);
      this.validateForm.get('mobile').setValue(this.selectUser.mobile);
    }
  }

  submitForm() {
    this.isSubmitting = true;
    const formObj = this.generateInfoSubmitted();
    this.matchmakingService.followAdd(formObj).subscribe(res => {
      if (res.status === 200) {
        this.messageService.success('委托下单成功，请等待审核确认！');
        this.modalRef.destroy(1);
      }
      this.isSubmitting = false;
    }, () => {
      this.messageService.error('委托下单失败！');
      this.isSubmitting = false;
    });
  }
}
