<nz-spin [nzSpinning]="isSubmitting">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <div>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24">购买用户</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text>{{info?.traderName}}</nz-form-text>
        </nz-form-control>
        <nz-form-label [nzSm]="6" [nzXs]="24">联系方式</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text>{{info?.mobile}}</nz-form-text>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24">片区</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text>{{info?.companyName}}</nz-form-text>
        </nz-form-control>
        <nz-form-label [nzSm]="6" [nzXs]="24">销售商</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text>{{info?.sellerCompanyName}}</nz-form-text>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24">油库</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text>{{info?.storageName}}</nz-form-text>
        </nz-form-control>
        <nz-form-label [nzSm]="6" [nzXs]="24">油品</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text>{{info?.oilType}}</nz-form-text>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24">委托量</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text style="font-weight: bold; color: red;">{{info?.remainingVolume || 0 | number: '0.0-4'}} 吨</nz-form-text>
        </nz-form-control>
        <nz-form-label [nzSm]="6" [nzXs]="24">价格</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text style="font-weight: bold; color: red;">{{info?.price || 0 | number: '0.0-4'}} 元/吨</nz-form-text>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24">最小成交量</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text style="font-weight: bold; color: #333;">{{info?.min || 0 | number}} 吨</nz-form-text>
        </nz-form-control>
        <nz-form-label [nzSm]="6" [nzXs]="24">最大成交量</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <nz-form-text style="font-weight: bold; color: #333;">{{info?.max || 0 | number}} 吨</nz-form-text>
        </nz-form-control>
      </nz-form-item>
    </div>
    <nz-form-item *ngIf="sysAdmin">
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="traderId">出售用户</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请选择出售用户">
        <nz-select style="width: 100%;" formControlName="traderId" nzMode="default" nzPlaceHolder="请搜索并选择出售用户"
                   (ngModelChange)="userChange($event)" nzShowSearch [nzServerSearch]="true" (nzOnSearch)="onSearch($event)">
          <ng-container *ngFor="let u of userList">
            <nz-option [nzValue]="u.userId" [nzLabel]="u.name + ' (' + u.mobile + ')'"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="!sysAdmin">
      <nz-form-label [nzSm]="6" [nzXs]="24">出售用户</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <nz-form-text>{{currentUser?.realname}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" [nzRequired]="true" nzFor="mobile">手机号</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入手机号" >
        <input nz-input formControlName="mobile" id="mobile" placeholder="手机号" type="text" class="uc-input"  style="width: 300px;">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="traderId">用户持仓</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请选用户持仓">
        <nz-select style="width: 100%;" formControlName="positionId" nzMode="default" nzPlaceHolder="请选择用户持仓"
                   (ngModelChange)="positionChange($event)">
          <ng-container *ngFor="let p of positionList">
            <nz-option [nzValue]="p.id" [nzLabel]="p.oilType + '-可用持仓：' + p.available + '-持仓总量：' + p.volume + p.companyName"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="positionId">
      <nz-form-label [nzSm]="6" [nzXs]="24">持仓总量</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text>{{selectPosition.volume || 0 | number}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="6" [nzXs]="24">可用持仓</nz-form-label>
      <nz-form-control [nzSm]="6" [nzXs]="24">
        <nz-form-text style="color: red; font-weight: bold;">{{selectPosition.available || 0 | number}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="volume">出售数量</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请输入出售数量">
        <nz-input-number formControlName="volume" [nzMin]="0" [nzStep]="10" [nzDisabled]="!selectPosition" style="width: 150px"></nz-input-number>
        <div style="color: red;" *ngIf="validateForm.get('volume').value">
          <ng-container *ngIf="validateForm.get('volume').value > available && validateForm.get('volume').value <= orderVolume">
            <ng-container>
              *您的可用持仓不足，请确认后下单！
            </ng-container>
          </ng-container>
          <ng-container *ngIf="validateForm.get('volume').value > orderVolume">
            <ng-container>
              *出售数量不能多于委托量！
            </ng-container>
          </ng-container>
          <ng-container *ngIf="validateForm.get('volume').value > max && validateForm.get('volume').value <= orderVolume && validateForm.get('volume').value <= available">
            <ng-container>
              *出售数量不能多于委托最大成交量！
            </ng-container>
          </ng-container>
          <ng-container *ngIf="validateForm.get('volume').value >= min && validateForm.get('volume').value <= max && validateForm.get('volume').value <= orderVolume && validateForm.get('volume').value <= available && validateForm.get('volume').value % 10 !== 0">
            <ng-container>
              *出售数量只能为10或10的整数倍数量
            </ng-container>
          </ng-container>
          <ng-container *ngIf="validateForm.get('volume').value < min">
            <ng-container>
              *出售数量不能少于委托最小成交量！
            </ng-container>
          </ng-container>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item >
      <nz-form-label [nzSm]="6" [nzXs]="24">操作方向</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <nz-form-text>委托卖出</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row style="margin-bottom:8px;">
      <nz-form-control [nzSm]="{ span:14, offset:6 }" [nzXs]="{ span:14, offset:1 }">
        <button nz-button class="uc-btn uc-btn-main-create" nzType="primary" type="submit"
                [disabled]="!validateForm.valid || validateForm.get('volume').value > available || validateForm.get('volume').value > max ||
                validateForm.get('volume').value < min || validateForm.get('volume').value > orderVolume || validateForm.get('volume').value % 10 !== 0">确定
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
