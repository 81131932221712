// ApprovalStatus Models

export interface TableStatus {
  allChecked?: boolean;
  indeterminate?: boolean;
  isLoading?: boolean;
  pageIndex?: number;
  pageSize?: number;
  totalItems?: number;
  data: any[];
  fields?: any[];
  reload?: boolean;
}

export interface TabStatus {
  title: string;
}

export interface TabTableStatus extends TabStatus, TableStatus {
}


// Response Models

export interface MessageModel {
  status: number;
  code?: number;
  message?: string;
  description?: string;
  data?: any;
}

export interface ViewModel<T> extends MessageModel {
  data: T;
}

export interface ViewModelList<T> extends MessageModel {
  data: T[];
}

export interface ViewModelPagination<T> extends ViewModelList<T> {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface Entity {
  id: string;
  enabled?: boolean;
  system?: boolean;
  createUser?: string;
  createTime?: number;
  updateUser?: string;
  updateTime?: number;
}

export interface SharedEntity {
  owner: string;
  global: boolean;
  shared: boolean;
  sharedScope: string;
  accessOrgPaths?: string;
  sharedTime?: string;
  sharedUser?: string;
  accessUserSet?: string[];
  accessRoleSet?: string[];
  accessOrgPathSet?: string[];
}

// Request Models
// Exists 是否存在：false-不存在null, true-存在
export enum FilterType {
  Eq,
  Gt,
  Ge,
  Lt,
  Le,
  Like,
  In,
  Or,
  And,
  Not,
  Ne,
  Exists
}

export interface Filter {
  key?: string;
  type?: string;
  value?: string | boolean | number | Filter | Filter[] | any;
  filters?: Filter[];
  otherValue?: any;
}


export interface Order {
  key: string;
  ascending: boolean;
}

export interface Field {
  key: string;
  alias?: string;
}


export interface Group {
  keys: string[];
  fields: Field[]; // 分组一定会定义字段
}

/**
 * 内部属性是可选的，先解析filter和order，如果为空则解析filters和orders
 * 先解析group，如果为空则解析fields, 否则fields取group内的fields
 * 先过滤和排序，最后分组
 */
export interface QueryModelList {
  filter?: Filter;
  sort?: Order;
  filters?: Filter[]; // 只支持and
  sorts?: Order[];
  filtersType?: string; // Or、And、Not  默认：And
  fields?: Field[];
  group?: Group;
  size?: number; // 取前size条数据
  [name: string]: any;
}


export interface QueryModelPagination extends QueryModelList {
  pageIndex?: number;
  pageSize?: number;
}
