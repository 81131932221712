// 图表信息
import {QueryModelPagination} from '../../system.model';

export interface ChartInfo {
  id?: string;
  enabled: boolean;
  global: boolean;
  shared: boolean;
  sharedScope?: string;
  sharedTime?: number;
  sharedUser?: string;
  owner?: string;
  directory?: boolean;
  path?: string;
  createUser?: string;
  createTime?: number;
  updateUser?: string;
  updateTime?: number;
  accessUsers?: string;
  accessRoles?: string;
  accessOrgPaths?: string;
  accessUserSet?: string[];
  accessRoleSet?: any[];
  accessOrgPathSet?: any[];
  name: string;
  template: ChartTemplate;
  context?: any;
  checked?: boolean;
}
export interface ChartTemplate {
  chart_config?: UcChartConfig; // 图表配置
  data_config?: UcDataConfig; // 数据配置
  chart_for?: ChartFor; // 图表类型
  createType?: string; // highcharts \ echarts  留一个echarts的解析接口
  dataTmp?: ChartDataTmp; // 临时配置 图表计算数据配置（不做保存） 静态？
  template?: TEMPLATE; //
}
// chartConfig
export interface UcChartConfig {
  chartOptions?: ChartOptions;
  tableOptions?: TableOptions;
  chartTmp?: ChartSetting;
}
export interface ChartOptions {
  chart?: { // 图表配置
    alignTicks?: boolean, // 刻度对齐
    animation?: boolean, // 图表更新的整体动画
    backgroundColor?: string, // 图表外部背景颜色
    borderColor?: string, // 图表边框颜色
    borderRadius?: number, // 图表边框圆角半径
    borderWidth?: number, // 图表边框宽度
    plotBackgroundColor?: string, // 绘图区域的背景颜色
    plotBackgroundImage?: string, // 绘图区域的背景图 imgURL
    plotBorderColor?: string, // 绘图区边框颜色
    plotBorderWidth?: number, // 绘图区边框宽度
    plotShadow?: boolean, // 是否给绘图区增加阴影效果
    ignoreHiddenSeries?: boolean, // 隐藏一个数据列，坐标轴将根据剩余的可见数据列进行缩放显示
    inverted?: boolean, // 是否反转坐标轴
    polar?: boolean, // 极地图
    options3d?: {
      enabled?: boolean,
      alpha?: number,
      beta?: number,
      depth?: number,
      cdepth?: number,
      viewDistance?: number,
      frame?: {
        bottom?: {
          size?: number,
          color?: string
        },
        side?: {
          size?: number,
          color?: string
        },
        back?: {
          size?: number,
          color?: string
        }
      }
    },
    panning?: boolean, // 是否开启平移
    shadow?: boolean, // 整个图表的阴影(包含 color，offsetX，offsetY，opacity 和 width 属性的对象形式
  };
  legend?: { // 图例
    enabled?: boolean,
    align?: string, // left right center
    floating?: boolean, // 图例容器是否可以浮动
    layout?: string, // horizontal / vertical
    reversed?: boolean,
    rtl?: boolean,
    shadow?: boolean,
    squareSymbol?: boolean,
    symbolPadding?: number, // 标志和文本之间的距离
    symbolWidth?: number, // 图例标志宽度
  };
  plotOptions?: { // 数据列配置
    series?: {
      connectNulls?: boolean, // 是否连接空值点
      pointInterval?: number, // 数据点间隔
      stacking?: string, // 堆叠
      showInLegend?: boolean,
      turboThreshold?: number, // 突破曲线最大1000个{}点
      depth?: number,
      tooltip?: any
    };
    pie?: {
      startAngle?: any,
      endAngle?: any
    }
  };
  tooltip?: {
    valueDecimals?: number
  };
  // highstock配置
  rangeSelector?: { // 范围选择器
    enabled?: boolean,
    buttons?: any[],
    buttonTheme?: {
      width?: number
    },
    selected?:  number,
    inputBoxBorderColor?: string, // 边框颜色
  };
  navigator?: { // 导航器
    enabled?: boolean
  };
  scrollbar?: { // 滚动条
    enabled?: boolean,
    height?: number,
    buttonArrorColor?: string
  };
  xAxis?: {
    id?: string,
    name?: string;
    dataType?: string;
    reversed?: boolean,
    categories?: any;
    title?: {
      text?: string
    },
    dateTimeLabelFormats?: any
  };
  yAxis?: [{
    id?: string,
    title?: {
      text?: string,
      style?: {
        color?: string
      }
    },
    endOnTick?: boolean,
    lineWidth?: number,
    lineColor?: string,
    labels?: {
      style?: {
        color?: string
      }
    },
    opposite?: boolean,
    reversed?: boolean,
    unit?: string,
    useHTML?: boolean,
    max?: number,
    min?: number
  }] | any;
  series?: any[];
}
export interface TableOptions {
  field?: {
    name?: string;
    fieldName?: string;
    fieldType?: string; // date|str|num|
    thStyle?: any;
    tdStyle?: any;
  }[]; // table field
  pageSize?: number; // 每页大小
  showSizeChanger?: boolean; // 是否可以改变pageSize
  border?: boolean;
  loading?: boolean;
  x?: number;
  y?: number;
  title?: string;
  footer?: string;
  size?: string; // small \ middle \ default
  hideOnSinglePage?: boolean; // 只有一页时是否显示分页器
  pagination?: boolean; // 是否分页
  fixHeader?: boolean; // 固定表头
  filterFields?: string[]; // 添加过滤按钮的field
  sortFields?: string[]; // 添加排序按钮的field
  tableNullField?: string; // 表格的过滤字段, 当ws传过来的该字段的值为判断值时，该数据对应的那条数据删除
  updateTableField?: string; // dynamic-update类型表格过滤相同字段数据，值相同时：只修改本条数据，值不同时：append新数据
  isNewDateNull?: boolean; // 相差最新时间10s是否显示数据
  dateField?: string; // 自定义表格的时间列字段
}
export interface ChartSetting {
  type?: string; // static dynamic-update  dynamic-addpoint
  chartUpdateTime?: number;
  chartUpdateTimeType?: string; // min hour day week
  restChart?: boolean; // 图表预览重绘判断
  tmp?: ChartTmp; // 图表配置
}
export interface ChartTmp {
  xAxisId?: string;
  // series?: any[];
  // yAxis?: any[];
  // xAxis?: any;
  compare?: string; // value、 percent 百分比？
  ismobile?: boolean;
  commonSublineTypes?: any; // 辅助线
  // useOld?: boolean;
  set: {
    useHighStocks?: boolean, // 使用stockChart or chart
    options3d?: any,
    inverted?: boolean, // x轴反转
    polar?: boolean, // 极地图
    lighten?: boolean, // 逐渐变亮
    subline?: any[], // 辅助线
    ignoreNull?: boolean, // 忽略空值  ignoreNull: boolean
    stacking?: string // 堆积方式
    flagTypes?: {
      flag?: {
        translate?: string,
        icon?: string,
        data?: any
      }
    };
    size: any;
  };
}
export interface ChartDataTmp {
  data_raw?: any;
  data_col?: any; // [{fieldCode:xx,data:[]}]
  data_row?: any; // [{fieldCode:xx1,fieldCode:xx2},{fieldCode:xx1,fieldCode:xx2}]
  data_dic?: any; // [{fieldName:xx, fieldCode: xxx,fieldType:xx}]
  data_dic_pie?: any; // [{fieldName:xx, fieldCode: xxx,fieldType:xx}]
  data_paint?: any; // {fieldCode1:[[x,y]],fieldCode2:[[x,y]]}
  data_init?: any; // {fieldCode1:[],fieldCode2:[]}
  data_table_fields?: any; // [{name: xx, fieldName: xx, fieldType: xx}]
  data_table?: any; // [{fieldName1:xx1,fieldName2:xx2},...]
  data_fields?: any;
}
export interface TEMPLATE {
  series?: any;
  xAxis?: any;
  yAxis?: any;
  compare?: any;
  ismobile?: boolean;
  set?: {
    redraw?: any;
    useHighStocks?: any;
    options3d?: any;
    chartFor?: any;
    inverted?: any;
    polar?: any;
    lighten?: any;
    flags?: any;
    subline?: any;
    connectNulls?: any;
    stacking?: any;
  };
}
// ChartFor
export interface ChartFor {
  id: string;
  title: string;
  type: string;
  template: string;
  sampleData: string;
  parseDataFun: string;
  useSampleData: boolean;
}
// dataConfig
export interface UcDataConfigField {
  fieldName?: string;
  fieldType?: string; // date|str|num|
  checked?: boolean;
}

export interface UcDataConfigSource {
  id: string; // 数据集id
  name: string; // 数据集名称
  type: string; // local|remote，分别表示本地数据集、远程数据集、最终生成数据集和持续更新的数据集
  url?: string; // 数据集地址 "/dataset/preview/" + id  post(url , {pageTotal: 100})
  body?: QueryModelPagination; // 数据大小
  count?: number;
  fields?: UcDataConfigField[]; // 数据集结构
  data?: Object[]; // 数据集预览数据，用于代码生成器初始化
  process?: UcSourceProcess[];
}

export interface  UcSourceProcess {
  type: string;
  scalarfunc?: UcProcessFuncScalar;
  ortherFunc?: string;
  enumfunc?: UcProcessFuncEnum;
}

export interface  UcProcessFuncScalar {
  colname: string;
  col: {value: string, type: string};
  dependent?: {value: any, type: string};
  symbol: string;
}

export interface UcProcessFuncEnum {
  colname: string;
  colValue: string;
  enumData: any;
}

export interface UcDataConfigGenerate {
  id: string; // 数据集id == generate
  type: string; // generate
  fields?: UcDataConfigField[]; // 数据集结构
  data?: Object[]; // 数据集预览数据，用于代码生成器初始化
}

export interface UcDataConfigUpdate {
  id: string; // 数据集id == update
  type: string; // update
  url?: string; // 数据集地址
  count?: number; // 数据集大小
  body?: QueryModelPagination; // 数据集大小
  fields?: UcDataConfigField[]; // 数据集结构
  data?: Object[]; // 数据集预览数据，用于代码生成器初始化
  protocol?: string; // http|https|ws|wss
  interval?: number; // 请求时间间隔，秒,
  process?: UcSourceProcess[];
}

export interface UcDataConfigAction {
  type?: string;
  mainField?: string;
  firstSource?: {
    id?: string;
    fields?: UcDataConfigField[];
    fieldsName?: string[];
  };
  secondSource?: {
    id?: string;
    fields?: UcDataConfigField[];
    fieldsName?: string[];
  };
}

export interface UcDataConfigProcess {
  target?: string[]; // 数据集id
  type?: string; // single|multi
  actions?: UcDataConfigAction; // 数据集操作
  scalarfunc?: UcProcessFuncScalar;
  ortherFunc?: string;
  enumfunc?: UcProcessFuncEnum;
}

export interface UcDataConfig {
  dataset_type: string; // dynamic|static，如果配置为static，则直接从dataset中读取，不再从异步源读取
  filtersSourcesId?: string[]; // source.id数组, 改配置建议只配置静态图表
  sources?: UcDataConfigSource[]; // 动态异步datasources
  generate_source?: UcDataConfigGenerate;
  update_source?: UcDataConfigUpdate;
  processes?: UcDataConfigProcess[]; // 数据集过程
}

export enum filter {
  Eq,
  Gt,
  Ge,
  Lt,
  Le,
  Ne,
  Like
}

export interface UcDataFormula {
  f_value: string;
  s_value: any;
  symbol: filter;
}
