<form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="sellerType">数据类型</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-radio-group [(ngModel)]="sellerType"  [ngModelOptions]="{standalone: true}" nzName="sellerType" (ngModelChange)="sellerTypeChange($event)">
        <label nz-radio [nzValue]="0">当前销售(当前上游)</label>
        <label nz-radio [nzValue]="1">当前销售(全部上游)</label>
        <label nz-radio [nzValue]="3">所有销售(当前上游)</label>
        <label nz-radio [nzValue]="4">所有风控</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="4" [nzXs]="24" [nzRequired]="true" nzFor="id">风控列表</nz-form-label>
    <nz-form-control [nzSm]="20" [nzXs]="24" nzErrorTip="选择风控列表" >
      <nz-select [nzPlaceHolder]="'输入 上游销售商/销售(库存)/采购销售 查询'" formControlName="id"  nzShowSearch [nzServerSearch]="true" (nzOnSearch)="riskOrderSearch($event)"
                 (ngModelChange)="riskOrderChange($event)">
        <nz-option *ngFor="let r of riskOrder" [nzValue]="r.id" [nzLabel]="r.sellerName + '[' + r.companyName + '](' + r.buyName + '-' + r.riskOrderBuyName + ')-未提:-' + r.closeAmount + '-日期:' + getDate(r.companyOrderDate) + '-类型:' + getOrderTypeStr(r.orderType) + '-状态:' + getIsEnabled(r.id)"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <div *ngIf="selectRisk">
    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24">公司主体</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{selectRisk && selectRisk.companyName || '-'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">销售商</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{selectRisk && selectRisk.sellerName || '-'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">初始采购销售</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{selectRisk && selectRisk.riskOrderBuyName || '-'}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24">油品</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text style="font-weight: bold;color: red;">{{selectRisk && selectRisk.oilType || '-'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">风控单类型</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{orderTypeStr[selectRisk.orderType] || '-'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">当前销售(库存)</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text style="font-weight: bold;color: red;">{{selectRisk && selectRisk.buyName || '-'}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24">风控天数</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text style="font-weight: bold;">{{getBuyDays(selectRisk && selectRisk.companyOrderDate) || 0 | number}} 天</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">数量</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{selectRisk && selectRisk.amount || 0 | number: '0.1-4'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">未提数量</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text style="font-weight: bold;color: red;">{{selectRisk && selectRisk.closeAmount || 0 | number: '0.1-4'}}</nz-form-text>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24">采购日期</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-form-text>{{selectRisk && selectRisk.companyOrderDate | date: 'yyyy-MM-dd'}}</nz-form-text>
      </nz-form-control>
      <nz-form-label [nzSm]="4" [nzXs]="24">状态</nz-form-label>
      <nz-form-control [nzSm]="4" [nzXs]="24">
        <nz-tag *ngIf="isEnabled" [nzColor]="'red'">已关联</nz-tag>
        <nz-tag *ngIf="!isEnabled" [nzColor]="'green'">未关联</nz-tag>
      </nz-form-control>
    </nz-form-item>
  </div>
  <nz-divider></nz-divider>
  <nz-form-item>
    <nz-form-label [nzSm]="4" [nzXs]="24">剩余未关联数量</nz-form-label>
    <nz-form-control [nzSm]="6" [nzXs]="24">
      <nz-form-text style="font-weight: bold;color: red;">{{remainAmount || 0 | number: '0.1-4' }}</nz-form-text>
    </nz-form-control>
  </nz-form-item>
  <nz-divider></nz-divider>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="amount">数量</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入数量">
      <nz-input-number formControlName="amount" [nzStep]="100" style="width: 150px"></nz-input-number>
      <div>
        <nz-form-text>{{validateForm.get('amount').value || 0 | number: '0.1-4'}}</nz-form-text>
      </div>
      <div style="color: red;">
        <div>
          <ng-container *ngIf="validateForm.get('amount').value > remainAmount">
            <ng-container>
              *1、关联不能多于当前未关联数量
            </ng-container>
          </ng-container>
        </div>
        <div>
          <ng-container *ngIf="validateForm.get('amount').value > closeAmount">
            <ng-container>
              *2、关联不能多于当前风控未提数量
            </ng-container>
          </ng-container>
        </div>
      </div>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24">备注</nz-form-label>
    <nz-form-control [nzSm]="18" [nzXs]="24">
      <textarea row="4" nz-input formControlName="desc" class="uc-input" [nzAutosize]="{ minRows: 2, maxRows: 8 }"></textarea>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item nz-row style="margin-bottom:8px;">
    <nz-form-control [nzSm]="{ span:14, offset:6 }" [nzXs]="{ span:14, offset:1 }">
      <button nz-button class="uc-btn uc-btn-main-create" nzType="primary" type="submit" [disabled]="!validateForm.valid || validateForm.get('amount').value > remainAmount || validateForm.get('amount').value > closeAmount">确定</button>
    </nz-form-control>
  </nz-form-item>
</form>
