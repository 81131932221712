import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import { UcEnvironmentService } from '../core/common';
import {QueryModelPagination, ViewModelPagination, MessageModel} from '../system/system.model';
@Injectable()
export class MatchmakingService {
  closeObservable = new ReplaySubject<any>(1);
  constructor(
    private http: HttpClient,
    private ucEnvironmentService: UcEnvironmentService
  ) {
  }

  /**
   * 委托单接口
   * @param body
   */
  getList(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/order/list', body);
  }

  getListAll(): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/order/list_all', null);
  }

  getListAllByBody(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/order/list_all', body);
  }

  add(body: any): Observable<MessageModel> {
    return this.http.post<MessageModel>(this.ucEnvironmentService.api.finance + '/order/add', body);
  }

  followAdd(body: any): Observable<MessageModel> {
    return this.http.post<MessageModel>(this.ucEnvironmentService.api.finance + '/order/follow_add', body);
  }

  update(id: string, info: any): Observable<MessageModel> {
    const body: any = {id: id, ...info};
    return this.http.post<MessageModel>(this.ucEnvironmentService.api.finance + '/order/update', body);
  }

  approval(id: string, info: any): Observable<MessageModel> {
    const body: any = {id: id, ...info};
    return this.http.post<MessageModel>(this.ucEnvironmentService.api.finance + '/order/approval', body);
  }

  cancel(id: string): Observable<MessageModel> {
    const body: any = {id: id};
    return this.http.post<MessageModel>(this.ucEnvironmentService.api.finance + '/order/cancel', body);
  }

  delete(id: string): Observable<MessageModel> {
    return this.http.post<MessageModel>(this.ucEnvironmentService.api.finance + '/order/delete/' + id, null);
  }



  /**
   * 获取片区信息 城市 库存
   * @param body
   */
  getCompanyList(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.oil + '/company/list_all', body);
  }
  getStorageList(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.oil + '/storage/list_all', body);
  }
  getCityList(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.oil + '/city/list_all', body);
  }

  /**
   * 交易用户接口
   */

  getTraderList(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/trader/list', body);
  }

  getTraderListAll(): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/trader/list_all', null);
  }

  getTraderListAllByBody(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/trader/list_all', body);
  }

  getCashList(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/discrepancy/gold/list', body);
  }

  getCashListAll(): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/discrepancy/gold/list_all', null);
  }

  getCashListAllByBody(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/discrepancy/gold/list_all', body);
  }

  /**
   * 交易用户持仓接口
   */

  getTraderPositionList(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/position/list', body);
  }

  getTraderPositionListAll(): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/position/list_all', null);
  }

  getTraderPositionListAllByBody(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/position/list_all', body);
  }

  /**
   * 成交订单接口
   */


  getDealList(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/deal/order/list', body);
  }

  getDealListAll(): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/deal/order/list_all', null);
  }

  getDealListAllByBody(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/deal/order/list_all', body);
  }

  // 获取销售商
  getSellerCompanyListAllByBody(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.ucEnvironmentService.api.finance + '/seller/company/list_all', body);
  }
}
