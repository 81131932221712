import {Routes, RouterModule} from '@angular/router';
import {SystemComponent} from './system.component';
import {NgModule} from '@angular/core';
import {UcAuthentication} from '../core/auth';

const routes: Routes = [
  {
    path: 'system',
    component: SystemComponent,
    children: [
      {path: '', redirectTo: 'index', pathMatch: 'full'},
      {
        path: 'index',
        loadChildren: () => import('./index/index.module').then(m => m.IndexModule)
      },
      {
        path: 'company_accounts_chart',
        loadChildren: () => import('./companyAccountChart/companyAccountChart.module').then(m => m.CompanyAccountChartModule)
      },
      {
        path: 'oil',
        loadChildren: () => import('./oil/oil.module').then(m => m.OilModule)
      },
      {
        path: 'company_accounts',
        loadChildren: () => import('./companyAccounts/companyAccounts.module').then(m => m.CompanyAccountsModule)
      },
      {
        path: 'oil_loan',
          loadChildren: () => import('./loan/loan.module').then(m => m.LoanModule)
      },
      {
        path: 'oil_strategy_charts',
        loadChildren: () => import('./oilStrategyCharts/oilStrategyCharts.module').then(m => m.OilStrategyChartsModule)
      },
      {
        path: 'oil_charts',
        loadChildren: () => import('./oilcharts/oilcharts.module').then(m => m.OilchartsModule)
      },
      {
        path: 'oil_finance',
        loadChildren: () => import('./oilFinance/oilFinance.module').then(m => m.OilFinanceModule)
      },
      {
        path: 'oil_accounts',
        loadChildren: () => import('./oilAccounts/accounts.module').then(m => m.AccountsModule)
      },
      {
        path: 'datasource',
        loadChildren: () => import('./datasource/datasource.module').then(m => m.DataSourceModule)
      },
      {
        path: 'visualization',
        loadChildren: () => import('./visualization/visualization.module').then(m => m.VisualizationModule)
      },
      {
        path: 'usercenter',
        loadChildren: () => import('./usercenter/usercenter.module').then(m => m.UserCenterModule)
      }
    ],
    canActivate: [UcAuthentication]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemRoutingModule {
}
