import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {NzMessageService} from 'ng-zorro-antd';


@Injectable()
export class XlsxService {
  constructor(
    private messageservice: NzMessageService
  ) {}

  exportList(json: any, fileName: string) {
    // 自动列宽
    const colNames = Object.keys(json[0]);
    const colWidths = [];
    const cols = [];
    for (let i = 0; i < json.length; i++) {
      let index = 0;
      Object.keys(json[i]).map(key => {
        if (colWidths[index] == null) { colWidths[index] = []; }
        switch (typeof json[i][key]) {
          case 'string':
            colWidths[index].push(this.getCellWidth(json[i][key]));
            break;
          case 'number':
            colWidths[index].push(this.getCellWidth(json[i][key]));
            break;
          case 'boolean':
            colWidths[index].push(this.getCellWidth(json[i][key]));
            break;
          case 'object':
            colWidths[index].push(0);
            break;
          case 'function':
            colWidths[index].push(0);
            break;
        }
        index++;
      });
    }

    colWidths.forEach((widths, index) => {
      // 计算列头宽度
      widths.push(this.getCellWidth(colNames[index]));
      // 设置最大值为宽度
      cols.push({wch: Math.max(...widths)});
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet['!ref'] = `A1:AI${json.length + 1}`;
    if (cols) {
      worksheet['!cols'] = cols;
    }

    const workbook: XLSX.WorkBook = { Sheets: {data: worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    // 这里类型如果不正确，下载出来的可能是类似xml文件的东西或者是类似二进制的东西等
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });

    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + '.xlsx');
    this.messageservice.success(fileName + '文件导出成功');
    // 如果写成.xlsx,可能不能打开下载的文件，这可能与Excel版本有关
  }

  exportAoaList(data: any, fileName: string, merge?: any, colWidths?: any) {
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    worksheet['!ref'] = `A1:AI${data.length}`;
    if (merge) {
      worksheet['!merges'] = merge;
    }
    if (colWidths) {
      worksheet['!cols'] = colWidths;
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    // 执行数据转换文件写入
    XLSX.writeFile(workbook, fileName + '_' + new Date().getTime() + '.xlsx', {
      bookType: 'xlsx',
      bookSST: true,
      type: 'array'
    });
  }

  exportJsonList(json: any, fileName: string, merge?: any) {
    // 自动列宽
    const colNames = Object.keys(json[0]);
    const colWidths = [];
    const cols = [];
    for (let i = 0; i < json.length; i++) {
      let index = 0;
      Object.keys(json[i]).map(key => {
        if (colWidths[index] == null) { colWidths[index] = []; }
        switch (typeof json[i][key]) {
          case 'string':
            colWidths[index].push(this.getCellWidth(json[i][key]));
            break;
          case 'number':
            colWidths[index].push(this.getCellWidth(json[i][key]));
            break;
          case 'boolean':
            colWidths[index].push(this.getCellWidth(json[i][key]));
            break;
          case 'object':
            colWidths[index].push(0);
            break;
          case 'function':
            colWidths[index].push(0);
            break;
        }
        index++;
      });
    }

    colWidths.forEach((widths, index) => {
      // 计算列头宽度
      widths.push(this.getCellWidth(colNames[index]));
      // 设置最大值为宽度
      cols.push({wch: Math.max(...widths)});
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet['!ref'] = `A1:AI${json.length + 1}`;
    if (merge) {
      worksheet['!merges'] = merge;
    }
    if (cols) {
      worksheet['!cols'] = cols;
    }

    const workbook: XLSX.WorkBook = { Sheets: {data: worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    // 这里类型如果不正确，下载出来的可能是类似xml文件的东西或者是类似二进制的东西等
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  getCellWidth(value) {
    if (value == null) {
      return 10;
    } else if (/.*[\u4e00-\u9fa5]+.*$/.test(value)) {
      // 中文长度
      const chineseLength = value.match(/[\u4e00-\u9fa5]/g).length;
      // 其他不是中文长度
      const otherLength = value.length - chineseLength;
      return chineseLength * 2.1 + otherLength * 1.1;
    } else {
      // return value.toString().legnth * 1.1;
      /*另一种方案*/
      value = value.toString();
      return value.replace(/[\u4e00-\u9fa5]/g, 'aa').length;
    }
  }

  // readAsBinary() {
  //   FileReader.prototype.readAsBinaryString = function (fileData) { // 解决ie11 大文件堆栈溢出的问题（for arrayBufferToString）
  //     let binary = '';
  //     const pt = this;
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       const bytes = new Uint8Array(reader.result);
  //       const length = bytes.byteLength;
  //       for (let i = 0; i < length; i++) {
  //         binary += String.fromCharCode(bytes[i]);
  //       }
  //       pt.content = binary;
  //       // console.log("binary length:" + binary.length);
  //       pt.onload(pt); // 页面内data取pt.content文件内容
  //     };
  //     reader.readAsArrayBuffer(fileData);
  //   };
  // }
}
