import {Injectable} from '@angular/core';
import {UcAuthService, UcUser} from '../auth';
import {UcCacheService, UcStorageType} from '../cache';
import {UcEnvironmentService} from './environment.service';
import {UcHttpService} from '../http';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CurrentCompanyService {
  private static COMPANY_POOL_KEY = '__companyCachePool__';
  private static COMPANY_KEY = 'currentCompany';

  private storageType: UcStorageType;
  private _currentCompany: any;

  currentUser: UcUser;
  orgId: string;


  constructor(private environmentService: UcEnvironmentService,
              private httpService: UcHttpService,
              private authService: UcAuthService,
              private cacheService: UcCacheService,
              private http: HttpClient) {
    this.storageType = UcStorageType.LocalStorage;
  }

  private getCache<T>(key: string): T {
    return this.cacheService.get<T>({
      pool: CurrentCompanyService.COMPANY_POOL_KEY,
      key: key,
      storageType: this.storageType
    });
  }

  private setCache(key: string, obj: Object) {
    this.cacheService.set({
      pool: CurrentCompanyService.COMPANY_POOL_KEY,
      key: key,
      storageType: this.storageType
    }, obj);
  }

  set currentCompany(company: any) {
    this._currentCompany = company;
    this.setCache(CurrentCompanyService.COMPANY_KEY, company);
  }

  get currentCompany(): any {
    if (this._currentCompany) {
      return this._currentCompany;
    }
    return this.getCache(CurrentCompanyService.COMPANY_KEY);
  }

  resetCompany() {
    this.currentUser = this.authService.currentUser;
    this.orgId = this.currentUser.mainOrgPath ? this.currentUser.mainOrgPath.split('/')[1] : '';
    this.getOrgCompany(this.orgId);
  }

  getOrgCompany(orgId: string) {
    if (orgId && orgId !== '') {
      this.companyGet(orgId).subscribe(res => {
        if (res.status === 200) {
          this.currentCompany = res.data[0];
        }
      });
    }
  }


  /**
   * 查询单个交易商的详细信息
   * @param id 被查询的交易商的 id
   */
  companyGet(id: string): Observable<ViewModel<any>> {
    const body = {
      filters: [
        {key: 'orgId', type: 'Eq', value: id}
      ]
    };
    return this.http.post<ViewModel<any>>(this.environmentService.api.oil + `/company/list`, body);
  }
}

export interface MessageModel {
  status: number;
  code?: number;
  message?: string;
  description?: string;
}

export interface ViewModel<T> extends MessageModel {
  data: T;
}
