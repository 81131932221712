import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Route, Router, Routes} from '@angular/router';
import {UcAuthService, UcUser} from '../core/auth';
import {Observable, Subject} from 'rxjs/index';
import {map, takeUntil} from 'rxjs/operators';
import {TradingDayService} from '../core/common/tradingDay.service';
import {cloneDeep, UcEnvironmentService} from '../core/common';
import {MenusListService} from './menusList.service';
import {CryptoJSService} from './common/cryptoJS';
import { NzModalService } from 'ng-zorro-antd';
import * as $ from 'jquery';
import * as moment from 'moment';
import {UcPreloaderService} from '../core/theme';
import {Title} from '@angular/platform-browser';

export interface MenuItem {
  url?: string;
  path: string;
  breadcrumb?: string;
  title?: string;
  icon?: string;
  roles?: string | string[];
  selected?: boolean;
  expanded?: boolean;
  inSider?: boolean; // 是否左侧菜单展示
  inHeader?: boolean; // 是否头部菜单展示
  children?: MenuItem[];
}

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styles: [`
    .sim-btn {
      line-height: 50px;
      float: right;
      margin-right: 20px;
      color: #fff;
      font-size: 15px;
    }
    .sim-theme {
      background-color: #003c74;
    }
    .sim-title {
      margin-right: 20px;
    }
    .sim-title>span {
      font-weight: bold;
    }
    .sim-btn-click{
      font-weight: bold;
      padding: 3px;
      border-radius: 2px;
      color: #fff;
      background: #5cb1ff;
    }
    :host ::ng-deep #uc-content-router .ant-tabs-bar {
      margin: 0 0 0 -1px;
    }
    :host ::ng-deep #uc-content-router .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
      padding: 0;
    }
    :host ::ng-deep #uc-content-router .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-tab-span {
      padding: 8px 16px;
      display: inline-block;
    }

    :host ::ng-deep #uc-content-router .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-tab-i {
      padding: 8px 8px 8px 0;
      display: inline-block;
    }
    :host ::ng-deep #uc-content-router .ant-tabs.ant-tabs-card .ant-tabs-card-bar a {
      color: rgba(0, 0, 0, 0.65);
    }
    :host ::ng-deep #uc-content-router .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active a {
      color: #1890ff;
    }
  `]
})

export class SystemComponent implements OnInit, AfterViewInit, OnDestroy {
  destory$ = new Subject();

  width = 200;
  collapsedWidth = 50;
  isCollapsed = false;
  menuItems: any[];
  baseUrl: string;
  openid: string;
  logo: string;
  title: string;
  year;

  ofiSysAdmin: boolean;

  routerTabs: any[] =  [];
  routerIndex = 0;

  logout() {
    this.authService.logout();
  }

  get isAuthenticated(): Observable<boolean> {
    return this.authService.isAuthenticated();
  }

  get username(): Observable<string> {
    return this.isAuthenticated.pipe(map(value => value ? this.authService.username : ''));
  }

  // get openid(): Observable<string> {
  //   return this.isAuthenticated.pipe(map(value => value ? this.authService.openid : ''));
  // }

  get letter(): Observable<string> {
    return this.username.pipe(map(value => value ? value.slice(0, 1).toUpperCase() : ''));
  }

  get icon(): Observable<string> {
    return this.username.pipe(map(value => value ? '' : 'user'));
  }

  get isTradingDay(): Observable<boolean> {
    return this.tradingDayService.isTradingdaysed();
  }

  get currentUser(): UcUser {
    return this.authService.currentUser;
  }

  constructor(
    private modalService: NzModalService,
    private authService: UcAuthService,
    private router: Router,
    private tradingDayService: TradingDayService,
    private environmentService: UcEnvironmentService,
    private menusListService: MenusListService,
    private crypto: CryptoJSService,
    private titleService: Title
    ) {
    this.tradingDayService.resetDays();
    this.baseUrl = this.environmentService.environment.host;
    this.logo = this.baseUrl + this.environmentService.environment.baseUrl + 'assets/images/logo-black.png';
    this.title = this.environmentService.environment.footerTitle;
    this.year = moment(new Date).format('yyyy');
    this.ofiSysAdmin = this.currentUser ? (this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_sys_admin')) ||
      this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_root_admin')) ||
      this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_trader')) ||
      this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_oil_fi_admin'))) : false;
  }

  ngOnInit() {
    this.isAuthenticated.pipe(takeUntil(this.destory$)).subscribe((value => {
      if (value) {
        // this.menuItems = this.getItemsFromArray(this.environmentService.environment.routes);
        // const fistItem = this.menuItems && this.menuItems.length > 0 ? this.menuItems[0] : null;
        // const url = fistItem && fistItem.children && fistItem.children.length > 0 ? fistItem.children[0].url : null;
        // if (!this.authService.lastPath || this.authService.lastPath === UcAuthService.SYSTEM_INDEX) {
        //   this.router.navigate([url]).then();
        // }

        this.menusListService.getParentMenuItem().subscribe(menusList => {
          this.menuItems = menusList;
        });
        // this.menuItems = this.menusListService.menuItems;
      }
    }));

    this.isTradingDay.subscribe((value) => {
      if (!value) {
        const year = new Date().getFullYear();
        this.tradingDayService.getIniterTradingdays(year);
      }
    });
  }

  ngAfterViewInit() {
    this.openid = this.authService.openid;
    this.titleService.setTitle(this.environmentService.environment.title);
  }


  ngOnDestroy() {
    this.destory$.next();
    this.destory$.complete();
  }

  private getItemsFromArray(routes: any[]): MenuItem[] {
    const routeList = cloneDeep(routes);
    const items: MenuItem[] = [];
    routeList.forEach((route) => {
      this.getItems(route, items, `/${route.path}`);
    });
    return items;
  }

  private getItems(route: any, items: MenuItem[], url: string = ''): MenuItem[] {
    const children: any[] = route.children;
    if (!children || children.length === 0) {
      return items;
    }

    for (const child of children) {

      let routeUrl = url;
      if (child.path) {
        routeUrl += `/${child.path}`;
      }

      if (!child.title) {
        continue;
      }

      const item: MenuItem = child as MenuItem;
      item.url = routeUrl;
      if (item.children && item.children.length > 0) {
        const childrenItems = [];
        item.children.forEach((c) => {
          c.url = [item.url, c.path].join('/');
        });

        item.children.filter((x) => this.authService.isAuthorized(x.roles).subscribe(value => {
          if (value) {
            childrenItems.push(x);
          }
        }));
        item.children = childrenItems;
      }

      if (items.filter((x) => x.url === item.url).length === 0) {
        this.authService.isAuthorized(item.roles).subscribe(value => {
          if (value) {
            items.push(item);
          }
        });
      }
    }
    return items;
  }


  storageMobile() {
    this.modalService.warning({
      nzTitle: '联系客服！',
      nzContent: '客服电话1：15060158802 (林)' + `<br />` + '客服电话2：18758787575 (吴)' + `<br />` + '工作时间：9:00 - 21:00',
      nzOnOk: () => {
      }
    });
  }

  routClick(e) {
    const router = this.routerTabs.filter(item => item.url === e.url)[0];
    if (router)  {
      this.routerTabs.forEach((item, num) => {
        if (item.url === router.url) {
          this.routerIndex = num;
          const url = '/system/' + item.url;
          this.router.navigate([url]).then();
        }
      });
    } else {
      this.routerTabs = [
        ...this.routerTabs,
        e
      ];
      this.routerIndex = this.routerTabs.length - 1;
    }

  }

  closeTab(e, index) {
    this.routerTabs =  this.routerTabs.filter(item => item.url !== e.url);
    if (index ===  this.routerIndex) {
      this.routerIndex = this.routerTabs.length - 1;
      const url = '/system/' + this.routerTabs[this.routerIndex].url;
      this.router.navigate([url]).then();
    }
  }
}

