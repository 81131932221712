import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SystemRoutingModule} from './system.routing';
import {SystemComponent} from './system.component';
import {CodemirrorComponent, CodemirrorModule} from '@ctrl/ngx-codemirror';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {ClipboardModule} from 'ngx-clipboard';
import {UcCommonModule} from './common/common.module';
import {MenusListService} from './menusList.service';
import {FormsModule} from '@angular/forms';
import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    CodemirrorModule,
    SystemRoutingModule,
    NgZorroAntdModule,
    ClipboardModule,
    UcCommonModule
  ],
  providers: [MenusListService],
  entryComponents: [CodemirrorComponent],
  declarations: [
    SystemComponent,
    BreadcrumbComponent
  ],
  exports: []
})

export class SystemModule {

}
