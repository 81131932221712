import {Component, OnInit, Input} from '@angular/core';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {UcAuthService} from '../../../core/auth';
import {DataTabListBaseComponent} from '../../../system/oilFinance/oilFinanceListBase.component';
import {MatchmakingService} from '../../matchmaking.service';
import {CryptoJSService} from '../../../system/common/cryptoJS';
import {QueryModelPagination, ViewModelPagination} from '../../../system';
import {FrontModalOptModel} from '../../../system/oilFinance/finance.model';

@Component({
  selector: 'app-finance-cash-list',
  templateUrl: './cashList.html',
  styles: [`
    .head-example {
      padding-right: 20px;
    }
  `]
})
export class CashListComponent extends DataTabListBaseComponent implements OnInit {
  @Input() info;
  constructor(
    private matchmakingService: MatchmakingService,
    authService: UcAuthService,
    crypto2: CryptoJSService,
    messageService: NzMessageService,
    private modalservice: NzModalService
  ) {
    super(authService, crypto2, messageService, modalservice);

    this.tabs = [
      {
        title: '入金记录', type: 1
      },
      {
        title: '出金记录', type: 2
      }
    ];

    this.approvalCount = 0;
  }

  ngOnInit() {
    this.init();
    this.getTargetText = `出入金记录`;
    this.searchKey = 'traderName';
    this.sortKey = 'createTime';
    this.sortValue = false;
    this.defaultSearch();
  }

  getDataListService(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.matchmakingService.getCashList(body);
  }

  getModalOpt(type: string, modalOpt: FrontModalOptModel): FrontModalOptModel {
    switch (type) {
    }
    return modalOpt;
  }

  defaultSearch() {
    this.currentTab = 0;
    this.tabFiltersChange();
    this.searchData(true);
  }

  tabFiltersChange() {
    this.tabFitlers = [];
    this.currentTabType = this.tabs[this.currentTab]['type'];
    if (this.tabs[this.currentTab]['type'] === 1) {
      this.tabFitlers = [
        {key: 'traderId', type: 'Eq', value: this.info && this.info.userId || 'nonedata'},
        {key: 'type', type: 'Eq', value: 1}
      ];
    }
    if (this.tabs[this.currentTab]['type'] === 2) {
      this.tabFitlers = [
        {key: 'traderId', type: 'Eq', value: this.info && this.info.userId || 'nonedata'},
        {key: 'type', type: 'Eq', value: 2}
      ];
    }
  }

  getApprovalCount() {
  }

  rateToValue(r): number {
    let rate = 0;
    if (r) {
      rate = r * 100;
    }
    return rate;
  }
}
