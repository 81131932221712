<div>
  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24">撮合委托单编号</nz-form-label>
    <nz-form-control [nzSm]="16" [nzXs]="24">
      <nz-form-text>{{info?.orgOrderCode}}</nz-form-text>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24">撮合委托用户</nz-form-label>
    <nz-form-control [nzSm]="16" [nzXs]="24">
      <nz-form-text>{{info?.orgTraderName}}</nz-form-text>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24">手机号</nz-form-label>
    <nz-form-control [nzSm]="16" [nzXs]="24">
      <nz-form-text>{{info?.orgTraderUserMobile}}</nz-form-text>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24">撮合量</nz-form-label>
    <nz-form-control [nzSm]="16" [nzXs]="24">
      <nz-form-text *ngIf="info?.approveStatus <= 5">{{info?.volume || 0 | number}} 吨</nz-form-text>
      <nz-form-text *ngIf="info?.approveStatus === 10">{{info?.volume || 0 | number}} 吨</nz-form-text>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24">撮合价格</nz-form-label>
    <nz-form-control [nzSm]="16" [nzXs]="24">
      <nz-form-text >{{info?.price || 0 | number}} 元/吨</nz-form-text>
    </nz-form-control>
  </nz-form-item>
</div>
