import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {ChartInfo, ChartTemplate} from './ucChart.model';
import {QueryModelList, ViewModel} from '../../system.model';
import {Observable, ReplaySubject} from 'rxjs';
import {cloneDeep} from '../../../core/common';
import {BaseService} from './base.service';
import {HttpService} from './http.service';
import {DataService} from './data.service';

import { ChartService } from './chart.service';
import { DynamicService } from './dynamic.service';
import { ProcessService } from './process.service';
import {UcEditService} from './ucEdit.service';
import {UcWebSocketService} from './websocket.service';

const SERVICE = [
  BaseService, DynamicService, ProcessService, ChartService, DataService, HttpService, UcEditService, UcWebSocketService
];

@Component({
  selector: 'app-uc-chart',
  templateUrl: './ucChart.html',
  styleUrls: ['./ucChart.scss'],
  providers: [...SERVICE]
})
export class UcChartComponent implements OnInit, AfterViewInit, OnDestroy {
  chart;
  TCD: ChartTemplate;
  chartForType: string;
  isLoading: boolean;
  ucWebsocket: WebSocket;
  interval;

  tableSearchValue = '';
  fieldName = '';
  tableData;
  tableDefaultData;
  sortName = null;
  sortValue = null;
  total: number;
  pageIndex: number;
  pageSize: number;
  filterKey: string;
  filterValue: string;
  filters = [];
  defaultSourceBodyFilters = [];
  datetypeString: string;

  // 浏览器标签页切换监听
  private isDestory: boolean;
  private hiddenIt;

  // testData = 605.0304000000153;

  @Input() title: string;
  @Input() ucWidth: number | string;
  @Input() ucHeight = 450;
  @Input() ClassName: string;
  @Input() id: string;
  @Input() ChartInfo: any;
  @Input() queryModelList: QueryModelList;
  @Input() queryWsModelList: QueryModelList;
  @Input() tableField: string; // 表格的过滤字段
  @Input() updateTableField: string; // dynamic-update类型表格过滤相同字段数据
  @Input() isNewDateNull = false; // 最新时间内是否显示数据
  @Input() FiltersSources: string[] = []; // sources的是否添加统一的过滤字段， [source.id,...]
  @Input() chartDateKey: string; // 图表的主要时间字段名称
  @Input() DateType = 'date'; // date:年月日 时分秒 day: 年月日 hour: 时分秒

  @Output() defaultChart = new EventEmitter<any>();
  @Output() serieUpdate = new EventEmitter();
  @Output() ucAfterClose = new EventEmitter<void>();
  @Output() ucDefaultData = new EventEmitter();
  @Output() ucChartLoaded = new EventEmitter<boolean>();
  @Output() chartDataTracking = new EventEmitter<any>();

  get afterClose(): Observable<void> {
    return this.ucAfterClose.asObservable();
  }

  constructor(
    private render2: Renderer2,
    private element: ElementRef,
    private baseservice: BaseService,
    private httpservice: HttpService,
    private dataservice: DataService,
    private wsservice: UcWebSocketService,
    private dy: DynamicService,
    private _process: ProcessService
  ) {}

  ngOnInit() {
    this.isDestory = false;
    this.tableData = [];
    this.tableDefaultData = [];
    this.isLoading = true;
    this.total = 0;
    this.pageIndex = 1;
    this.pageSize = 10;
    this.defaultSourceBodyFilters = [];
    this.ucChartLoaded.emit(false);
    this.datetypeString = this.DateType === 'date' ? 'yyyy-MM-dd hh:mm:ss' : (this.DateType === 'day' ? 'yyyy-MM-dd' : (this.DateType === 'hour' ? 'hh:mm:ss' : 'yyyy-MM-dd hh:mm:ss'));
    this.parseChart();
  }

  ngAfterViewInit() {
    const hiddenProperty = 'hidden' in document ? 'hidden' :
      'webkitHidden' in document ? 'webkitHidden' :
        'mozHidden' in document ? 'mozHidden' :
          null;
    const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

    // 浏览器标签页切换监听函数
    const onVisibilityChange = () => {
      if (!this.isDestory && (this.TCD && this.TCD.chart_config.chartTmp.type !== 'static')) {
        let hiddenSecond = 0;
        if (this.hiddenIt) {
          clearInterval(this.hiddenIt);
        }
        // 判断document.hidden
        if (document[hiddenProperty]) {
          // 切换标签页,隐藏
          this.hiddenIt = setInterval(() => {
            hiddenSecond += 1;
            if (hiddenSecond > 90) {
              this.destroyDynamic();
              clearInterval(this.hiddenIt);
            }
          }, 1000);
        } else {
          // 切换标签页,显示
          if (this.ucWebsocket && this.ucWebsocket.readyState !== 1) {
            this.restChartTmplate();
          }
        }
      }
    };
    document.addEventListener(visibilityChangeEvent, onVisibilityChange);
  }

  ngOnDestroy() {
    this.sortName = null;
    this.sortValue = null;
    this.filterValue = null;
    this.filterKey = null;
    this.filters = [];
    this.defaultSourceBodyFilters = null;
    this.isDestory = true;
    this.destroyDynamic();

    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  /**
   * defaultChartInfo
   */
  public parseChart() {
    if (!this.isDestory) {
      this.isLoading = true;
      this.destroyDynamic();
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }

      // 判断父级传进来的是chartInfo还是chartID
      if (this.ChartInfo && this.ChartInfo.template) {
        const chartinfo = cloneDeep(this.ChartInfo);
        if  (chartinfo.template instanceof String) {
          chartinfo.template = JSON.parse(chartinfo.template);
        }
        this.defaultChartInfo(chartinfo);
      } else if (this.id) {
        this. httpservice.getChart(this.id).subscribe(
          res => {
            if (res.status === 200) {
              if (res.data) {
                this.ChartInfo = res.data;
                this.ChartInfo.template = JSON.parse(this.ChartInfo.template);
                this.defaultChartInfo(this.ChartInfo);
              }
            } else {
              console.log('chart请求错误：' + res['message']);
            }
          }
        );
      }
    }
  }

  defaultChartInfo(config: ChartInfo) {
    const chartInfo: ChartInfo = cloneDeep(config);
    this.chartForType = chartInfo.template.chart_for.type;
    this.TCD = chartInfo.template;

    if (this.queryModelList) {
      this.TCD = this.restQueryModelList(this.TCD);
    }
    if (this.queryWsModelList) {
      this.TCD = this.restWsQueryModelList(this.TCD);
    }

    this.dataservice.handleDataConfig(this, this.TCD);
  }

  /**
   * 重置body的内容
   * @param tmp
   */
  restQueryModelList(tcd: ChartTemplate): ChartTemplate {
    if (tcd && tcd.data_config.sources.length > 0) {
      if (tcd.data_config.filtersSourcesId && tcd.data_config.filtersSourcesId.length > 0) {
        tcd.data_config.sources.forEach((source) => {
          if (tcd.data_config.filtersSourcesId.includes(source.id)) {
            source.body = this.deepObjectMerge(tcd.data_config.sources[0].body, this.queryModelList);
          }
        });
      } else {
        if (this.FiltersSources.length > 0) {
          tcd.data_config.sources.forEach((source) => {
            if (this.FiltersSources.includes(source.id)) {
              source.body = this.deepObjectMerge(tcd.data_config.sources[0].body, this.queryModelList);
            }
          });
        } else {
          tcd.data_config.sources.forEach((source) => {
            source.body = this.deepObjectMerge(tcd.data_config.sources[0].body, this.queryModelList);
          });
        }
      }
    }

    return tcd;
  }
  /**
   * 重置body的内容
   * @param tmp
   */
  restWsQueryModelList(tcd: ChartTemplate): ChartTemplate {
    if (tcd && tcd.data_config.update_source) {
      tcd.data_config.update_source.body = tcd.data_config.update_source.body ?
        this.deepObjectMerge(tcd.data_config.update_source.body, this.queryWsModelList) : this.queryWsModelList;
    }

    return tcd;
  }

  /**
   *  修改source - body
   */
  restChartTmplate() {
    this.ucChartLoaded.emit(false);
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
    this.isLoading = true;
    this.destroyDynamic();

    setTimeout(() => {
      if (this.queryModelList) {
        this.TCD = this.restQueryModelList(this.TCD);
      }

      if (this.queryWsModelList) {
        this.TCD = this.restWsQueryModelList(this.TCD);
      }

      if (this.TCD) {
        this.dataservice.handleDataConfig(this, this.TCD);
      }
    }, 100);
  }


  /**
   * destroy Interval or close webSocket
   */
  destroyDynamic() {
    if (this.ucWebsocket) {
      this.ucWebsocket.close(1000, 'destroy');
    }
    // 组件销毁时，清除所有定时器、关闭所有websocket
    if (this.TCD) {
      const protocol = this.TCD.data_config.update_source.protocol;
      if (protocol === 'http' || protocol === 'https') {
        clearInterval(this.interval);
      }
    }
  }

  closeUcWebscoket(ws) {
    if (ws.readyState === 0) {
      return this.closeUcWebscoket(this.ucWebsocket);
    } else if (ws.readyState === 1) {
      this.ucWebsocket.close(1000, 'destroy');
    }
  }

  /**
   * 全屏显示
   */
  showAllTemplate() {
    if (this.id) {
      // 获取要展示全屏的元素
      const fullscreenDiv = $('#' + this.id)[0];
      let fullscreenFunc = fullscreenDiv.requestFullscreen;
      // 设定document的参数
      if (!fullscreenFunc) {
        ['mozRequsetFullScreen', 'msRequestFullscreen', 'webkitRequestFullScreen'].forEach((item) => {
          fullscreenFunc = fullscreenFunc || fullscreenDiv[item];
        });
      }
      // 把全屏展示的内容， 通过call改变this的指向
      fullscreenFunc.call(fullscreenDiv);
    }
  }


  /**
   * table 排序
   */
  sort(name, sort): void {
    this.sortName = name;
    this.sortValue = sort;
    this.search();
  }

  search(name?: string): void {
    if (name) {
      this.fieldName = name;
    }
    /** filter data **/
    const filterFunc = (item) => {
      return (this.fieldName && this.fieldName !== '' ? item[this.fieldName].indexOf(this.tableSearchValue) !== -1 : true);
    };

    const data = this.tableDefaultData.filter(item => filterFunc(item));
    /** sort data **/
    if (this.sortName && this.sortValue) {
      this.tableData = [...data.sort(
        (a, b) => (this.sortValue === 'ascend') ?
          (a[ this.sortName ] > b[ this.sortName ] ? 1 : -1) : (b[ this.sortName ] > a[ this.sortName ] ? 1 : -1))];
    } else {
      this.tableData = [...data];
    }
  }

  // 深度合并对象
  deepObjectMerge(FirstOBJ, SecondOBJ) {
    for (const key in SecondOBJ) {
      FirstOBJ[key] = FirstOBJ[key] && FirstOBJ[key].toString() === '[object Object]' ?
        this.deepObjectMerge(FirstOBJ[key], SecondOBJ[key]) : FirstOBJ[key] = SecondOBJ[key];
    }
    return FirstOBJ;
  }

  /**
   * 表格类型数据操作
   * @param reset
   */
  searchData(reset: boolean) {
    this.ucChartLoaded.emit(false);
    this.isLoading = true;
    this.destroyDynamic();

    this.dataservice.sourceDataRequest(this, this.TCD, reset);
  }

  /**
   * 排序
   */
  sortFunc(name: string, sort: string) {
    this.sortName = name;
    this.sortValue = sort === 'ascend' ? true : false;
    this.searchData(true);
  }


  updateFilter(key: string, value: string): void {
    this.filterKey = key;
    this.filterValue = value;
    this.filters = [];
    this.searchData(true);
  }

  nzFilterChange() {
    this.tableSearchValue = null;
    this.filters = [];
  }

  // addSeriesData() {
  //   const viewModel: any = {
  //     'code': 200,
  //     'data': {
  //       'trader_id': '39e5edf876f14a2b927a34f62b0058b9', 'offset': 605181,
  //       'trade_time': '2020-11-23 09:40:47', 'trader_team_name': '郑YF团队',
  //       'fee': 174.9696, 'stock_num': 1,
  //       'sync_time': '2020-11-23 09:40:59',
  //       'trade_date': 20201123, 'trader_company_name': '汇迪投资',
  //       'partition_id': 0, 'inner_fee': 174.9696, 'net_value': this.testData,
  //       'trader_company_id': 'ddd9b6d40a644bbead86267e10464d7d',
  //       'net_profit': this.testData, 'trader_name': '林南疆',
  //       'trader_team_id': '0b2dc4c2ae4b440e9ff169b8b301ab0e',
  //       'id': 'f0512c782d2c11ebb8b70249c554f5a5', 'order_num': 2,
  //       'hour_flag': 1, 'inner_net_profit': this.testData,
  //       'timestamp': +new Date()}, 'status': 200
  //   };
  //   const updateSource: any = {
  //     'id': 'update', 'type': 'update',
  //     'url': '/socket/a47fe629078149c2bdc26f32a8e066de', 'protocol': 'ws',
  //     'interval': 0, 'count': 1, 'body': null, 'data': [], 'fields': [],
  //     'process': []
  //   };
  //
  //   if (!viewModel.data.length || viewModel.data.length === 0) {
  //     if (viewModel.data.length === 0) {
  //       return false;
  //     } else {
  //       viewModel.data = [viewModel.data];
  //     }
  //   }
  //
  //   if (!viewModel.fields || viewModel.fields.length === 0) {
  //     viewModel.fields = [];
  //     for (const i in viewModel['data'][0]) {
  //       if (this.TCD.dataTmp.data_fields.indexOf(i) > -1) {
  //         this.TCD.dataTmp.data_dic.forEach((dic) => {
  //           if (i === dic['fieldName']) {
  //             viewModel.fields.push({
  //               fieldName: i,
  //               type: dic.fieldType
  //             });
  //           }
  //         });
  //       } else {
  //         viewModel.fields.push({
  //           fieldName: i,
  //           type: typeof (viewModel['data'][0][i])
  //         });
  //       }
  //     }
  //   }
  //
  //   this.chartDataTracking.emit(viewModel['data']);
  //   updateSource.fields = viewModel['fields'];
  //   updateSource.data = viewModel['data'];
  //
  //   this.TCD.chart_config.chartTmp.type = 'dynamic-addpoint';
  //   const source_data_tmp = this.dy.handleUpdateData(updateSource, this.TCD.chart_for.type); // 将json数据数组转化为列数据数组
  //   if (updateSource.process && updateSource.process.length !== 0) {
  //     this._process.handleProcess(updateSource.process, source_data_tmp);
  //   }
  //   // source重新获取数据
  //   updateSource.fields = source_data_tmp.data_field;
  //   updateSource.data = source_data_tmp.data_row;
  //
  //   if (this.TCD.chart_for.type === 'table') {
  //     if (updateSource.data && updateSource.data.length > 0) {
  //       this.dy.tableUpdate(this, updateSource.data, this.TCD.chart_config.chartTmp);
  //     }
  //   } else {
  //     // 最新时间
  //     const xAxisName = this.TCD.chart_config.chartOptions.xAxis.name;
  //     const newDate = viewModel['data'][0][xAxisName];
  //     if (updateSource.data && updateSource.data.length > 0) {
  //       this.dy.chartUpdate(this, this.chart, updateSource.data, xAxisName, this.TCD.chart_config.chartTmp, newDate);
  //     }
  //   }
  // }

}
