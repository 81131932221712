<div class="uc-container">
  <div class="mm-trader-info">
    <div class="trader-info">
      <nz-row [nzGutter]="16" *ngIf="financeAdmin">
        <nz-col nzSm="24" nzMd="24" nzLg="24" nzXl="12" nzXXl="12">
          <div class="uc-btn-group" style="margin-bottom: 15px;">
            <button nz-button nzType="primary" (click)="getTrader()" class="uc-btn uc-btn-icon uc-btn-main-create">
              <i nz-icon nzType="reload" nzTheme="outline"></i>刷新账户
            </button>
            <button nz-button nzType="primary" *ngIf="traderInfo" (click)="createModal('FUNDING', traderInfo)" class="uc-btn uc-btn-icon uc-btn-main-success">
              出入金记录
            </button>
          </div>
        </nz-col>
      </nz-row>
      <nz-row [nzGutter]="16">
        <nz-col *ngIf="financeAdmin" nzSm="24" nzMd="12" nzLg="12" nzXl="6" nzXXl="6">
          <nz-spin [nzSpinning]="isTraderSpinning">
            <div class="grid-item">
              <nz-statistic [nzValue]="(traderInfo?.accountEquity || 0 | number: '0.0-4')!" [nzTitle]="'账户权益'"></nz-statistic>
              <div style="border-bottom: 1px dashed #ccc;"></div>
              <nz-statistic [nzValue]="(traderInfo?.availableEquity || 0 | number: '0.0-4')!" [nzTitle]="'可用资金'"></nz-statistic>
              <div style="border-bottom: 1px dashed #ccc;"></div>
              <nz-statistic [nzValue]="((rateToValue(traderInfo?.bondRate) | number: '0.0-4') + '%')!" [nzTitle]="'保证金比例'"></nz-statistic>
              <div style="border-bottom: 1px dashed #ccc;"></div>
              <nz-statistic *ngIf="traderInfo?.feeType === 2" [nzValue]="((rateToValue(traderInfo?.feeRate) | number: '0.0-8') + '%')!" [nzTitle]="'手续费比例'"></nz-statistic>
              <nz-statistic *ngIf="traderInfo?.feeType === 1" [nzValue]="(traderInfo?.feeUnit || 0 | number)!" [nzTitle]="'手续费/吨'"></nz-statistic>
              <nz-statistic *ngIf="!traderInfo?.feeType" [nzValue]="(0)!" [nzTitle]="'手续费'"></nz-statistic>
              <div style="border-bottom: 1px dashed #ccc;"></div>
              <nz-statistic [nzValue]="(traderInfo?.positionBond || 0)!" [nzTitle]="'持仓占用保证金'"></nz-statistic>
              <div style="border-bottom: 1px dashed #ccc;"></div>
              <nz-statistic [nzValue]="(traderInfo?.closeProfit || 0)!" [nzTitle]="'累计平仓盈亏'"></nz-statistic>
              <div style="border-bottom: 1px dashed #ccc;"></div>
              <nz-statistic [nzValue]="(traderInfo?.lockedFunds || 0)!" [nzTitle]="'委托锁定资金'"></nz-statistic>
            </div>
          </nz-spin>
        </nz-col>
<!--        <nz-col *ngIf="financeAdmin" nzSm="24" nzMd="12" nzLg="12" nzXl="6" nzXXl="6">-->
<!--          <nz-spin [nzSpinning]="isTraderSpinning">-->
<!--            <div class="grid-item">-->
<!--              <nz-statistic [nzValue]="((rateToValue(traderInfo?.bondRate) | number: '0.0-4') + '%')!" [nzTitle]="'保证金比例'"></nz-statistic>-->
<!--              <div style="border-bottom: 1px dashed #ccc;"></div>-->
<!--              <nz-statistic *ngIf="traderInfo?.feeType === 2" [nzValue]="((rateToValue(traderInfo?.feeRate) | number: '0.0-8') + '%')!" [nzTitle]="'手续费比例'"></nz-statistic>-->
<!--              <nz-statistic *ngIf="traderInfo?.feeType === 1" [nzValue]="(traderInfo?.feeUnit)!" [nzTitle]="'手续费/吨'"></nz-statistic>-->
<!--            </div>-->
<!--          </nz-spin>-->
<!--        </nz-col>-->
        <nz-col nzSm="24" nzMd="24" nzLg="24" nzXl="18" nzXXl="18">
          <div class="grid-item">
            <div class="grid-box" style="margin-top: 0;">
              <div class="grid-box-header">
                <span>{{chartDataInfo.grid01.title}} {{'(' + selectChartOilType.label + ')'}}</span>
                <div class="lm_icon" style="margin-right: 15px;" title="刷新" (click)="resetChart(QuotationChart)">
                  <i nz-icon nzType="redo" nzTheme="outline"></i>
                </div>
                <div style="margin-right: 15px; float: right">
                  <span class="font-style">油品：</span>
                  <nz-select style="width: 100%;" [(ngModel)]="oilTypeKey" nzMode="default" nzPlaceHolder="选择油品" style="width: 100px;"
                             (ngModelChange)="oilChartTypeChange($event)">
                    <ng-container *ngFor="let o of oilTypeChartList">
                      <nz-option [nzValue]="o.key" [nzLabel]="o.label"></nz-option>
                    </ng-container>
                  </nz-select>
                </div>
              </div>
              <div class="grid-box-body">
                <app-uc-chart #QuotationChart [ucHeight]="455"></app-uc-chart>
              </div>
            </div>
          </div>
        </nz-col>
      </nz-row>
    </div>
  </div>
  <div class="mm-header">
    <div class="mm-container-radio">
      <span class="font-style" [ngClass]="{'span-select': direction === 2}" style="border-right: 1px solid #929292;" (click)="directionChange(2)">购买</span>
      <span class="font-style" [ngClass]="{'span-select': direction === 1}" (click)="directionChange(1)">出售</span>
    </div>
    <div class="clear"></div>
  </div>
  <div class="mm-header">
    <div class="mm-select">
      <div class="mm-select-item">
        <span class="font-style">片区：</span>
        <nz-select style="width: 100%;" [(ngModel)]="companyId" nzMode="default" nzPlaceHolder="选择片区" style="width: 150px;"
                   (ngModelChange)="companyChange($event)">
          <ng-container *ngFor="let c of companyList">
            <nz-option [nzValue]="c.orgId" [nzLabel]="c.name"></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div class="mm-select-item">
        <span class="font-style">销售商：</span>
        <nz-select style="width: 100%;" [(ngModel)]="sellerId" nzMode="default" nzPlaceHolder="选择销售商" style="width: 150px;"
                   (ngModelChange)="sellerChange($event)">
          <ng-container *ngFor="let s of sellerCompanyList">
            <nz-option [nzValue]="s.id" [nzLabel]="s.name"></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div class="mm-select-item">
        <span class="font-style">油库：</span>
        <nz-select style="width: 120px; margin-right: 10px" [(ngModel)]="cityCode" (ngModelChange)="cityChange($event)" nzPlaceHolder="选择城市">
          <nz-option *ngFor="let c of cityList" [nzValue]="c.cityCode" [nzLabel]="c.city"></nz-option>
        </nz-select>
        <nz-select style="width: 150px;" [(ngModel)]="storageId" nzPlaceHolder="选择油库" (ngModelChange)="storageChange($event)" [nzDisabled]="!cityCode">
          <nz-option *ngFor="let s of storageList" [nzValue]="s.id" [nzLabel]="s.storageName" ></nz-option>
        </nz-select>
      </div>
      <div class="mm-select-item">
        <span class="font-style">油品：</span>
        <nz-select style="width: 100%;" [(ngModel)]="oilTypeCode" nzMode="default" nzPlaceHolder="选择油品" style="width: 100px;"
                   (ngModelChange)="oilTypeChange($event)">
          <ng-container *ngFor="let o of oilTypeList">
            <nz-option [nzValue]="o.key" [nzLabel]="o.label"></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div class="mm-select-item">
        <button nz-button nzType="primary" (click)="filterSearch()" class="uc-btn uc-btn-icon uc-btn-sub-create">
          <i nz-icon nzType="search" nzTheme="outline"></i>搜索
        </button>
      </div>
      <div class="clear"></div>
    </div>
<!--    <div class="mm-refresh-item">-->
<!--      <button nz-button nzType="primary" (click)="refresh()" class="uc-btn uc-btn-icon uc-btn-main-default">-->
<!--        <i nz-icon nzType="reload" nzTheme="outline"></i>恢复默认-->
<!--      </button>-->
<!--    </div>-->
    <div class="clear"></div>
  </div>
  <div class="mm-content">
    <nz-spin [nzSpinning]="isSpinning">
      <div class="table-header-content">
        <div class="table-data-tip">
          <span *ngIf="!defaultData">当前为搜索数据</span>
          <span *ngIf="defaultData">当前为默认数据</span>
        </div>
        <div class="table-data-default">
          <button nz-button nzType="primary" (click)="refresh()" class="uc-btn uc-btn-icon uc-btn-main-default">
            <i nz-icon nzType="reload" nzTheme="outline"></i>刷新
          </button>
        </div>
      </div>
      <nz-table
      #orderDataTable
      class="uc-table"
      [nzData]="dataList"
      [nzScroll]="{x: '1000px'}"
      nzVirtualScroll
      nzSize="small"
      [nzLoading]="false"
      nzShowSizeChanger
      [nzFrontPagination]="false"
      [nzTotal]="totalItems"
      [(nzPageIndex)]="pageIndex"
      [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="searchData()"
      (nzPageSizeChange)="searchData(true)"
      (nzCurrentPageDataChange)="currentPageDataChange($event)">
      <thead (nzSortChange)="sort($event)" nzSingleSort>
        <tr>
          <th nzWidth="70px"></th>
          <th nzWidth="120px">价格(元/吨)</th>
          <th nzWidth="100px">数量(吨)</th>
          <th>限量(吨)</th>
          <th>油品</th>
          <th>油库</th>
          <th>片区</th>
          <th>销售商</th>
          <th>用户</th>
          <th nzWidth="150px" [nzRight]="true" >操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of orderDataTable.data; index as index">
          <td>
            <nz-tag [nzColor]="'#05c28c'" *ngIf="data.operation === 1">买单{{ index + 1 }}</nz-tag>
            <nz-tag [nzColor]="'#e3493f'" *ngIf="data.operation === 2">卖单{{ index + 1 }}</nz-tag>
          </td>
          <td [ngClass]="{'font-buy': direction === 2, 'font-sell': direction === 1}">{{ data.price || 0 | number: '0.0-4' }}</td>
          <td>{{ data.remainingVolume || 0 | number }}</td>
          <td>
            <span style="margin-right: 15px;">最小成交：{{ data.min || 0 | number}}</span>
            <span>最大成交：{{data.max || data.volume | number}}</span>
          </td>
          <td>{{ data.oilType }}</td>
          <td>{{ data.storageName }}</td>
          <td>{{ data.companyName }}</td>
          <td>{{ data.sellerCompanyName }}</td>
          <td>{{ data.traderName }}</td>
          <td [nzRight]="true" >
            <div class="uc-btn-group">
              <button nz-button nzType="primary" class="uc-btn uc-btn-small uc-btn-buy" *ngIf="direction === 2 && currentUser.id !== data.traderId" (click)="createModal('Buy', data)">购买(BUY)
              </button>
              <button nz-button nzType="primary" class="uc-btn uc-btn-small uc-btn-sell" *ngIf="direction === 1 && currentUser.id !== data.traderId" (click)="createModal('Sell', data)">出售(SELL)
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </nz-spin>
  </div>
</div>
