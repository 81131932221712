import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UcCommonService} from '../common.service';
import { NzModalRef } from 'ng-zorro-antd';
import {QueryModelPagination} from '../../system.model';

@Component({
  selector: 'app-risk-order-common-update',
  templateUrl: './riskOrderUpdateCommon.html'
})
export class RiskOrderUpdateCommonComponent implements OnInit {
  @Input() info: any;
  @Input() remainAmount: number;
  riskOrder;
  closeAmount = 0;
  newRemainAmount = 0;
  validateForm: FormGroup;
  orderTypeStr = {
    1: '上游采购',
    2: '内部成交',
    3: '主体货权转移',
    4: '内部借卖',
    5: '内部借提',
    6: '内部换油',
    7: '内部借归还'
  };

  constructor(
    private commonService: UcCommonService,
    private fb: FormBuilder,
    private modalRef: NzModalRef
  ) {
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      amount: [null, Validators.required]
    });

    if (this.info) {
      this.newRemainAmount = this.remainAmount + this.info.amount;
      this.closeAmount = this.info.amount;
      this.getRiskOrderList();
    }
  }


  getRiskOrderList() {
    const body: QueryModelPagination = {
      filters: [
        {key: 'id', type: 'Eq', value: this.info && this.info.id}
      ]
    };

    this.commonService.getAgentRiskOrderListAll(body).subscribe(res => {
      if (res.status === 200) {
        this.riskOrder = res.data && res.data[0] || null;
        if (this.riskOrder) {
          this.closeAmount = this.riskOrder.closeAmount + this.closeAmount;
        }
      }
    });
  }

  // 采购天数
  getBuyDays(date) {
    if (date) {
      const startTimestamp = +new Date(date);
      const buyTimestamp = +new Date() - startTimestamp;
      const days = Math.ceil(buyTimestamp / (24 * 60 * 60 * 1000));
      return days || 0;
    } else {
      return 0;
    }
  }

  submitForm() {
    if (this.validateForm.valid) {
      const formData = this.validateForm.value;
      this.modalRef.destroy(formData);
    }
  }
}
