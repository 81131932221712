import {Injectable} from '@angular/core';
import {UcStorageType} from '../cache';
import {UcCacheService} from '../cache';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {QueryModelList, ViewModelList} from '../../system';
import {UcEnvironmentService} from './environment.service';

export class TradingDay {
  ID?: number;
  TradingDate?: any;
  IfTradingDay?: number;
  SecuMarket?: number;
  IfWeekEnd?: number;
  IfMonthEnd?: number;
  IfQuarterEnd?: number;
  IfYearEnd?: number;
  XGRQ?: string;
  JSID?: number;
}

@Injectable()
export class TradingDayService {
  private static TRADING_DAY_POOL_KEY = '__tradingDayCachePool__';
  private static TRADING_DAY_KEY = 'tradingDays';

  private storageType: UcStorageType;
  private _tradingDays: TradingDay[];

  /**
   * 在使用tradingDays前请检查改Subject状态
   */
  public tradingdaysed = new ReplaySubject<boolean>(1);

  constructor(private cacheService: UcCacheService,
              private http: HttpClient,
              private environmentService: UcEnvironmentService) {
    this.storageType = UcStorageType.LocalStorage;
    this.tradingdaysed.next(true);
  }

  private getCache<T>(key: string): T {
    return this.cacheService.get<T>({
      pool: TradingDayService.TRADING_DAY_POOL_KEY,
      key: key,
      storageType: this.storageType
    });
  }

  private setCache(key: string, obj: Object) {
    this.cacheService.set({
      pool: TradingDayService.TRADING_DAY_POOL_KEY,
      key: key,
      storageType: this.storageType
    }, obj);
  }

  private removeAllCache() {
    this.cacheService.removeAll({
      pool: TradingDayService.TRADING_DAY_POOL_KEY,
      storageType: this.storageType
    });
  }

  set tradingDays(days: TradingDay[]) {
    this._tradingDays = days;
    this.setCache(TradingDayService.TRADING_DAY_KEY, days);
  }

  get tradingDays(): TradingDay[] {
    if (this._tradingDays) {
      return this._tradingDays;
    }
    return this.getCache(TradingDayService.TRADING_DAY_KEY);
  }

  /**
   * getInitTimes 交易日时间的判断获取
   */
  getIniterTradingdays(year) {
    const preYear = (year - 1) + '-01-01 00:00:00';
    const nextYear = (year + 1) + '-01-01 00:00:00';
    // 初始化时间跟交易信息
    const body = {
      pageSize: 3000,
      sort: {
        key: 'TradingDate',
        ascending: true
      },
      filters: [
        {
          key: 'TradingDate',
          type: 'Ge',
          value: preYear
        },
        {
          key: 'TradingDate',
          type: 'Le',
          value: nextYear
        },
        {
          key: 'SecuMarket',
          type: 'Eq',
          value: '83' // 83为上证交易日
        }
      ]
    };
    this.getTradingDay(body).subscribe(res => {
      if (res.data && res.data.length > 0) {
        this.tradingDays = res.data;
      } else {
        this.tradingDays = [];
      }
      this.tradingdaysed.next(true);
    });
  }

  /**
   * 重置交易时间
   */
  resetDays() {
    if (this.tradingDays && this.tradingDays.length > 0) {
      const year = new Date().getFullYear();
      const month = (new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1);
      const day = new Date().getDate() > 9 ? new Date().getDate() : '0' + new Date().getDate();
      const nowDate = year + '-' + month + '-' + day + ' 00:00:00';
      if (+new Date(this.tradingDays[this.tradingDays.length - 1].TradingDate) > +new Date(nowDate)) {
        this.tradingdaysed.next(true);
      } else {
        this._tradingDays = [];
        this.tradingdaysed.next(false);
      }
    } else {
      this._tradingDays = [];
      this.tradingdaysed.next(false);
    }
  }

  getTradingDay(body) {
    // tslint:disable-next-line:max-line-length
    return this.http.post<ViewModelList<any>>(this.environmentService.api.datasource + '/data/search/4ef7c216c6eb4bff8421c0a8315703c5', body);
  }

  /**
   * 判断交易时间列表是否存在
   */
  public isTradingdaysed(): Observable<boolean> {
    return this.tradingdaysed;
  }
}
