import {Component, Input, OnInit} from '@angular/core';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {MatchmakingService} from '../../matchmaking.service';
import {UcAuthService} from '../../../core/auth';
import {DataTabListBaseComponent} from '../../../system/oilFinance/oilFinanceListBase.component';
import {XlsxService} from '../../../core/common/xlsx.service';
import {CryptoJSService} from '../../../system/common/cryptoJS';
import { QueryModelPagination, ViewModelPagination, MessageModel } from 'src/app/system';
import {OrderModel, FrontModalOptModel} from '../../../system/oilFinance/finance.model';

@Component({
  selector: 'app-finance-mm-approve-list',
  templateUrl: './approveList.html',
  styles: [`
    .head-example {
      padding-right: 20px;
    }
  `]
})
export class ApproveListComponent extends DataTabListBaseComponent implements OnInit {
  @Input() info: OrderModel;
  orderStatus = {
    1: '委托中',
    10: '部成',
    20: '已成',
    200: '废单',
    201: '部撤',
    203: '已撤'
  };

  orderStatusFilter1 = [
    {text: '委托中', value: 1},
    {text: '部成', value: 10}
  ];

  orderStatusFilter = [
    {text: '委托中', value: 1},
    {text: '部成', value: 10},
    {text: '已成', value: 20},
    {text: '废单', value: 200},
    {text: '部撤', value: 201},
    {text: '已撤', value: 203},
  ];

  approveStatus = {
    1: '待审核',
    5: '待结清',
    10: '已结算',
    200: '已取消',
    201: '已作废',
    203: '已撤单'
  };

  approveStatusFilter = [
    {text: '待审核', value: 1},
    {text: '待结清', value: 5},
    {text: '已结算', value: 10},
    {text: '已取消', value: 200},
    {text: '已作废', value: 201},
    {text: '已撤单', value: 203}
  ];

  operation = {
    1: '买入',
    2: '卖出'
  };

  constructor(
    private matchmakingService: MatchmakingService,
    private xlsxService: XlsxService,
    authService: UcAuthService,
    crypto2: CryptoJSService,
    messageService: NzMessageService,
    private modalservice: NzModalService
  ) {
    super(authService, crypto2, messageService, modalservice);

    this.tabs = [
      {
        title: '待撮合', type: 1
      },
      {
        title: '已撮合', type: 2
      },
      {
        title: '已结算', type: 3
      }
    ];

    this.approvalCount = 0;
  }

  ngOnInit() {
    this.init();
    this.getTargetText = `撮合委托订单`;
    this.searchKey = 'traderName';
    this.sortKey = 'orderTime';
    this.sortValue = false;
    this.defaultSearch();
  }

  getDataListService(body: QueryModelPagination): Observable<ViewModelPagination<OrderModel>> {
    return this.matchmakingService.getList(body);
  }

  getModalOpt(type: string, modalOpt: FrontModalOptModel): FrontModalOptModel {
    switch (type) {
    }
    return modalOpt;
  }

  defaultSearch() {
    this.currentTab = 0;
    this.tabFiltersChange();
    this.searchData(true);
  }

  tabFiltersChange() {
    this.tabFitlers = [];
    this.currentTabType = this.tabs[this.currentTab]['type'];
    if (this.tabs[this.currentTab]['type'] === 1) {
      this.tabFilterType = 'And';
      this.tabFitlers = [
        {key: 'approveStatus', type: 'Eq', value: 1},
        {key: 'status', type: 'Lt', value: 20},
        {key: 'flag', type: 'Eq', value: true},
        {key: 'orgOrderId', type: 'Eq', value: this.info && this.info.id}
      ];
    }
    if (this.tabs[this.currentTab]['type'] === 2) {
      this.tabFilterType = 'And';
      this.tabFitlers = [
        {key: 'approveStatus', type: 'Eq', value: 5},
        {key: 'flag', type: 'Eq', value: true},
        {key: 'orgOrderId', type: 'Eq', value: this.info && this.info.id}
      ];
    }
    if (this.tabs[this.currentTab]['type'] === 3) {
      this.tabFilterType = 'And';
      this.tabFitlers = [
        {key: 'approveStatus', type: 'Eq', value: 10},
        {key: 'flag', type: 'Eq', value: true},
        {key: 'orgOrderId', type: 'Eq', value: this.info && this.info.id}
      ];
    }
  }

  getApprovalCount() {
  }

  rateToValue(r): number {
    let rate = 0;
    if (r) {
      rate = r * 100;
    }
    return rate;
  }
}
