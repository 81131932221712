<div class="uc-container">
  <div class="uc-sub-header">
    <div nz-row nzGutter="16" nzType="flex" nzJustify="space-between" nzAlign="middle">
      <div nz-col nzSpan="12"><!--左侧功能按钮-->
        <div class="uc-btn-group">
        </div>
      </div>
      <div nz-col nzSpan="12"><!--右侧搜索框-->
        <div nz-row nzGutter="5" nzType="flex" nzJustify="end" nzAlign="middle">
          <div nz-col *ngIf="sysAdmin">
            <nz-select [(ngModel)]="searchKey" class="uc-select-btn" nzPlaceHolder="选择字段">
              <nz-option [nzLabel]="'委托编号'" [nzValue]="'orderCode'"></nz-option>
              <nz-option [nzLabel]="'交易用户'" [nzValue]="'traderName'"></nz-option>
              <nz-option [nzLabel]="'手机号'" [nzValue]="'mobile'"></nz-option>
            </nz-select>
          </div>
          <div nz-col nzSpan="10" *ngIf="sysAdmin">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixSearchButton">
              <input type="text" nz-input placeholder="搜索" class="uc-input"
                     [(ngModel)]="searchValue" (keyup.enter)="search()">
            </nz-input-group>
            <ng-template #suffixSearchButton>
              <button nz-button nzSearch nzType="primary" (click)="search()"
                      class="uc-btn uc-btn-main-create uc-btn-icon">
                <i nz-icon nzType="search" nzTheme="outline"></i></button>
            </ng-template>
          </div>
          <div nz-col>
            <button nz-button nzType="primary" (click)="refresh()" class="uc-btn uc-btn-icon uc-btn-main-create"
                    style="width:32px">
              <i nz-icon nzType="reload" nzTheme="outline"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24">
      <nz-tabset class="uc-tabset" (nzSelectChange)="selectTab($event)" [nzType]="'card'" [(nzSelectedIndex)]="currentTab">
        <nz-tab [nzTitle]="titleTemplate" *ngFor="let tab of tabs">
          <ng-template #titleTemplate>
            <div>
              {{ tab.title }}
            </div>
          </ng-template>
          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-spin [nzSpinning]="isSpinning">
                <nz-table
                  #infoTable class="uc-table"
                  [nzData]="dataList"
                  nzVirtualScroll
                  nzSize="middle"
                  [nzScroll]="{x: '100vw'}"
                  [nzLoading]="false"
                  nzShowSizeChanger
                  [nzFrontPagination]="false"
                  [nzTotal]="total"
                  [(nzPageIndex)]="pageIndex"
                  [(nzPageSize)]="pageSize"
                  (nzPageIndexChange)="searchData()"
                  (nzPageSizeChange)="searchData(true)"
                  (nzCurrentPageDataChange)="currentPageDataChange($event)">
                  <thead (nzSortChange)="sort($event)" nzSingleSort>
                  <tr>
                    <th nzWidth="100px">委托用户</th>
                    <th nzWidth="140px">手机</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="volume">委托数量</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="price">委托价格</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="dealVolume">成交量</th>
                    <th nzWidth="100px" nzShowSort nzSortKey="remainingVolume">未成数量</th>
                    <th nzWidth="100px">油品</th>
                    <th nzWidth="100px" [nzFilters]="[{text: '买入', value: 1},{text: '卖出', value: 2}]"
                        [nzFilterMultiple]="false" (nzFilterChange)="updateFilter('operation', $event)">操作类型</th>
                    <th nzWidth="120px" nzShowSort nzSortKey="orderTime">委托时间</th>
                    <th nzWidth="100px" [nzFilters]="approveStatusFilter"
                        [nzFilterMultiple]="false" (nzFilterChange)="updateFilter('approveStatus', $event)">审核状态</th>
                    <th nzWidth='150px'>委托编号</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let data of infoTable.data;index as index">
                    <td>{{data.traderName}}</td>
                    <td>{{data.mobile}}</td>
                    <td>{{data.volume || 0 | number}}</td>
                    <td>{{data.price || 0 | number}}</td>
                    <td>{{data.dealVolume || 0 | number}}</td>
                    <td>{{data.remainingVolume || 0 | number}}</td>
                    <td>{{data.oilType}}</td>
                    <td>
                      <nz-tag [nzColor]="'#05c28c'" *ngIf="data.operation === 1">{{operation[data.operation]}}</nz-tag>
                      <nz-tag [nzColor]="'#e3493f'" *ngIf="data.operation === 2">{{operation[data.operation]}}</nz-tag>
                    </td>
                    <td>{{data.orderTime | date: 'yyyy-MM-dd HH:mm'}}</td>
                    <td>
                      <nz-tag [nzColor]="'#f50'" *ngIf="data.approveStatus === 1">{{approveStatus[data.approveStatus]}}</nz-tag>
                      <nz-tag [nzColor]="'#ffa500'" *ngIf="data.approveStatus === 5">{{approveStatus[data.approveStatus]}}</nz-tag>
                      <nz-tag [nzColor]="'#87d068'" *ngIf="data.approveStatus === 10">{{approveStatus[data.approveStatus]}}</nz-tag>
                      <nz-tag *ngIf="data.approveStatus > 100">{{approveStatus[data.approveStatus]}}</nz-tag>
                    </td>
                    <td>{{data.orderCode}}</td>
                  </tr>
                  </tbody>
                </nz-table>
              </nz-spin>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>
<ng-template #modalHeader>
  <div class="uc-modal-title-wrapper">
    <span class="uc-form-header">
      <i nz-icon [nzType]='modalIconOpt.nzType' [nzTheme]='modalIconOpt.nzTheme'
         [nzSpin]="modalIconOpt.spin" [nzIconfont]="modalIconOpt.iconfont"></i>&nbsp;&nbsp;{{modalTitleText}}
    </span>
  </div>
</ng-template>
