import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService, NzModalRef, NzTreeNode} from 'ng-zorro-antd';
import {BehaviorSubject, Observable, of} from 'rxjs';
import * as moment from 'moment';
import {MatchmakingService} from '../../matchmaking.service';
import { OrderModel } from 'src/app/system/oilFinance/finance.model';
import { QueryModelPagination } from 'src/app/system/system.model';
import {UcAuthService, UcUser} from '../../../core/auth';
import {CryptoJSService} from '../../../system/common/cryptoJS';

@Component({
  selector: 'app-oil-trader-sell-order-add',
  templateUrl: './traderSellOrder.html'
})
export class TraderSellOrderComponent implements OnInit, OnDestroy {
  positionList;
  selectPosition;
  positionId;
  available;

  validateForm: FormGroup;

  isSubmitting: boolean;
  financeAdmin: boolean;
  currentUser: UcUser;

  constructor(
    private matchmakingService: MatchmakingService,
    private fb: FormBuilder,
    private messageService: NzMessageService,
    private modalRef: NzModalRef,
    private authService: UcAuthService,
    private crypto: CryptoJSService
  ) {
    this.currentUser = this.authService.currentUser;
    this.financeAdmin = this.currentUser ? (this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_trader')) || this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_oil_fi_admin'))) : false;
  }

  ngOnInit() {
    this.available = 0;

    this.validateForm = this.fb.group({
      orderCode: [],
      traderId: [null, [Validators.required]],
      traderName: [null],
      traderUsername: [null],
      mobile: [null],
      flag: [false],
      oilTypeCode: [null, [Validators.required]],
      oilType: [null],
      sellerCompanyId: [null, [Validators.required]],
      sellerCompanyName: [null],
      storageId: [null, [Validators.required]],
      storageName: [null],
      companyName: [null],
      companyId: [null, [Validators.required]],
      positionId: [null, [Validators.required]],
      orderDate: [null],
      orderTime: [null],
      timestamp: [null],
      operation: [2, [Validators.required]],
      direction: ['SELL'],
      status: [1, [Validators.required]],
      approveStatus: [10, [Validators.required]],
      volume: [null, [Validators.required]],
      remainingVolume: [null],
      dealVolume: [0],
      price: [null, [Validators.required]],
      min: [10, [Validators.required]],
      max: [10, [Validators.required]]
    });

    this.validateForm.get('traderId').setValue(this.currentUser && this.currentUser.id);
    this.validateForm.get('traderUsername').setValue(this.currentUser && this.currentUser.username);
    this.validateForm.get('traderName').setValue(this.currentUser && this.currentUser.realname);
    this.validateForm.get('mobile').setValue(this.currentUser && this.currentUser.mobile);
    this.getPosition();
  }

  ngOnDestroy() {
    this.modalRef.destroy();
  }

  generateInfoSubmitted(): OrderModel {
    const formObj = this.validateForm.value;
    const date = moment(new Date()).format('YYYYMMDDHHmmss');
    formObj.orderCode = 'WT' + date ;
    formObj.orderDate = moment(new Date()).format('YYYYMMDD');
    formObj.orderTime = new Date();
    formObj.timestamp = +new Date();
    formObj.remainingVolume = formObj.volume;
    return formObj;
  }

  getPosition() {
    const body: QueryModelPagination = {
      filters: [
        {key: 'enabled', type: 'Eq', value: true},
        {key: 'userId', type: 'Eq', value: this.currentUser && this.currentUser.id || 'noneUser'}
      ]
    };

    this.matchmakingService.getTraderPositionListAllByBody(body).subscribe(res => {
      if (res.status === 200) {
        this.positionList = res.data || [];
        if (this.positionList.length === 0) {
          this.messageService.info('未获取到相关持仓信息，请建仓后交易！');
        }
      }
    });
  }

  positionChange(e) {
    if (e) {
      this.positionId = e;
      this.selectPosition = this.positionList.filter(item => item.id === e)[0];

      this.available = this.selectPosition && this.selectPosition.available || 0;

      this.validateForm.get('companyId').setValue(this.selectPosition && this.selectPosition.companyId);
      this.validateForm.get('companyName').setValue(this.selectPosition && this.selectPosition.companyName);
      this.validateForm.get('oilTypeCode').setValue(this.selectPosition && this.selectPosition.oilTypeCode);
      this.validateForm.get('oilType').setValue(this.selectPosition && this.selectPosition.oilType);
      this.validateForm.get('sellerCompanyId').setValue(this.selectPosition && this.selectPosition.sellerCompanyId);
      this.validateForm.get('sellerCompanyName').setValue(this.selectPosition && this.selectPosition.sellerCompanyName);
      this.validateForm.get('storageId').setValue(this.selectPosition && this.selectPosition.storageId);
      this.validateForm.get('storageName').setValue(this.selectPosition && this.selectPosition.storageName);
    }
  }

  volumeChange(e) {
    const data = e || 0;
    const data2 = data % 10;

    if (this.selectPosition) {
      if (data > this.selectPosition) {
        this.validateForm.get('max').setValue(this.selectPosition.available);
      } else {
        if (data2 !== 0) {
          this.validateForm.get('max').setValue(data - data2);
        } else {
          this.validateForm.get('max').setValue(data);
        }
      }
    } else {
      this.validateForm.get('max').setValue(10);
    }
  }

  submitForm() {
    this.isSubmitting = true;
    const formObj = this.generateInfoSubmitted();
    this.matchmakingService.add(formObj).subscribe(res => {
      if (res.status === 200) {
        this.messageService.success('出售委托下单成功，请等待审核确认！');
        this.modalRef.destroy(1);
      }
      this.isSubmitting = false;
    }, () => {
      this.messageService.error('出售委托下单失败！');
      this.isSubmitting = false;
    });
  }
}
