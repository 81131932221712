import {fromEvent, Observable} from 'rxjs';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import {AfterViewInit, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {FrontModalOptModel, ModalIconOpt, FrontCallBackHandler, FrontCallbackResultType, ApprovalStatus} from './finance.model';
import { Filter, QueryModelPagination, ViewModelPagination, MessageModel, ViewModelList, FilterType } from '../system.model';
import {debounceTime} from 'rxjs/operators';
import {UcAuthService, UcUser} from '../../core/auth';
import {CryptoJSService} from '../common/cryptoJS';

/**
 * 油贸业务模块 - 列表组件基类：实现了分页请求列表功能、所有单项操作功能和所有批量操作功能
 * 需要重写的部分有：getTargetText 属性、服务函数、模态框配置函数
 * 此外，还可以重写 prepareData()，用于预处理展示数据
 * 使用注意：在 ngOnInit() 中先执行初始化函数 init()，然后定义 getTargetText 属性值，再执行其他语句
 */
export class DataListBaseComponent implements AfterViewInit, OnDestroy {
  public autoHeight: number;
  isSpinning: boolean;

  dataList: any[];
  searchKey: string;
  searchValue: string;
  accountKey: string;
  accountValue: string;
  startLendTime?: any;
  endLendTime?: any;

  pageSizeOptions = [50, 100, 150, 200];
  pageIndex: number;
  pageSize: number;
  total: number;

  allChecked: boolean;
  displayData: any[];
  indeterminate: boolean;
  disabledBatchButton: boolean;
  operating: {[name in 'toApproved' | 'toDenied' | 'toChecking' | 'onDelete' | 'toContinue' | 'toStop' | 'toBatch']?: boolean};
  checkedNumber: number;

  modalIconOpt: ModalIconOpt;
  modalTitleText: string;
  @ViewChild('modalHeader', {static: true}) modalHeaderTpl: TemplateRef<any>; /* 需要在子组件类的 View 中增加 #modalHeader 的通用 <ng-template> */

  // 获取的对象名称：需自定义初值，比如'用户'、'用户组'，用于信息提示
  getTargetText: string;

  // nz-table filter字符串
  filterValue: any;
  filterKey: string;
  filters: Filter[];
  enabledFilter = [{text: '启用', value: true}, {text: '禁用', value: false}];
  // sort 字符串
  sortKey: string;
  sortValue: boolean;

  currentUser: UcUser;
  orgId: string;
  sysAdmin: boolean;
  financeAdmin: boolean;
  rootAdmin: boolean;

  hiddenIt;
  isDestory: boolean;

  constructor(
    private authService: UcAuthService,
    private crypto: CryptoJSService,
    protected messageService: NzMessageService,
    protected modalService: NzModalService
  ) {
    this.filters = [];

    this.currentUser = this.authService.currentUser;
    this.orgId = this.currentUser.mainOrgPath && this.currentUser.mainOrgPath.split('/')[1];
    this.sysAdmin = this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_sys_admin'));
    this.financeAdmin = this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_trader'));
    this.rootAdmin = this.currentUser.roles.includes(this.crypto.encryptByEnAESn('role_ofi_root_admin'));
    this.isDestory = false;
  }

  ngAfterViewInit() {
    const hiddenProperty = 'hidden' in document ? 'hidden' :
      'webkitHidden' in document ? 'webkitHidden' :
        'mozHidden' in document ? 'mozHidden' :
          null;
    const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

    // 浏览器标签页切换监听函数
    const onVisibilityChange = () => {
      if (!this.isDestory) {
        let hiddenSecond = 0;
        if (this.hiddenIt) {
          clearInterval(this.hiddenIt);
        }
        // 判断document.hidden
        if (document[hiddenProperty]) {
          // 切换标签页,隐藏
          this.hiddenIt = setInterval(() => {
            hiddenSecond += 1;
            if (hiddenSecond > 5) {
              clearInterval(this.hiddenIt);
            }
          }, 1000);
        } else {
          // 切换标签页,显示
          this.refresh();
        }
      }

    };
    document.addEventListener(visibilityChangeEvent, onVisibilityChange);
  }

  ngOnDestroy() {
    this.isDestory = true;
  }


  /**
   * 服务：单页列表请求
   * @param body 分页或搜索信息
   * @return Observable 对象
   */
  getDataListService(body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return null;
  }

  /**
   * 服务：单项更新
   * @param targetId 该对象的唯一标记
   * @param body 新的信息对象
   * @return Observable 对象
   */
  singleUpdateService(targetId: string, body: { [key: string]: any }): Observable<any> {
    return null;
  }

  singleApprovalService(targetId: string, body: { [key: string]: any }): Observable<any> {
    return null;
  }

  /**
   * 服务：单项删除
   * @param targetId 该对象的唯一标记
   * @return Observable 对象
   */
  singleDeleteService(targetId: string): Observable<MessageModel> {
    return null;
  }

  /**
   * 服务：批量更新
   * @param submittedData POST 的内容
   * @return Observable 对象
   */
  batchUpdateService(submittedData): Observable<MessageModel> {
    return null;
  }

  /**
   * 服务：批量删除
   * @param submittedData POST 的内容
   * @return Observable 对象
   */
  batchDeleteService(submittedData): Observable<MessageModel> {
    return null;
  }

  /**
   * 服务：请求全部数据，特殊需求下调用
   */
  getAllDataListService(): Observable<ViewModelList<any>> {
    return null;
  }

  /**
   * 模态框配置，使用 switch 配置各个模态框的外观和内容
   * @param type 区分不同模态框的标记
   * @param modalOpt 模态框配置
   */
  getModalOpt(type: string, modalOpt: FrontModalOptModel): FrontModalOptModel {
    return modalOpt;
  }

  /** 以下全为抽象函数，一般需求下不必重写 */

  /**
   * 监听页面大小变化、初始化属性
   */
  init(): void {
    this.dataList = [];
    this.pageIndex = 1;
    this.pageSize = 10;
    this.total = 0;
    this.searchValue = null;
    this.checkedNumber = 0;
    this.allChecked = false;
    this.displayData = [];
    this.indeterminate = false;
    this.disabledBatchButton = true;
    this.operating = {toApproved: false, toDenied: false, toChecking: false, onDelete: false};
    this.autoHeight = jQuery('body').height() - 200;
    fromEvent(window, 'resize').pipe(debounceTime(100))
      .subscribe((event) => {// 这里处理页面变化时的操作
        this.autoHeight = event['currentTarget']['innerHeight'] - 200;
      });
  }

  /**
   * 请求一页数据
   * @param reset:Boolean 需要重置页码时传入 true
   */
  searchData(reset: boolean = false): void {
    this.isSpinning = true;
    const defaultFilters = {
      resetFilters: (key: string, type: string, value: any) => {
        if (this.filters.length > 0) {
          const include = this.filters.map((f, num) => {
            if (f.key === key) {
              if (value !== null && value !== '') {
                f.value = value;
              } else {
                this.filters.splice(num, 1);
              }
            }
            return f.key === key;
          });

          if (!include.includes(true)) {
            if (value !== null && value !== '') {
              this.filters.push({key: key, type: type, value: value});
            }
          }
        } else {
          if (value !== null && value !== '') {
            this.filters.push({key: key, type: type, value: value});
          }
        }
      }
    };

    if (reset) {
      this.pageIndex = 1;
    }

    const body: QueryModelPagination = {
      pageSize: this.pageSize,
      pageIndex: this.pageIndex
    };
    const searchFilters = [];

    if (this.searchValue) {
      if (this.searchKey === 'companyId') {
        searchFilters.push({key: this.searchKey, type: FilterType[FilterType.Eq], value: this.searchValue});
      } else if (this.searchKey === 'companyIds') {
        searchFilters.push({key: this.searchKey, type: FilterType[FilterType.In], value: this.searchValue});
      } else {
        searchFilters.push({key: this.searchKey, type: FilterType[FilterType.Like], value: this.searchValue});
      }
    }

    if (this.filterKey) {
      if (this.filterKey === 'status' || this.filterKey === 'enabled' || this.filterKey === 'isPay'
        || this.filterKey === 'code' || this.filterKey === 'companyId' || this.filterKey === 'id'
        || this.filterKey === 'operation') {
        defaultFilters.resetFilters(this.filterKey, FilterType[FilterType.Eq], this.filterValue);
      } else if (this.filterKey === 'companyIds') {
        searchFilters.push({key: this.filterKey, type: FilterType[FilterType.In], value: this.filterValue});
      } else {
        defaultFilters.resetFilters(this.filterKey, FilterType[FilterType.Like], this.filterValue);
      }
    }

    if (this.sortKey) {
      body.sort = {
        key: this.sortKey,
        ascending: this.sortValue
      };
    }

    body.filters = [
      ...searchFilters,
      ...this.filters
    ];

    this.getDataListService(body).subscribe(res => {
      if (res.status === 200) {
        const preparedData = this.prepareData(res.data);
        this.total = res.totalItems;
        this.dataList = preparedData;
        this.isSpinning = false;
      } else {
        this.isSpinning = false;
        this.messageService.warning(`${this.getTargetText}获取失败，请稍后再试`);
      }
    }, () => {
      this.isSpinning = false;
      this.messageService.error(`${this.getTargetText}获取失败，请稍后再试`);
    });
  }

  /**
   * 成功获取列表数据后，进行预处理。特殊需求下才重写该函数。
   * @param originalData 原始列表数据
   */
  prepareData(originalData) {
    return originalData;
  }

  /**
   * 请求全部数据，特殊需求下调用
   */
  searchAllData(): void {
    this.isSpinning = true;

    this.getAllDataListService().subscribe(res => {
      this.isSpinning = false;
      if (res.status === 200) {
        this.dataList = res.data;
      } else {
        this.messageService.warning(`${this.getTargetText}获取失败，请稍后再试`);
      }
    }, () => {
      this.isSpinning = false;
      this.messageService.error(`${this.getTargetText}失败，请稍后再试`);
    });
  }

  /**
   * 搜索
   */
  search(): void {
    this.searchData(true);
  }

  searchKeyChange() {
    // this.searchValue = null;
  }

  /**
   * 清空搜索状态并刷新列表
   */
  refresh(): void {
    this.searchValue = null;
    this.filterValue = null;
    this.filterKey = null;
    this.filters = [];
    this.startLendTime = null;
    this.endLendTime = null;
    this.searchData(true);
  }

  /**
   * 全组件处理回调结果，注意闭包问题
   * @param resType 回调结果类型
   */
  handleCallBack: FrontCallBackHandler = (resType: FrontCallbackResultType): void => {
    switch (resType) {
      case FrontCallbackResultType.refresh: {
        this.searchData();
        break;
      }
      default : {
        // this.searchData();
        break;
      }
    }
  }

  /**
   * 表格功能：表格当前页的数据源改变时，读取当前页数据作为属性值
   * @param $event 新的当前页数据
   */
  currentPageDataChange($event: Array<any>): void {
    this.displayData = $event;
    if (this.displayData.length !== 0) {
      this.refreshStatus();
    }
  }

  /**
   * 表格功能：判断当前选择状态，并更新有关的属性变量
   */
  refreshStatus(e?): void {
    const allChecked = this.displayData.every(value => value.checked === true);
    /* .every() 不会检测空数组 */
    const allUnChecked = this.displayData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    this.disabledBatchButton = !this.dataList.some(value => value.checked);
    this.checkedNumber = this.dataList.filter(value => value.checked).length;
  }

  /**
   * 表格功能：全选
   * @param value 全选，或（全选时）全清
   */
  checkAll(value: boolean): void {
    this.displayData.forEach(data => data.checked = value);
    this.refreshStatus();
  }

  /**
   * 表格功能：清空所有选中状态，注意闭包问题
   */
  clearSelection = (): void => {
    this.dataList.forEach((data): void => {
      data.checked = false;
    });
    this.displayData = this.dataList;
    this.refreshStatus();
  }

  /**
   * 单项操作管理，包括更新和删除
   * @param type 更新的类型，在 switch 中配置
   * @param targetId 识别操作目标的唯一标记，用于 POST 到服务器
   * @param targetText? 操作目标的名称，用于在提示信息中显示，比如"用户"、"角色"
   * @param deleteConfirmMsg? 删除时的确认框信息，可选字段 titleText: 确认框标题，content: 确认框内容
   */
  operateSingleData(
    type: string,
    targetId: string,
    targetText: string = '对象',
    deleteConfirmMsg?: { title?: string, content?: string }
  ): void {
    const newProp: { name: string, value } = {name: null, value: null};
    let msgText: string = null;
    const submittedData: { [key: string]: any } = {};
    switch (type) {
      case 'toApproved': {
        newProp.name = 'approvalStatus';
        newProp.value = ApprovalStatus[ApprovalStatus.approved];
        msgText = '同意';
        break;
      }
      case 'toDenied': {
        newProp.name = 'approvalStatus';
        newProp.value = ApprovalStatus[ApprovalStatus.denied];
        msgText = '拒绝';
        break;
      }
      case 'toChecking': {
        newProp.name = 'approvalStatus';
        newProp.value = ApprovalStatus[ApprovalStatus.checking];
        msgText = '撤销';
        break;
      }
      case 'toEnabled': {
        newProp.name = 'enabled';
        newProp.value = true;
        msgText = '启用';
        break;
      }
      case 'toDisabled': {
        newProp.name = 'enabled';
        newProp.value = false;
        msgText = '禁用';
        break;
      }
      case 'onDelete': {
        msgText = '删除';
        break;
      }
      default: {
        this.operating[type] = false;
        return;
      }
    }
    let resType: FrontCallbackResultType;
    if (newProp.name) {
      // 单项更新
      submittedData[newProp.name] = newProp.value;
      this.singleUpdateService(targetId, submittedData).subscribe(res => {
        if (res.status === 200) {
          this.messageService.create('success', `已成功${msgText}该${targetText}`);
          resType = FrontCallbackResultType.refresh;
        } else {
          this.messageService.create('warning', `提交失败，请稍后再试`);
          resType = FrontCallbackResultType.error;
        }
        this.operating[type] = false;
        this.handleCallBack(resType);
      }, () => {
        this.operating[type] = false;
        this.messageService.create('error', `提交失败，请稍后再试`);
        resType = FrontCallbackResultType.error;
        this.handleCallBack(resType);
      });
    } else {
      // 单项删除
      this.modalService.confirm({
        nzTitle: deleteConfirmMsg.title ? deleteConfirmMsg.title : `确认删除该${targetText}吗`,
        nzContent: deleteConfirmMsg.content ? deleteConfirmMsg.content : null,
        nzOnOk: () => {
          this.singleDeleteService(targetId).subscribe(res => {
            if (res['status'] === 200) {
              this.messageService.success(`成功删除该${targetText}`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.warning('删除失败，请稍后再试');
              resType = FrontCallbackResultType.error;
            }
            this.handleCallBack(resType);
          }, () => {
            this.messageService.error('删除失败，请稍后再试');
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });
    }
  }


  operateSingle(
    type: string,
    targetId: string,
    targetText: string = '对象',
    deleteConfirmMsg?: { title?: string, content?: string }
  ): void {
    const newProp: { name: string, value } = {name: null, value: null};
    let msgText: string = null;
    const submittedData: { [key: string]: any } = {};
    switch (type) {
      case 'onPayee': {
        newProp.name = 'approveStatus';
        newProp.value = 10;
        msgText = '确认成交';
        break;
      }
      case 'onVoid': {
        newProp.name = 'approveStatus';
        newProp.value = 200;
        msgText = '确认取消';
        break;
      }
      case 'onEnd': {
        newProp.name = 'approveStatus';
        newProp.value = 100;
        msgText = '确认结清';
        break;
      }
      case 'onDestroy': {
        newProp.name = 'approveStatus';
        newProp.value = 201;
        msgText = '确认作废';
        break;
      }
      case 'onEnabled': {
        newProp.name = 'enabled';
        newProp.value = false;
        msgText = '确认作废';
        break;
      }
      default: {
        this.operating[type] = false;
        return;
      }
    }
    let resType: FrontCallbackResultType;
    if (newProp.name) {
      // 单项更新
      submittedData[newProp.name] = newProp.value;
      this.modalService.confirm({
        nzTitle: deleteConfirmMsg.title ? deleteConfirmMsg.title : `${msgText}！`,
        nzContent: deleteConfirmMsg.content ? deleteConfirmMsg.content : null,
        nzOnOk: () => {
          this.singleApprovalService(targetId, submittedData).subscribe(res => {
            if (res.status === 200) {
              this.messageService.create('success', `${targetText}已${msgText}`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.create('warning', `提交失败，请重新操作！`);
              resType = FrontCallbackResultType.error;
            }
            this.operating[type] = false;
            this.handleCallBack(resType);
          }, () => {
            this.operating[type] = false;
            // this.messageService.create('error', `提交失败，请重新操作！`);
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });
    }
  }

  /**
   * 单项意向操作管理，包括更新和删除
   * @param type 更新的类型，在 switch 中配置
   * @param targetId 识别操作目标的唯一标记，用于 POST 到服务器
   * @param targetText? 操作目标的名称，用于在提示信息中显示，比如"用户"、"角色"
   * @param deleteConfirmMsg? 删除时的确认框信息，可选字段 titleText: 确认框标题，content: 确认框内容
   */
  operateIntentSingleData(
    type: string,
    targetId: string,
    targetText: string = '对象',
    deleteConfirmMsg?: { title?: string, content?: string }
  ): void {
    const newProp: { name: string, value } = {name: null, value: null};
    let msgText: string = null;
    const submittedData: { [key: string]: any } = {};
    switch (type) {
      case 'toApproved': {
        newProp.name = 'approvalStatus';
        newProp.value = 20;
        msgText = '同意';
        break;
      }
      case 'toDenied': {
        newProp.name = 'approvalStatus';
        newProp.value = 15;
        msgText = '拒绝';
        break;
      }
      case 'toChecking': {
        newProp.name = 'approvalStatus';
        newProp.value = 10;
        msgText = '撤销';
        break;
      }
      case 'toEnabled': {
        newProp.name = 'enabled';
        newProp.value = true;
        msgText = '启用';
        break;
      }
      case 'toDisabled': {
        newProp.name = 'enabled';
        newProp.value = false;
        msgText = '禁用';
        break;
      }
      case 'onDelete': {
        msgText = '删除';
        break;
      }
      default: {
        this.operating[type] = false;
        return;
      }
    }
    let resType: FrontCallbackResultType;
    if (newProp.name) {
      // 单项更新
      submittedData[newProp.name] = newProp.value;
      this.singleUpdateService(targetId, submittedData).subscribe(res => {
        if (res.status === 200) {
          this.messageService.create('success', `已成功${msgText}该${targetText}`);
          resType = FrontCallbackResultType.refresh;
        } else {
          this.messageService.create('warning', `提交失败，请稍后再试`);
          resType = FrontCallbackResultType.error;
        }
        this.operating[type] = false;
        this.handleCallBack(resType);
      }, () => {
        this.operating[type] = false;
        this.messageService.create('error', `提交失败，请稍后再试`);
        resType = FrontCallbackResultType.error;
        this.handleCallBack(resType);
      });
    } else {
      // 单项删除
      this.modalService.confirm({
        nzTitle: deleteConfirmMsg.title ? deleteConfirmMsg.title : `确认删除该${targetText}吗`,
        nzContent: deleteConfirmMsg.content ? deleteConfirmMsg.content : null,
        nzOnOk: () => {
          this.singleDeleteService(targetId).subscribe(res => {
            if (res['status'] === 200) {
              this.messageService.success(`成功删除该${targetText}`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.warning('删除失败，请稍后再试');
              resType = FrontCallbackResultType.error;
            }
            this.handleCallBack(resType);
          }, () => {
            this.messageService.error('删除失败，请稍后再试');
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });
    }
  }

  /**
   * 单项申请操作管理，包括更新和删除
   * @param type 更新的类型，在 switch 中配置
   * @param targetId 识别操作目标的唯一标记，用于 POST 到服务器
   * @param targetText? 操作目标的名称，用于在提示信息中显示，比如"用户"、"角色"
   * @param deleteConfirmMsg? 删除时的确认框信息，可选字段 titleText: 确认框标题，content: 确认框内容
   */
  operateApplicationSingleData(
    type: string,
    targetId: string,
    targetText: string = '对象',
    deleteConfirmMsg?: { title?: string, content?: string }
  ): void {
    const newProp: { name: string, value } = {name: null, value: null};
    let msgText: string = null;
    const submittedData: { [key: string]: any } = {};
    switch (type) {
      case 'toApproved': {
        newProp.name = 'approvalStatus';
        newProp.value = 50;
        msgText = '同意';
        break;
      }
      case 'toDenied': {
        newProp.name = 'approvalStatus';
        newProp.value = 45;
        msgText = '拒绝';
        break;
      }
      case 'toChecking': {
        newProp.name = 'approvalStatus';
        newProp.value = 40;
        msgText = '撤销';
        break;
      }
      case 'toEnabled': {
        newProp.name = 'enabled';
        newProp.value = true;
        msgText = '启用';
        break;
      }
      case 'toDisabled': {
        newProp.name = 'enabled';
        newProp.value = false;
        msgText = '禁用';
        break;
      }
      case 'onDelete': {
        msgText = '删除';
        break;
      }
      default: {
        this.operating[type] = false;
        return;
      }
    }
    let resType: FrontCallbackResultType;
    if (newProp.name) {
      // 单项更新
      submittedData[newProp.name] = newProp.value;
      this.singleUpdateService(targetId, submittedData).subscribe(res => {
        if (res.status === 200) {
          this.messageService.create('success', `已成功${msgText}该${targetText}`);
          resType = FrontCallbackResultType.refresh;
        } else {
          this.messageService.create('warning', `提交失败，请稍后再试`);
          resType = FrontCallbackResultType.error;
        }
        this.operating[type] = false;
        this.handleCallBack(resType);
      }, () => {
        this.operating[type] = false;
        this.messageService.create('error', `提交失败，请稍后再试`);
        resType = FrontCallbackResultType.error;
        this.handleCallBack(resType);
      });
    } else {
      // 单项删除
      this.modalService.confirm({
        nzTitle: deleteConfirmMsg.title ? deleteConfirmMsg.title : `确认删除该${targetText}吗`,
        nzContent: deleteConfirmMsg.content ? deleteConfirmMsg.content : null,
        nzOnOk: () => {
          this.singleDeleteService(targetId).subscribe(res => {
            if (res['status'] === 200) {
              this.messageService.success(`成功删除该${targetText}`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.warning('删除失败，请稍后再试');
              resType = FrontCallbackResultType.error;
            }
            this.handleCallBack(resType);
          }, () => {
            this.messageService.error('删除失败，请稍后再试');
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });
    }
  }

  /**
   * 单项展期操作管理，包括更新和删除
   * @param type 更新的类型，在 switch 中配置
   * @param targetId 识别操作目标的唯一标记，用于 POST 到服务器
   * @param targetText? 操作目标的名称，用于在提示信息中显示，比如"用户"、"角色"
   * @param deleteConfirmMsg? 删除时的确认框信息，可选字段 titleText: 确认框标题，content: 确认框内容
   */
  operateDelaySingleData(
    type: string,
    targetId: string,
    targetText: string = '对象',
    deleteConfirmMsg?: { title?: string, content?: string }
  ): void {
    const newProp: { name: string, value } = {name: null, value: null};
    let msgText: string = null;
    const submittedData: { [key: string]: any } = {};
    switch (type) {
      case 'toApproved': {
        newProp.name = 'approvalStatus';
        newProp.value = 80;
        msgText = '同意';
        break;
      }
      case 'toDenied': {
        newProp.name = 'approvalStatus';
        newProp.value = 75;
        msgText = '拒绝';
        break;
      }
      case 'toChecking': {
        newProp.name = 'approvalStatus';
        newProp.value = 70;
        msgText = '撤销';
        break;
      }
      case 'toEnabled': {
        newProp.name = 'enabled';
        newProp.value = true;
        msgText = '启用';
        break;
      }
      case 'toDisabled': {
        newProp.name = 'enabled';
        newProp.value = false;
        msgText = '禁用';
        break;
      }
      case 'onDelete': {
        msgText = '删除';
        break;
      }
      default: {
        this.operating[type] = false;
        return;
      }
    }
    let resType: FrontCallbackResultType;
    if (newProp.name) {
      // 单项更新
      submittedData[newProp.name] = newProp.value;
      this.singleUpdateService(targetId, submittedData).subscribe(res => {
        if (res.status === 200) {
          this.messageService.create('success', `已成功${msgText}该${targetText}`);
          resType = FrontCallbackResultType.refresh;
        } else {
          this.messageService.create('warning', `提交失败，请稍后再试`);
          resType = FrontCallbackResultType.error;
        }
        this.operating[type] = false;
        this.handleCallBack(resType);
      }, () => {
        this.operating[type] = false;
        this.messageService.create('error', `提交失败，请稍后再试`);
        resType = FrontCallbackResultType.error;
        this.handleCallBack(resType);
      });
    } else {
      // 单项删除
      this.modalService.confirm({
        nzTitle: deleteConfirmMsg.title ? deleteConfirmMsg.title : `确认删除该${targetText}吗`,
        nzContent: deleteConfirmMsg.content ? deleteConfirmMsg.content : null,
        nzOnOk: () => {
          this.singleDeleteService(targetId).subscribe(res => {
            if (res['status'] === 200) {
              this.messageService.success(`成功删除该${targetText}`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.warning('删除失败，请稍后再试');
              resType = FrontCallbackResultType.error;
            }
            this.handleCallBack(resType);
          }, () => {
            this.messageService.error('删除失败，请稍后再试');
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });
    }
  }

  /**
   * 批量操作管理，包括更新和删除
   * 注意：更新和删除依据的都是对象的 id 字段
   * @param type 批量操作的类型，在组件的属性变量 operating 中定义
   * @param targetText 操作的对象，用于在提示信息中显示
   * @param targetKey? 操作对象的主键键名，默认为 id
   * @param submittedKey? POST 对象的键名，默认为 ids
   */
  operateMultipleData(type: string, targetText: string, targetKey: string = 'id', submittedKey: string = 'ids'): void {
    this.operating[type] = true;
    const submittedData = {[submittedKey]: []};
    const newProp: { name: string, value } = {name: null, value: null};
    let msgText: string = null;
    let selectedItemCount = 0;
    switch (type) {
      case 'toApproved': {
        newProp.name = 'approvalStatus';
        newProp.value = ApprovalStatus[ApprovalStatus.approved];
        msgText = '同意';
        break;
      }
      case 'toDenied': {
        newProp.name = 'approvalStatus';
        newProp.value = ApprovalStatus[ApprovalStatus.denied];
        msgText = '拒绝';
        break;
      }
      case 'toChecking': {
        newProp.name = 'approvalStatus';
        newProp.value = ApprovalStatus[ApprovalStatus.checking];
        msgText = '撤销';
        break;
      }
      case 'onDelete': {
        msgText = '删除';
        break;
      }
      default: {
        this.operating[type] = false;
        return;
      }
    }
    // 获取选中项和需更新项
    this.dataList.forEach((data): void => {
      if (data.checked) {
        selectedItemCount += 1;
        if (data[newProp.name] !== newProp.value || !newProp.name) {
          submittedData[submittedKey].push(data[targetKey]);
        }
      }
    });

    this.clearSelection();

    // 校验
    const len = submittedData[submittedKey].length;
    if (len === 0) {
      if (selectedItemCount === 0) {
        this.messageService.create('warning', `请选择要操作的${targetText}`);
      } else if (newProp.name) {
        this.messageService.create('warning', `所有选中${targetText}都已处于 ${msgText} 状态`);
      }
      this.operating[type] = false;
      return;
    }

    let resType: FrontCallbackResultType;
    if (newProp.name) {
      // 批量更新
      submittedData[newProp.name] = newProp.value;
      this.batchUpdateService(submittedData).subscribe(res => {
        if (res.status === 200) {
          this.messageService.create('success', `成功${msgText} ${len} 个${targetText}${selectedItemCount === len ? '' :
            `，其余 ${selectedItemCount - len} 个${targetText}更新前已处于${msgText}状态`
          }`);
          resType = FrontCallbackResultType.refresh;
        } else {
          this.messageService.create('warning', `提交失败，请稍后再试`);
          resType = FrontCallbackResultType.error;
        }
        this.operating[type] = false;
        this.handleCallBack(resType);
      }, () => {
        this.operating[type] = false;
        this.messageService.create('error', `提交失败，请稍后再试`);
        resType = FrontCallbackResultType.error;
        this.handleCallBack(resType);
      });
    } else {
      // 批量删除
      this.modalService.confirm({
        nzTitle: `确认删除选中的 ${selectedItemCount} 个${targetText}吗？`,
        nzContent: null,
        nzOnCancel: () => {
          this.operating[type] = false;
        },
        nzOnOk: () => {
          this.batchDeleteService(submittedData).subscribe(res => {
            if (res.status === 200) {
              this.messageService.create('success', `成功${msgText} ${selectedItemCount} 个${targetText}`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.create('warning', `${msgText}失败，请稍后再试`);
              resType = FrontCallbackResultType.error;
            }
            this.operating[type] = false;
            this.handleCallBack(resType);
          }, () => {
            this.operating[type] = false;
            this.messageService.create('error', `${msgText}失败，请稍后再试`);
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });
    }
  }


  /**
   * 批量意向操作管理，包括更新和删除
   * 注意：更新和删除依据的都是对象的 id 字段
   * @param type 批量操作的类型，在组件的属性变量 operating 中定义
   * @param targetText 操作的对象，用于在提示信息中显示
   * @param targetKey? 操作对象的主键键名，默认为 id
   * @param submittedKey? POST 对象的键名，默认为 ids
   */
  operateIntentMultipleData(type: string, targetText: string, targetKey: string = 'id', submittedKey: string = 'ids'): void {
    this.operating[type] = true;
    const submittedData = {[submittedKey]: []};
    const newProp: { name: string, value } = {name: null, value: null};
    let msgText: string = null;
    let selectedItemCount = 0;
    switch (type) {
      case 'toApproved': {
        newProp.name = 'approvalStatus';
        newProp.value = 20;
        msgText = '同意';
        break;
      }
      case 'toDenied': {
        newProp.name = 'approvalStatus';
        newProp.value = 15;
        msgText = '拒绝';
        break;
      }
      case 'toChecking': {
        newProp.name = 'approvalStatus';
        newProp.value = 10;
        msgText = '撤销';
        break;
      }
      case 'onDelete': {
        msgText = '删除';
        break;
      }
      default: {
        this.operating[type] = false;
        return;
      }
    }
    // 获取选中项和需更新项
    this.dataList.forEach((data): void => {
      if (data.checked) {
        selectedItemCount += 1;
        if (data[newProp.name] !== newProp.value || !newProp.name) {
          submittedData[submittedKey].push(data[targetKey]);
        }
      }
    });

    this.clearSelection();

    // 校验
    const len = submittedData[submittedKey].length;
    if (len === 0) {
      if (selectedItemCount === 0) {
        this.messageService.create('warning', `请选择要操作的${targetText}`);
      } else if (newProp.name) {
        this.messageService.create('warning', `所有选中${targetText}都已处于 ${msgText} 状态`);
      }
      this.operating[type] = false;
      return;
    }

    let resType: FrontCallbackResultType;
    if (newProp.name) {
      // 批量更新
      this.modalService.confirm({
        nzTitle: `确认${msgText}选中的 ${selectedItemCount} 个${targetText}吗？`,
        nzContent: null,
        nzOnCancel: () => {
          this.operating[type] = false;
        },
        nzOnOk: () => {
          submittedData[newProp.name] = newProp.value;
          this.batchUpdateService(submittedData).subscribe(res => {
            if (res.status === 200) {
              this.messageService.create('success', `成功${msgText} ${len} 个${targetText}${selectedItemCount === len ? '' :
                `，其余 ${selectedItemCount - len} 个${targetText}更新前已处于${msgText}状态`
              }`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.create('warning', `提交失败，请稍后再试`);
              resType = FrontCallbackResultType.error;
            }
            this.operating[type] = false;
            this.handleCallBack(resType);
          }, () => {
            this.operating[type] = false;
            this.messageService.create('error', `提交失败，请稍后再试`);
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });

    } else {
      // 批量删除
      this.modalService.confirm({
        nzTitle: `确认删除选中的 ${selectedItemCount} 个${targetText}吗？`,
        nzContent: null,
        nzOnCancel: () => {
          this.operating[type] = false;
        },
        nzOnOk: () => {
          this.batchDeleteService(submittedData).subscribe(res => {
            if (res.status === 200) {
              this.messageService.create('success', `成功${msgText} ${selectedItemCount} 个${targetText}`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.create('warning', `${msgText}失败，请稍后再试`);
              resType = FrontCallbackResultType.error;
            }
            this.operating[type] = false;
            this.handleCallBack(resType);
          }, () => {
            this.operating[type] = false;
            this.messageService.create('error', `${msgText}失败，请稍后再试`);
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });
    }
  }

  /**
   * 批量申请操作管理，包括更新和删除
   * 注意：更新和删除依据的都是对象的 id 字段
   * @param type 批量操作的类型，在组件的属性变量 operating 中定义
   * @param targetText 操作的对象，用于在提示信息中显示
   * @param targetKey? 操作对象的主键键名，默认为 id
   * @param submittedKey? POST 对象的键名，默认为 ids
   */
  operateApplicationMultipleData(type: string, targetText: string, targetKey: string = 'id', submittedKey: string = 'ids'): void {
    this.operating[type] = true;
    const submittedData = {[submittedKey]: []};
    const newProp: { name: string, value } = {name: null, value: null};
    let msgText: string = null;
    let selectedItemCount = 0;
    switch (type) {
      case 'toApproved': {
        newProp.name = 'approvalStatus';
        newProp.value = 50;
        msgText = '同意';
        break;
      }
      case 'toDenied': {
        newProp.name = 'approvalStatus';
        newProp.value = 45;
        msgText = '拒绝';
        break;
      }
      case 'toChecking': {
        newProp.name = 'approvalStatus';
        newProp.value = 40;
        msgText = '撤销';
        break;
      }
      case 'onDelete': {
        msgText = '删除';
        break;
      }
      default: {
        this.operating[type] = false;
        return;
      }
    }
    // 获取选中项和需更新项
    this.dataList.forEach((data): void => {
      if (data.checked) {
        selectedItemCount += 1;
        if (data[newProp.name] !== newProp.value || !newProp.name) {
          submittedData[submittedKey].push(data[targetKey]);
        }
      }
    });

    this.clearSelection();

    // 校验
    const len = submittedData[submittedKey].length;
    if (len === 0) {
      if (selectedItemCount === 0) {
        this.messageService.create('warning', `请选择要操作的${targetText}`);
      } else if (newProp.name) {
        this.messageService.create('warning', `所有选中${targetText}都已处于 ${msgText} 状态`);
      }
      this.operating[type] = false;
      return;
    }

    let resType: FrontCallbackResultType;
    if (newProp.name) {
      // 批量更新
      submittedData[newProp.name] = newProp.value;
      this.batchUpdateService(submittedData).subscribe(res => {
        if (res.status === 200) {
          this.messageService.create('success', `成功${msgText} ${len} 个${targetText}${selectedItemCount === len ? '' :
            `，其余 ${selectedItemCount - len} 个${targetText}更新前已处于${msgText}状态`
          }`);
          resType = FrontCallbackResultType.refresh;
        } else {
          this.messageService.create('warning', `提交失败，请稍后再试`);
          resType = FrontCallbackResultType.error;
        }
        this.operating[type] = false;
        this.handleCallBack(resType);
      }, () => {
        this.operating[type] = false;
        this.messageService.create('error', `提交失败，请稍后再试`);
        resType = FrontCallbackResultType.error;
        this.handleCallBack(resType);
      });
    } else {
      // 批量删除
      this.modalService.confirm({
        nzTitle: `确认删除选中的 ${selectedItemCount} 个${targetText}吗？`,
        nzContent: null,
        nzOnCancel: () => {
          this.operating[type] = false;
        },
        nzOnOk: () => {
          this.batchDeleteService(submittedData).subscribe(res => {
            if (res.status === 200) {
              this.messageService.create('success', `成功${msgText} ${selectedItemCount} 个${targetText}`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.create('warning', `${msgText}失败，请稍后再试`);
              resType = FrontCallbackResultType.error;
            }
            this.operating[type] = false;
            this.handleCallBack(resType);
          }, () => {
            this.operating[type] = false;
            this.messageService.create('error', `${msgText}失败，请稍后再试`);
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });
    }
  }

  /**
   * 批量展期操作管理，包括更新和删除
   * 注意：更新和删除依据的都是对象的 id 字段
   * @param type 批量操作的类型，在组件的属性变量 operating 中定义
   * @param targetText 操作的对象，用于在提示信息中显示
   * @param targetKey? 操作对象的主键键名，默认为 id
   * @param submittedKey? POST 对象的键名，默认为 ids
   */
  operateDelayMultipleData(type: string, targetText: string, targetKey: string = 'id', submittedKey: string = 'ids'): void {
    this.operating[type] = true;
    const submittedData = {[submittedKey]: []};
    const newProp: { name: string, value } = {name: null, value: null};
    let msgText: string = null;
    let selectedItemCount = 0;
    switch (type) {
      case 'toApproved': {
        newProp.name = 'approvalStatus';
        newProp.value = 80;
        msgText = '同意';
        break;
      }
      case 'toDenied': {
        newProp.name = 'approvalStatus';
        newProp.value = 75;
        msgText = '拒绝';
        break;
      }
      case 'toChecking': {
        newProp.name = 'approvalStatus';
        newProp.value = 70;
        msgText = '撤销';
        break;
      }
      case 'onDelete': {
        msgText = '删除';
        break;
      }
      default: {
        this.operating[type] = false;
        return;
      }
    }
    // 获取选中项和需更新项
    this.dataList.forEach((data): void => {
      if (data.checked) {
        selectedItemCount += 1;
        if (data[newProp.name] !== newProp.value || !newProp.name) {
          submittedData[submittedKey].push(data[targetKey]);
        }
      }
    });

    this.clearSelection();

    // 校验
    const len = submittedData[submittedKey].length;
    if (len === 0) {
      if (selectedItemCount === 0) {
        this.messageService.create('warning', `请选择要操作的${targetText}`);
      } else if (newProp.name) {
        this.messageService.create('warning', `所有选中${targetText}都已处于 ${msgText} 状态`);
      }
      this.operating[type] = false;
      return;
    }

    let resType: FrontCallbackResultType;
    if (newProp.name) {
      // 批量更新
      submittedData[newProp.name] = newProp.value;
      this.batchUpdateService(submittedData).subscribe(res => {
        if (res.status === 200) {
          this.messageService.create('success', `成功${msgText} ${len} 个${targetText}${selectedItemCount === len ? '' :
            `，其余 ${selectedItemCount - len} 个${targetText}更新前已处于${msgText}状态`
          }`);
          resType = FrontCallbackResultType.refresh;
        } else {
          this.messageService.create('warning', `提交失败，请稍后再试`);
          resType = FrontCallbackResultType.error;
        }
        this.operating[type] = false;
        this.handleCallBack(resType);
      }, () => {
        this.operating[type] = false;
        this.messageService.create('error', `提交失败，请稍后再试`);
        resType = FrontCallbackResultType.error;
        this.handleCallBack(resType);
      });
    } else {
      // 批量删除
      this.modalService.confirm({
        nzTitle: `确认删除选中的 ${selectedItemCount} 个${targetText}吗？`,
        nzContent: null,
        nzOnCancel: () => {
          this.operating[type] = false;
        },
        nzOnOk: () => {
          this.batchDeleteService(submittedData).subscribe(res => {
            if (res.status === 200) {
              this.messageService.create('success', `成功${msgText} ${selectedItemCount} 个${targetText}`);
              resType = FrontCallbackResultType.refresh;
            } else {
              this.messageService.create('warning', `${msgText}失败，请稍后再试`);
              resType = FrontCallbackResultType.error;
            }
            this.operating[type] = false;
            this.handleCallBack(resType);
          }, () => {
            this.operating[type] = false;
            this.messageService.create('error', `${msgText}失败，请稍后再试`);
            resType = FrontCallbackResultType.error;
            this.handleCallBack(resType);
          });
        }
      });
    }
  }

  /**
   * 创建基于 Component 的模态框，不需要重写，而是重写 this.getModalOpt() 即可。
   * @param type 模态框类型，具体配置在重写 this.getModalOpt() 时定义
   * @param data 注入子组件的数据
   */
  createComponentModal(type: string, data?: any): void {
    // 默认配置
    let modalOpt: FrontModalOptModel = {
      iconOpt: {nzType: null, nzTheme: 'outline'}, /* 默认设 nzTheme='outline' */
      titleText: null,
      width: 520, /* 默认值 520 */
      content: null,
      footer: null, /* 默认值 null */
      onClose: this.handleCallBack,
      maskClosable: false /* 默认值设为 false，点击蒙层不关闭 */
    };

    // 通过自定义的 this.getModalOpt() 覆盖默认配置
    modalOpt = this.getModalOpt(type, modalOpt);
    const defalutTitle = `
      <div class="uc-modal-title-wrapper"><span class="uc-form-header">${modalOpt.titleText}</span></div>
    `;

    // 同步更新 View 中的 ng-template#modalHeader
    this.modalIconOpt = modalOpt.iconOpt;
    this.modalTitleText = modalOpt.titleText;

    // 创建模态框
    const modal = this.modalService.create({
      /* nzTitle - 若无 ng-template，则直接用 titleText，若 titleText 为空则不显示标题 */
      nzTitle: modalOpt.iconOpt.nzType && this.modalHeaderTpl || modalOpt.titleText && defalutTitle,
      nzContent: modalOpt.content, /* 内容组件名 */
      nzComponentParams: {
        info: data ? data : null, /* 传入内容组件的参数 */
      },
      nzFooter: modalOpt.footer,
      nzWidth: modalOpt.width, /* 模态框宽度，默认 520 */
      nzWrapClassName: 'uc-modal', /* 用于重写样式 */
      nzMaskClosable: modalOpt.maskClosable, /* 点击蒙层是否关闭，默认值设为 false */
    });

    // 订阅该模态框的关闭事件
    if (modalOpt.onClose) {
      modal.afterClose.subscribe(modalOpt.onClose);
    }

    // 附1：在子组件视图中需要增加 ng-template 通用模板如下
    /*
     <ng-template #modalHeader>
        <div class="uc-modal-title-wrapper">
            <span class="uc-form-header">
              <i nz-icon [nzType]='modalIconOpt.nzType' [nzTheme]='modalIconOpt.nzTheme'
                 [spin]="modalIconOpt.spin" [iconfont]="modalIconOpt.iconfont"></i>&nbsp;&nbsp;{{modalTitleText}}
            </span>
        </div>
      </ng-template>
     */
    // 附1 END.
  }

  /**
   * 过滤
   * @param key
   * @param value
   */


  updateFilter(key: string, value: string): void {
    this.filterKey = key;
    this.filterValue = value;
    this.searchData(true);
  }

  /**
   * 排序
   */
  sort(sort: { key: string, value: string }) {
    this.sortKey = sort.value !== null ? sort.key : null;
    this.sortValue = sort.value === 'ascend' ? true : false;
    this.searchData(true);
  }

  exportAsFile() {

  }
}

export class DataTabListBaseComponent  extends DataListBaseComponent {
  tabs = [];
  currentTab = 0;
  currentTabType: number;
  tabFitlers: Filter[];
  tabFilterType: string;

  approvalCount: number;

  constructor(
    private authservice: UcAuthService,
    private crypto2: CryptoJSService,
    messageService: NzMessageService,
    modalService: NzModalService
  ) {
    super(authservice, crypto2, messageService, modalService);
  }

  /**
   * Tab选择
   */
  defaultSearch() {
    this.currentTab = 0;
    this.tabFiltersChange();
    this.searchData(true);
  }

  selectTab(event: any) {
    this.currentTab = event.index;
    this.defaultFilter();
    this.tabFiltersChange();
    this.searchData(true);
  }

  tabFiltersChange() {
    this.tabFitlers = [];
    this.currentTabType = this.tabs[this.currentTab]['type'];
    if (this.tabs[this.currentTab]['type'] === 1) {
      if (this.sysAdmin || this.rootAdmin) {
        this.tabFilterType = 'And';
        this.tabFitlers = [
        ];
      } else {
        this.tabFilterType = 'And';
        this.tabFitlers = [
          {key: 'traderId', type: 'Eq', value: this.currentUser && this.currentUser.id}
        ];
      }
    }
  }

  /**
   * 请求一页数据
   * @param reset:Boolean 需要重置页码时传入 true
   */
  searchData(reset: boolean = false): void {
    this.isSpinning = true;
    const defaultFilters = {
      resetFilters: (key: string, type: string, value: any) => {
        if (this.filters.length > 0) {
          const include = this.filters.map((f, num) => {
            if (f.key === key) {
              if (value !== null && value !== '') {
                f.value = value;
              } else {
                this.filters.splice(num, 1);
              }
            }
            return f.key === key;
          });

          if (!include.includes(true)) {
            if (value !== null && value !== '') {
              this.filters.push({key: key, type: type, value: value});
            }
          }
        } else {
          if (value !== null && value !== '') {
            this.filters.push({key: key, type: type, value: value});
          }
        }
      }
    };

    if (reset) {
      this.pageIndex = 1;
    }

    const body: QueryModelPagination = {
      pageSize: this.pageSize,
      pageIndex: this.pageIndex
    };
    const searchFilters = [];

    if (this.searchValue) {
      if (this.searchKey === 'companyId') {
        searchFilters.push({key: this.searchKey, type: FilterType[FilterType.Eq], value: this.searchValue});
      } else {
        searchFilters.push({key: this.searchKey, type: FilterType[FilterType.Like], value: this.searchValue});
      }
    }

    if (this.filterKey) {
      if (this.filterKey === 'status' || this.filterKey === 'enabled' || this.filterKey === 'isPay'
        || this.filterKey === 'isMake' || this.filterKey === 'code' || this.filterKey === 'type'
        || this.filterKey === 'operation') {
        defaultFilters.resetFilters(this.filterKey, FilterType[FilterType.Eq], this.filterValue);
      } else {
        defaultFilters.resetFilters(this.filterKey, FilterType[FilterType.Like], this.filterValue);
      }
    }

    if (this.sortKey) {
      body.sort = {
        key: this.sortKey,
        ascending: this.sortValue
      };
    }

    if (this.tabFilterType === 'Or') {
      body.filtersType = 'Or';

      this.tabFitlers.map(f => {
        f.filters = [
          ...f.filters,
          ...searchFilters,
          ...this.filters
        ];
      });
    } else {
      body.filtersType = 'And';
      this.tabFitlers = [
        ...this.tabFitlers,
        ...searchFilters,
        ...this.filters
      ];
    }

    body.filters = [
      ...this.tabFitlers
    ];

    this.getDataListObservable(body);
  }

  getDataListObservable(body: QueryModelPagination) {
    this.getDataListService(body).subscribe(res => {
      if (res.status === 200) {
        const preparedData = this.prepareData(res.data);
        this.total = res.totalItems;
        this.dataList = preparedData;
        this.getApprovalCount();
        this.isSpinning = false;
      } else {
        this.isSpinning = false;
        this.messageService.warning(`${this.getTargetText}获取失败，请稍后再试`);
      }
    }, () => {
      this.isSpinning = false;
      this.messageService.error(`${this.getTargetText}获取失败，请稍后再试`);
    });
  }

  defaultFilter(): void {
    this.searchValue = null;
    this.filterValue = null;
    this.filterKey = null;
    this.filters = [];
    this.startLendTime = null;
    this.endLendTime = null;
  }

  /**
   * 获取对应表数据的数据条数
   */
  getApprovalCount() {}

  /**
   * 清空搜索状态并刷新列表
   */
  refresh(): void {
    this.searchValue = null;
    this.filterValue = null;
    this.filterKey = null;
    this.filters = [];
    this.startLendTime = null;
    this.endLendTime = null;
    this.tabFiltersChange();
    this.searchData(true);
  }

  /**
   * 搜索
   */
  search(): void {
    this.tabFiltersChange();
    this.searchData(true);
  }


  updateFilter(key: string, value: string): void {
    this.filterKey = key;
    this.filterValue = value;
    this.tabFiltersChange();
    this.searchData(true);
  }

  /**
   * 排序
   */
  sort(sort: { key: string, value: string }) {
    this.sortKey = sort.value !== null ? sort.key : null;
    this.sortValue = sort.value === 'ascend' ? true : false;
    this.tabFiltersChange();
    this.searchData(true);
  }
}
