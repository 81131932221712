<div *ngIf="chartForType !== 'table'">
<!--  <button (click)="addSeriesData()">数据测试</button>-->
<!--  <input type='number' style='width: 300px' [(ngModel)]="testData">-->
  <nz-spin [nzSpinning]="isLoading">
    <div #container [attr.id]="id" [ngStyle]="{height: ucHeight + 'px'}"></div>
  </nz-spin>
</div>
<div *ngIf="chartForType === 'table'">
  <div [ngStyle]="{minHeight: ucHeight + 'px'}">
    <nz-table
      #ucTable
      [nzLoading]="isLoading"
      [nzBordered]="TCD.chart_config.tableOptions.border"
      [nzShowPagination]="TCD.chart_config.tableOptions.pagination"
      [nzShowSizeChanger]="TCD.chart_config.tableOptions.showSizeChanger"
      [nzHideOnSinglePage]="TCD.chart_config.tableOptions.hideOnSinglePage"
      [nzFooter]="TCD.chart_config.tableOptions.footer"
      [nzTitle]="TCD.chart_config.tableOptions.title"
      [nzSize]="TCD.chart_config.tableOptions.size"
      [nzData]="tableData"
      [nzScroll]="{ x: TCD.chart_config.tableOptions.x ?  TCD.chart_config.tableOptions.x + 'px' : null,
                    y: TCD.chart_config.tableOptions.y ?  TCD.chart_config.tableOptions.y + 'px' : null}"
      [nzNoResult]="'暂无数据'"
      [nzFrontPagination]="false"
      [nzTotal]="total"
      [(nzPageIndex)]="pageIndex"
      [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="searchData(true)"
      (nzPageSizeChange)="searchData(true)">
      <thead>
      <tr>
        <th *ngFor="let field of TCD.chart_config.tableOptions.field"
            [nzShowSort]="TCD.chart_config.tableOptions.sortFields.indexOf(field.fieldName) > -1" nzSortKey="{{field.fieldName}}"
            (nzSortChange)="sortFunc(field.fieldName, $event)"
            [nzCustomFilter]="TCD.chart_config.tableOptions.filterFields.indexOf(field.fieldName) > -1"
            [ngStyle]="field.thStyle"
        >
          {{field.name}}
          <span
            nz-th-extra
            class="ant-table-filter-icon"
            nz-dropdown
            #dropdown="nzDropdown"
            [nzDropdownMenu]="menu"
            [class.ant-table-filter-open]="dropdown.nzVisible"
            nzTrigger="click"
            nzPlacement="bottomRight"
            [nzClickHide]="false"
            nzTableFilter
            (click)="nzFilterChange()"
            [hidden]="TCD.chart_config.tableOptions.filterFields.indexOf(field.fieldName) < 0"
          >
            <i nz-icon nzType="search"></i>
          </span>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <div nz-menu>
              <div nz-menu-item class="custom-filter-dropdown">
                <input type="text" style="width: 130px;margin-right: 5px" nz-input placeholder="Search..."
                       [(ngModel)]="tableSearchValue" (keyup.enter)="updateFilter(field.fieldName, tableSearchValue)">
                <button nz-button [nzType]="'primary'" (click)="updateFilter(field.fieldName, tableSearchValue)">搜索</button>
              </div>
            </div>
          </nz-dropdown-menu>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of ucTable.data">
        <td *ngFor="let field of TCD.chart_config.tableOptions.field" [ngStyle]="field.tdStyle">{{data[field.fieldName]}}</td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
