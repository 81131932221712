import {Injectable} from '@angular/core';
import {ChartOptions, ChartSetting, ChartTemplate, TEMPLATE, UcDataConfigUpdate} from './ucChart.model';
import {HttpService} from './http.service';
import {ProcessService} from './process.service';
import {NzMessageService} from 'ng-zorro-antd';
import {cloneDeep, UcEnvironmentService} from '../../../core/common';
import {BaseService} from './base.service';
import {UcChartComponent} from './ucChart.component';
import {UcWebSocketService} from './websocket.service';

@Injectable()
export class DynamicService {
  public interval;
  private chartTemplate: ChartTemplate;

  constructor(
    private request: HttpService,
    private wsService: UcWebSocketService,
    private _process: ProcessService,
    private environmentService: UcEnvironmentService,
    private messageService: NzMessageService,
    private _base: BaseService
  ) {}

  dynamicChart(TCD: ChartTemplate, component): void {
    this.chartTemplate = TCD;
    if (TCD.chart_config.chartTmp.type !== 'static') {
      const protocol = TCD.data_config.update_source.protocol;
      if (protocol === 'http' || protocol === 'https') {
        this.updateRequest(TCD.data_config.update_source, TCD.chart_config.chartTmp, component);
      } else if (protocol === 'ws' || protocol === 'wss') {
        this.websocketRequest(TCD.data_config.update_source, TCD.chart_config.chartTmp, component);
      }
    }
  }

  /**
   * http=> update
   * @param {UcDataConfigUpdate} updateSource
   */
  updateRequest(updateSource: UcDataConfigUpdate, chartTmp: ChartSetting, component) {
    const intervalDate: number = updateSource.interval;
    const interval = setInterval(() => {
      this.request.intervalGetData(updateSource.url, updateSource.count).subscribe(
        res => {
          updateSource.data = res['data'];

          if (res['fields'] && res['fields'].length !== 0) {
            updateSource.fields = res['fields'];
          } else {
            for (const i in res['data'][0]) {
              updateSource.fields.push({
                fieldName: i,
                fieldType: 'ws'
              });
            }
          }
          component.chartDataTracking.emit(res['data']);

          const source_data_tmp = this.handleUpdateData(updateSource, this.chartTemplate.chart_for.type, component.datetypeString); // 将json数据数组转化为列数据数组
          if (updateSource.process && updateSource.process.length !== 0) {
            this._process.handleProcess(updateSource.process, source_data_tmp);
          }
          // source重新获取数据
          updateSource.fields = source_data_tmp.data_field;
          updateSource.data = source_data_tmp.data_row;


          if (this.chartTemplate.chart_for.type === 'table') {
            if (updateSource.data && updateSource.data.length > 0) {
              //  data_table 转化Date类型的时间
              source_data_tmp.data_field.forEach((item) => {
                // tslint:disable-next-line:max-line-length
                if ((item.type === 'Date' || item.type === 'date' || item.type === 'datetime' || item.fieldName === 'alertTime' || item.fieldName === 'orderTime' || item.fieldName === 'lastUpdateTime')
                ) {
                  updateSource.data.forEach((tableData, num) => {
                    // tslint:disable-next-line:max-line-length
                    updateSource.data[num][item.fieldName] = this._base.Format(new Date(this._base.xyVal(tableData[item.fieldName])), component.datetypeString);
                  });
                }
              });

              // 限制data_table中Number类型的小数有效位
              source_data_tmp.data_field.forEach((item, index) => {
                if (item.type === 'Number') {
                  updateSource.data.forEach((tableData, num) => {
                    // tslint:disable-next-line:max-line-length
                    updateSource.data[num][item.fieldName] = this._process.formatNumber(this._base.isNum_(tableData[item.fieldName], 2));
                  });
                }
              });

              // tslint:disable-next-line:max-line-length
              if (this.chartTemplate.chart_config.tableOptions.dateField && this.chartTemplate.chart_config.tableOptions.dateField !== null &&
                this.chartTemplate.chart_config.tableOptions.dateField !== '') {
                updateSource.data.forEach((tableData, num) => {
                  updateSource.data[num][this.chartTemplate.chart_config.tableOptions.dateField] =
                    // tslint:disable-next-line:max-line-length
                    this._base.Format(new Date(this._base.xyVal(tableData[this.chartTemplate.chart_config.tableOptions.dateField])), component.datetypeString);
                });
              }

              this.tableUpdate(component, updateSource.data, chartTmp);
            }
          } else {
            // 限制data_table中Number类型的小数有效位
            source_data_tmp.data_field.forEach((item, index) => {
              if (item.type === 'Number') {
                updateSource.data.forEach((tableData, num) => {
                  // tslint:disable-next-line:max-line-length
                  updateSource.data[num][item.fieldName] = this._base.isNum_(tableData[item.fieldName], 4);
                });
              }
            });
            // 最新时间
            const xAxisName = this.chartTemplate.chart_config.chartOptions.xAxis.name;
            const newDate = res['data'][0][xAxisName];
            if (updateSource.data && updateSource.data.length > 0) {
              this.chartUpdate(component, component.chart, updateSource.data, xAxisName, chartTmp, newDate);
            }
          }
        },
        error => {
          console.log('updateHttp错误提示', error);
        }
      );
    }, intervalDate);
    component.interval = interval;
  }

  /**
   * webSocket => update
   * @param {UcDataConfigUpdate} updateSource
   */
  websocketRequest(updateSource: UcDataConfigUpdate, chartTmp: ChartSetting, component: UcChartComponent) {
    if ((!updateSource.body || updateSource.body === null) && component.TCD.data_config.sources[0].body.filters || component.TCD.data_config.sources[0].body.filter) {
      if (component.TCD.data_config.sources[0].body.filters) {
        updateSource.body = {
          filters: component.TCD.data_config.sources[0].body.filters
        };
      } else if (component.TCD.data_config.sources[0].body.filter) {
        updateSource.body = {
          filter: component.TCD.data_config.sources[0].body.filter
        };
      }

      if (component.TCD.data_config.sources[0].body.filtersType) {
        updateSource.body.filtersType = component.TCD.data_config.sources[0].body.filtersType;
      }
    }

    this.wsService.createObservableSocket(updateSource, component)
      .subscribe(
        res => {
          this.wsService.reset();
          if (res !== 'keep alive') {
            const response = JSON.parse(res);
            const viewModel = (response.data.code && response.data.status) ? response.data : response;

            if (viewModel.status === 200 && JSON.stringify(viewModel.data) !== '{}') {
              if (!viewModel.data.length || viewModel.data.length === 0) {
                if (viewModel.data.length === 0) {
                  return false;
                } else {
                  viewModel.data = [viewModel.data];
                }
              } else {
                viewModel.data = [...viewModel.data];
              }

              if (!viewModel.fields || viewModel.fields.length === 0) {
                viewModel.fields = [];
                for (const i in viewModel['data'][0]) {
                  if (this.chartTemplate.dataTmp.data_fields.indexOf(i) > -1) {
                    this.chartTemplate.dataTmp.data_dic.forEach((dic) => {
                      if (i === dic['fieldName']) {
                        viewModel.fields.push({
                          fieldName: i,
                          type: dic.fieldType
                        });
                      }
                    });
                  } else {
                    viewModel.fields.push({
                      fieldName: i,
                      type: typeof (viewModel['data'][0][i])
                    });
                  }
                }
              }

              component.chartDataTracking.emit(viewModel['data']);
              updateSource.fields = viewModel['fields'];
              updateSource.data = viewModel['data'];

              const source_data_tmp = this.handleUpdateData(updateSource, this.chartTemplate.chart_for.type, component.datetypeString); // 将json数据数组转化为列数据数组
              if (updateSource.process && updateSource.process.length !== 0) {
                this._process.handleProcess(updateSource.process, source_data_tmp);
              }
              // source重新获取数据
              updateSource.fields = source_data_tmp.data_field;
              updateSource.data = source_data_tmp.data_row;

              if (this.chartTemplate.chart_for.type === 'table') {
                if (updateSource.data && updateSource.data.length > 0) {
                  //  data_table 转化Date类型的时间
                  source_data_tmp.data_field.forEach((item) => {
                    // tslint:disable-next-line:max-line-length
                    if ((item.type === 'Date' || item.type === 'date' || item.type === 'datetime' || item.fieldName === 'alertTime' || item.fieldName === 'orderTime' || item.fieldName === 'lastUpdateTime')
                    ) {
                      updateSource.data.forEach((tableData, num) => {
                        // tslint:disable-next-line:max-line-length
                        updateSource.data[num][item.fieldName] = this._base.Format(new Date(this._base.xyVal(tableData[item.fieldName])), component.datetypeString);
                      });
                    }
                  });

                  // 限制data_table中Number类型的小数有效位
                  source_data_tmp.data_field.forEach((item, index) => {
                    if (item.type === 'Number') {
                      updateSource.data.forEach((tableData, num) => {
                        // tslint:disable-next-line:max-line-length
                        updateSource.data[num][item.fieldName] = this._process.formatNumber(this._base.isNum_(tableData[item.fieldName], 2));
                      });
                    }
                  });

                  // tslint:disable-next-line:max-line-length
                  if (this.chartTemplate.chart_config.tableOptions.dateField && this.chartTemplate.chart_config.tableOptions.dateField !== null &&
                    this.chartTemplate.chart_config.tableOptions.dateField !== '') {
                    updateSource.data.forEach((tableData, num) => {
                      updateSource.data[num][this.chartTemplate.chart_config.tableOptions.dateField] =
                        // tslint:disable-next-line:max-line-length
                        this._base.Format(new Date(this._base.xyVal(tableData[this.chartTemplate.chart_config.tableOptions.dateField])), component.datetypeString);
                    });
                  }

                  this.tableUpdate(component, updateSource.data, chartTmp);
                }
              } else {
                // 限制data_table中Number类型的小数有效位
                source_data_tmp.data_field.forEach((item, index) => {
                  if (item.type === 'Number') {
                    updateSource.data.forEach((tableData, num) => {
                      // tslint:disable-next-line:max-line-length
                      updateSource.data[num][item.fieldName] = this._base.isNum_(tableData[item.fieldName], 4);
                    });
                  }
                });
                // 最新时间
                const xAxisName = this.chartTemplate.chart_config.chartOptions.xAxis.name;
                const newDate = viewModel['data'][0][xAxisName];
                if (updateSource.data && updateSource.data.length > 0) {
                  this.chartUpdate(component, component.chart, updateSource.data, xAxisName, chartTmp, newDate);
                }
              }
            } else {
              console.log(component.title, viewModel.message);
              if (this.environmentService.production) {
                console.log(viewModel.description);
              }
            }
          }
        },
        error => {
          console.log(component.ChartInfo.name + '：ws错误提示', error);
          this.wsService.webSocketReconnect(updateSource, component);
        }
      );
  }

  chartUpdate(component: UcChartComponent, chart, data, xAxisName, chartTmp: ChartSetting, newDate?) {
    switch (chartTmp.type) {
      case 'dynamic-update':
        this.seriesUpdate(component, chart, data, xAxisName, chartTmp, newDate);
        break;
      case 'dynamic-addpoint':
        this.seriesAddpoint(component, chart, data, xAxisName);
        break;
    }
  }

  /**
   * 更新点类型
   * @param chart
   * @param data
   * @param xAxisName
   * @param chartTmp
   * @param newDate
   */
  seriesUpdate(component: UcChartComponent, chart, data, xAxisName, chartTmp, newDate) {
    // x轴是是否为时间轴判断
    const isTimeX = this._base.isT(xAxisName);
    // x轴为时间轴 数据返回
    if (isTimeX) {
      newDate = this._base.xyVal(newDate);
    }

    const ut = this._base.isNum(newDate) ? this.chartUpdateTime(chartTmp, newDate) : newDate;
    if (chart && chart.series && chart.series.length !== 0) {
      chart.series.map((serie) => {
        serie.data.map( (point) => {
          if (point.x === ut) {
            point.update({y: data[0][serie.userOptions.dataName]});
            component.serieUpdate.emit({
              serie: serie,
              updateData: [newDate, data[0][serie.userOptions.dataName]]
            });
          }
        });
      });
    } else {
      const series = this.getChartTemplate(data[0], component.TCD)[0];
      series.data = [[data[0][xAxisName], data[0][series.dataName]]];
      chart.addSeries(series);
    }
  }

  /**
   * 添加点类型
   * @param chart
   * @param data
   * @param xAxisName
   */
  seriesAddpoint(component: UcChartComponent, chart, data, xAxisName) {
    // x轴是是否为时间轴判断
    const isTimeX = this._base.isT(xAxisName);
    // x轴为时间轴 数据返回
    if (isTimeX) {
      data[0][xAxisName] = this._base.xyVal(data[0][xAxisName]);
    }
    if (chart && chart.series && chart.series.length !== 0) {
      const dataInSeries = chart.series.map((serie) => {
        const ylength = serie.yData.length - 1;
        // 获取最后一个不为空的数据的位置
        const yDataLength = this.isNullData(serie, ylength);
        const datainserie = [];
        for (const key in data[0]) {
          datainserie.push((key === serie.userOptions.dataName));

          if (ylength > 0) {
            if (key === serie.userOptions.dataName) {
              if (data[0][xAxisName] > serie.xData[yDataLength] ) {
                serie.addPoint([data[0][xAxisName], data[0][serie.userOptions.dataName]]);

                component.serieUpdate.emit({
                  serie: serie,
                  updateData: [data[0][xAxisName], data[0][serie.userOptions.dataName]]
                });
              } else if (data[0][xAxisName] === serie.xData[yDataLength] ) {
                if (serie.data.length > 0) {
                  serie.data[yDataLength].update({y: data[0][serie.userOptions.dataName]});

                  component.serieUpdate.emit({
                    serie: serie,
                    updateData: [data[0][xAxisName], data[0][serie.userOptions.dataName]]
                  });
                }

              }
            }
          } else {
            serie.addPoint([data[0][xAxisName], data[0][serie.userOptions.dataName]]);
          }
        }

        return datainserie.includes(true);
      });

      if (!dataInSeries.includes(true))  {
        const series = this.getChartTemplate(data[0], component.TCD)[0];
        series.data = [[data[0][xAxisName], data[0][series.dataName]]];
        chart.addSeries(series);
      }
    } else {
      const series = this.getChartTemplate(data[0], component.TCD)[0];
      series.data = [[data[0][xAxisName], data[0][series.dataName]]];
      chart.addSeries(series);
    }
  }

  /**
   * 获取更新点类型的更新点的x轴的值
   * @param chartTmp
   * @param newDate
   */
  chartUpdateTime(chartTmp: ChartSetting, newDate) {
    const utime = chartTmp.chartUpdateTime;
    let ut;
    switch (chartTmp.chartUpdateTimeType) {
      case 'min':
        ut = this.getUpdateTime(newDate, utime * 60 * 1000);
        break;
      case 'hour':
        ut = this.getUpdateTime(newDate, utime * 60 * 60 * 1000);
        break;
      case 'day':
        ut = this.getUpdateTime(newDate, utime * 24 * 60 * 60 * 1000);
        break;
      case 'week':
        ut = this.getUpdateTime(newDate, utime * 7 * 24 * 60 * 60 * 1000);
        break;
    }

    return ut;
  }

  getUpdateTime(newDate, d) {
    if (typeof(newDate) === 'string') {
      newDate = Number(newDate);
    }
    const ut = newDate % d === 0 ? newDate : ((d - newDate % d) + newDate);
    return ut;
  }

  /**
   * 动态表格
   * @param TCD
   * @param component
   */
  tableUpdate(component: UcChartComponent, data, chartTmp: ChartSetting): void {
    for (const i in data) {
      if (this._base.isNum(data[i])) {
        data[i] = parseFloat(this._base.isNum_(data[i], 2));
      }
    }

    if (component.pageIndex === 1) {
      switch (chartTmp.type) {
        case 'dynamic-update':
          this.tableUpdateData(component, data);
          break;
        case 'dynamic-addpoint':
          this.tableAddData(component, data);
          break;
      }
    }
  }

  tableAddData(component: UcChartComponent, data) {
    component.total += 1;
    component.tableData = [
      data[0],
      ...component.tableData
    ];
    if (component.tableData.length > component.pageSize) {
      component.tableData = component.tableData.filter((item, index) => index !== component.tableData.length - 1);
    }

    component.tableDefaultData = [...cloneDeep(component.tableData)];
  }

  tableUpdateData(component: UcChartComponent, data) {
    if (component.tableData.length > 0) {
      // updateTableField是否存在，若存在根据updateTableField字段的值来显示最新数据
      if (component.TCD.chart_config.tableOptions.updateTableField &&
        component.TCD.chart_config.tableOptions.updateTableField !== null &&
        component.TCD.chart_config.tableOptions.updateTableField !== '') {
        component.updateTableField = component.TCD.chart_config.tableOptions.updateTableField;
      }

      // updateTableField存在走更新，不存在直接append
      if (component.updateTableField && component.updateTableField !== null && component.updateTableField !== '') {
        const idIncludes = component.tableData.map((d, num) => { // idIncludes 用来存储updateTableField是否存在，或新旧值是否相等的boolean
          if (d[component.updateTableField] && data[0][component.updateTableField]) { // 判断updateTableField字段的值是否存在
            if (d[component.updateTableField] === data[0][component.updateTableField]) { // 判断updateTableField字段，新旧值是否相等
              // 判断tableField字段的值是否存在，且是否为判断值，true: 删除该条数据
              if (
                (
                  data[0][component.tableField] &&
                  (data[0][component.tableField] === 0 || data[0][component.tableField] === 'null' ||
                    data[0][component.tableField] === 'None' || data[0][component.tableField] === null ||
                    data[0][component.tableField] === '空' || data[0][component.tableField] === '无' ||
                    data[0][component.tableField] === undefined)) ||
                (
                  data[0][component.TCD.chart_config.tableOptions.tableNullField] &&
                  (data[0][component.TCD.chart_config.tableOptions.tableNullField] === 0 ||
                    data[0][component.TCD.chart_config.tableOptions.tableNullField] === 'null' ||
                    data[0][component.TCD.chart_config.tableOptions.tableNullField] === 'None' ||
                    data[0][component.TCD.chart_config.tableOptions.tableNullField] === null ||
                    data[0][component.TCD.chart_config.tableOptions.tableNullField] === '空' ||
                    data[0][component.TCD.chart_config.tableOptions.tableNullField] === '无' ||
                    data[0][component.TCD.chart_config.tableOptions.tableNullField] === undefined)
                )
              ) {
                component.TCD.chart_config.tableOptions.field.splice(num, 1);
              } else {
                // 判断新数据的时间字段值是否大于等于旧数据的时间字段值
                if (component.TCD.chart_config.tableOptions.dateField && component.TCD.chart_config.tableOptions.dateField !== null &&
                  component.TCD.chart_config.tableOptions.dateField !== '') {
                  if (d[component.TCD.chart_config.tableOptions.dateField] &&
                    d[component.TCD.chart_config.tableOptions.dateField] <= data[0][component.TCD.chart_config.tableOptions.dateField]) {
                    component.TCD.chart_config.tableOptions.field.forEach(field => {
                      d[field.fieldName] = data[0][field.fieldName];
                    });
                  }
                } else {
                  if (
                    (d['timestamp'] && d['timestamp'] <= data[0]['timestamp']) ||
                    (d['trade_time'] && d['trade_time'] <= data[0]['trade_time']) ||
                    (d['trade_date'] && d['trade_date'] <= data[0]['trade_date']) ||
                    (d['lastUpdateTime'] && d['lastUpdateTime'] <= data[0]['lastUpdateTime']) ||
                    (d['dealTime'] && d['dealTime'] <= data[0]['dealTime']) ||
                    (d['alertTime'] && d['alertTime'] <= data[0]['alertTime'])
                  ) {
                    component.TCD.chart_config.tableOptions.field.forEach(field => {
                      d[field.fieldName] = data[0][field.fieldName];
                    });
                  }
                }
              }
            }
            return d[component.updateTableField] === data[0][component.updateTableField];
          } else {
            return false;
          }
        });

        // 判断tableData数组中是否存在updateTableField值与新数据updateTableField值相同的数据对象
        if (!this._base.isIncludeField(idIncludes, true)) {
          component.total += 1;
          component.tableData = [
            data[0],
            ...component.tableData
          ];
          if (component.tableData.length > component.pageSize) {
            component.tableData = component.tableData.filter((item, index) => index !== component.tableData.length - 1);
          }
        }
      } else {
        // updateTableField不存在，直接append数据
        if (component.TCD.chart_config.tableOptions.dateField && component.TCD.chart_config.tableOptions.dateField !== null &&
          component.TCD.chart_config.tableOptions.dateField !== '') {
          if (data[0][component.TCD.chart_config.tableOptions.dateField] && this.isNewDate(component.tableData[0][component.TCD.chart_config.tableOptions.dateField], data[0][component.TCD.chart_config.tableOptions.dateField])) {
            component.total += 1;
            component.tableData = [
              data[0],
              ...component.tableData
            ];
            if (component.tableData.length > component.pageSize) {
              component.tableData = component.tableData.filter((item, index) => index !== component.tableData.length - 1);
            }
          }
        } else if (
          (data[0]['date'] && this.isNewDate(component.tableData[0]['date'], data[0]['date']))
          || (data[0]['datetime'] && this.isNewDate(component.tableData[0]['datetime'], data[0]['datetime']))
          || (data[0]['Date'] && this.isNewDate(component.tableData[0]['Date'], data[0]['Date']))
          || (data[0]['时间'] && this.isNewDate(component.tableData[0]['date'], data[0]['时间']))
          || (data[0]['timestamp'] && this.isNewDate(component.tableData[0]['timestamp'], data[0]['timestamp']))
          || (data[0]['lastUpdateTime'] && this.isNewDate(component.tableData[0]['lastUpdateTime'], data[0]['lastUpdateTime']))
          || (data[0]['dealTime'] && this.isNewDate(component.tableData[0]['dealTime'], data[0]['dealTime']))
          || (data[0]['alertTime'] && this.isNewDate(component.tableData[0]['alertTime'], data[0]['alertTime']))
        ) {
          component.total += 1;
          component.tableData = [
            data[0],
            ...component.tableData
          ];
          if (component.tableData.length > component.pageSize) {
            component.tableData = component.tableData.filter((item, index) => index !== component.tableData.length - 1);
          }
        } else {
          component.total += 1;
          component.tableData = [
            data[0],
            ...component.tableData
          ];
          if (component.tableData.length > component.pageSize) {
            component.tableData = component.tableData.filter((item, index) => index !== component.tableData.length - 1);
          }
        }
      }
    } else {
      // 原表格数据为空,直接添加新数据

      component.total += 1;
      component.tableData = [
        data[0],
        ...component.tableData
      ];
      if (component.tableData.length > component.pageSize) {
        component.tableData = component.tableData.filter((item, index) => index !== component.tableData.length - 1);
      }
    }

    component.tableDefaultData = [...cloneDeep(component.tableData)];
  }

  isNewDate(oldDate, newDate) {
    const isNewDate = Math.abs(this._base.xyVal(newDate) - this._base.xyVal(oldDate)) <= 5000;

    return isNewDate;
  }

  isNullData(serie, len) {
    if (serie.yData[len] === null || serie.yData[len] === 'NaN' || serie.yData[len] === 'undefined') {
      len = len - 1;
      return this.isNullData(serie, len);
    }
    return len;
  }

  // udpate_source数据转化为列数据
  handleUpdateData(updateSource: UcDataConfigUpdate, type: string, datetypeString = 'yyyy-MM-dd hh:mm:ss') {
    const data_tmp = {
      data_field: [],
      data_col: [],
      data_row: []
    };

    const data = [[]];
    if (updateSource.fields && updateSource.fields.length !== 0) {
      data_tmp.data_field = updateSource.fields;
    }

    updateSource.fields.map((col, index) => {
      data[0].push(col['fieldName']);
    });

    updateSource.data.map((row, key) => {
      data[(key + 1)] = [];
      data[0].map((col, colNum) => {
        data[(key + 1)].push(row[col]);
      });
    });

    data_tmp.data_col = data[0].map((name, index) => {
      const colName = name;
      const colData = data.slice(1).map((row, rowNum) => {
        if (row[index] != null) {
          return row[index];
        }
        return null;
      });

      return {
        name: colName,
        data: colData
      };
    });
    data_tmp.data_row = this._base.col2row(data_tmp.data_col, 'name', 'data');

    // if (this.chartTemplate.chart_for.type === 'table') {
    //   //  data_table 转化Date类型的时间
    //   data_tmp.data_field.forEach((item) => {
    //     if ((item.type === 'Date' || item.type === 'date' || item.type === 'datetime' || item.fieldName === 'alertTime' || item.fieldName === 'orderTime' || item.fieldName === 'lastUpdateTime')
    //       && data[0].indexOf(item.fieldName) > -1) {
    //       data_tmp.data_row.forEach((tableData, num) => {
    //         data_tmp.data_row[num][item.fieldName] = this._base.Format(new Date(this._base.xyVal(tableData[item.fieldName])), datetypeString);
    //       });
    //     }
    //   });
    //
    //   // 限制data_table中Number类型的小数有效位
    //   data_tmp.data_field.forEach((item, index) => {
    //     if (item.type === 'Number' && data[0].indexOf(item.fieldName) > -1) {
    //       data_tmp.data_row.forEach((tableData, num) => {
    //         data_tmp.data_row[num][item.fieldName] = this._process.formatNumber(this._base.isNum_(tableData[item.fieldName], 2));
    //       });
    //     }
    //   });
    //
    //   if (this.chartTemplate.chart_config.tableOptions.dateField && this.chartTemplate.chart_config.tableOptions.dateField !== null &&
    //     this.chartTemplate.chart_config.tableOptions.dateField !== '') {
    //     data_tmp.data_row.forEach((tableData, num) => {
    //       data_tmp.data_row[num][this.chartTemplate.chart_config.tableOptions.dateField] =
    //         this._base.Format(new Date(this._base.xyVal(tableData[this.chartTemplate.chart_config.tableOptions.dateField])), datetypeString);
    //     });
    //   }
    // }


    return data_tmp;
  }


  // 生成图模板
  getChartTemplate(newData, TCD: ChartTemplate) {
    const dataDic = [];
    for (const key in newData) {
      const colcode = this._base.QID(key || '');
      dataDic.push({
        fieldName: key,
        fieldCode: colcode,
        fieldType: 'any'
      });
    }

    const chartTmp: ChartSetting = TCD.chart_config.chartTmp;
    const series: any[] = [];
    const xAxis: any = TCD.chart_config.chartOptions.xAxis;
    const tabLen = dataDic.length;
    const specialChart = this._base.specialChartTypes.indexOf(TCD.chart_for.type) > -1;
    const commonParseSerie = {
      /**
       * 3个判断
       * 1、series中已存在feild相对应的serie，将所有存在的serie重新付给series配置
       * 2、series不存在不存在field相对应的serie，通过getSerie()生成
       * 3、通过xAxisId,设置xAxis配置
       */
      common: (data) => {
        const n = Math.floor(100 / tabLen);
        let l = -1;

        data.map((item, index) => {
          // x轴判断 抛去x轴
          if (item.fieldCode !== chartTmp.tmp.xAxisId) {
            l++;
            // 获取所有serie配置
            series.push(this.getSeries(TCD.chart_for, chartTmp, item, l, tabLen, null));
          }
        });
      }
    };

    if (specialChart) {
      // 饼图类型
      if (TCD.chart_for.type === 'pie') {
        // 圆环图、三列
        if (TCD.chart_for.id === 'pie-double') {
          if (dataDic.length === 3) {
            dataDic.slice(0, 2).map((row, index) => {
              series.push(this.getSeries(TCD.chart_for, chartTmp, row, index, 2, null));
            });
          } else {
            this.messageService.create('warning', '双饼图需要三列数据，第一列为大类型，第二列为小类型，第三列为数据，请在【选择模板】参照样例数据。');
          }
        } else {
          commonParseSerie.common(dataDic);
        }
      } else if (TCD.chart_for.type === 'bubble') {
        const serieData = (dataDic.length % 2 === 1) ? dataDic.slice(1) : dataDic;
        xAxis.id = (dataDic.length % 2 === 1) ? dataDic[0].field : null;

        serieData.map((row, index) => {
          if (index % 2 === 0) {
            series.push(this.getSeries(TCD.chart_for, chartTmp, row, index / 2, serieData.length / 2, {
              zPoint: serieData[index + 1]
            }));
          }
        });
      } else if (TCD.chart_for.type === 'candle') {

      }
    } else {
      commonParseSerie.common(dataDic);
    }

    return series;
  }
  // series
  getSeries(chartFor, chartTmp, item, index, num, append) {
    const colors = this._base.setting.i10n.colors;
    const color = colors[index % colors.length];
    const groupingUnits = [
      [
        'week',                         // unit name
        [1]                             // allowed multiples
      ],
      [
        'month',
        [1, 2, 3, 4, 6]
      ]
    ];
    const type = {
      common: () => {
        return {
          'id': item['fieldCode'],
          'name': item['fieldName'],
          'dataName': item['fieldName'],
          'dataLabels': {
            'enabled': false,
            'color': color,
            'style': {
              'fontSize': '12px',
              'textShadow': 'none'
            }
          },
          'type': chartFor.type,
          'color': color,
          'visible': true,
          'yAxis': '轴1',
          'yAxisShow': false,
          'smooth': false,
          'marker': {
            'symbol': 'circle',
            'lineWidth': 2,
            'lineColor': color,
            'enabled': false
          },
          'tooltip': {
            'valuePrefix': '',
            'valueSuffix': ''
          },
          'dashStyle': 'Solid',
          'lineWidth': 2,
          'showInLegend': true
        };
      },
      pie: () => {
        const n = Math.floor(100 / num);
        return {
          'id': item.fieldCode,
          'field': item.fieldCode,
          'name': item.fieldName,
          'dataName': item['fieldName'],
          'color': color,
          'marker': {
            'lineWidth': 2,
            'lineColor': color,
            'enabled': false
          },
          'dataLabels': {
            'enabled': true,
            'color': color,
            'style': {
              'fontSize': '12px',
              'textShadow': 'none'
            }
          },
          'tooltip': {
            'valuePrefix': '',
            'valueSuffix': ''
          },
          'type': chartFor.type,
          'topCenter': 50,
          'leftCenter': 50,
          'inSize': Math.floor(n * (index + 0.8)) - 1,
          'outSize': Math.floor(n * (index + 0.8))
        };
      },
      bubble: () => {
        return {
          'id': item.fieldCode,
          'field': item.fieldCode,
          'name': item.fieldName,
          'dataName': item['fieldName'],
          'color': color,
          'marker': {
            'fillColor': {
              radialGradient: {cx: 0.4, cy: 0.3, r: 0.7},
              stops: [
                [0, 'rgba(255,255,255,0.5)'],
                [1, color]
              ]
            },
            'lineWidth': 0,
            'lineColor': color,
            'enabled': true
          },
          'minSize': 3,
          'maxSize': 50,
          'gradientColor': 'transparent',
          'gradientColorEnble': false,
          'visible': true,
          'yAxis': '轴1',
          'dataLabels': {
            'enabled': true,
            'color': color,
            'style': {
              'fontSize': '12px',
              'textShadow': 'none'
            }
          },
          'tooltip': {
            'valuePrefix': '',
            'valueSuffix': ''
          },
          'type': chartFor.type
        };
      },
      candle: () => {
        return {
          'id': item.fieldCode,
          'field': item.fieldCode,
          'name': index === 0 ? '股价' : '成交量',
          'dataName': item[index === 0 ? '股价' : '成交量'],
          'type': index === 0 ? 'candlestick' : 'column',
          'color': index === 0 ? 'green' : '#9fc5e8',
          'lineColor': index === 0 ? 'green' : '',
          'upColor': index === 0 ? 'red' : '',
          'upLineColor': index === 0 ? 'red' : '',
          'yAxis': index === 0 ? '轴1' : '轴2',
          'marker': {
            'lineWidth': 2,
            'lineColor': color,
            'enabled': false
          },
          'dataGrouping': {
            'units': groupingUnits
          },
          'visible': true,
          'groupPaddings': 20
        };
      }
    };

    const result = type[chartFor.template]();
    if (append) {
      jQuery.extend(result, append);
    }

    return result;
  }
}
